import React, { ComponentProps, useState, useEffect, useCallback } from 'react';
import { Switch as RNSwitch } from 'react-native-paper';

import { ISwitch } from '@nocode/types';
import createStyles from './style';
import { View } from 'react-native';
import { get, isEmpty, isNil } from 'lodash';

type Props = ComponentProps<typeof RNSwitch>;

const Switch: React.FC<ISwitch> = ({
  attributes,
  onPress,
  changeInput,
  valueInputs,
  groupActionId,
  height,
}) => {
  const [isActive, setIsActive] = useState<boolean>(valueInputs);
  const styles = createStyles(attributes);

  const thumbColor = isActive
    ? attributes.thumbColorOn
    : attributes.thumbColorOff;

  let childProps: Props = {
    value: isActive,
    color: thumbColor ? thumbColor : '',
    thumbColor: thumbColor ? thumbColor : '',
    trackColor: {
      false: attributes.trackColorOff,
      true: attributes.trackColorOn,
    },
    ios_backgroundColor: attributes.trackColorOff,
  };

  const handleSwitch = (id: string, isAction: any) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});

    if (groupActionId && isNil(isAction)) {
      if (isEmpty(sectionOnpress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  useEffect(() => {
    setIsActive(valueInputs);
  }, [valueInputs]);

  const handlePress = useCallback(async () => {
    changeInput && changeInput(!valueInputs);
    const { onActions, offActions } = attributes;

    if (!onPress) return;

    if (isActive === false && onActions) {
      await handleSwitch('onActions', onActions);
      return;
    }

    if (isActive === true && offActions) {
      await handleSwitch('offActions', offActions);
      return;
    }
    setIsActive(!isActive);
    return handleSwitch('clickActions', undefined);
  }, [valueInputs, isActive]);

  return (
    <View style={{ height }}>
      <RNSwitch onValueChange={handlePress} value={isActive} {...childProps} />
    </View>
  );
};

export default Switch;
