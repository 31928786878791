import { excuteBinding } from '@common/hooks/useBinding';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { IInput } from '@common/types/element';
import { get, isNil, replace } from 'lodash';
import React, { FC, useCallback } from 'react';
import { Platform, TextInput, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import createStyles from './style';

const CusInput: FC<IInput> = (attributes) => {
  const recordId = get(attributes, 'record._id');
  const keyItem = get(attributes, 'keyItem');
  const getTableId = get(attributes, 'selectedItem.tableId');
  const currentRecord = useSelector(getCurrentRecord) || {};

  const defaultValue = excuteBinding(
    attributes?.defaultValue || '',
    attributes.record || currentRecord[getTableId],
    attributes?.selectedItem?.itemIndex
  );

  const { changeInput, valueInputs }: UseValueInputProps = useValueInputs({
    ...attributes,
    id: !isNil(keyItem)
      ? `${attributes.id}`
      : recordId
      ? `${attributes.id}__${recordId}`
      : attributes.id,
  });

  const styles = createStyles(attributes);
  const keyBoardType = getKeyBoardType(attributes.inputType);

  const key: any = attributes.id;

  const isPassword = attributes.inputType === 'password';
  const isEmail = attributes.id === 'email';
  const isName = attributes.id === 'fullName';

  const checkMultiline = () => {
    if ((isPassword && isEmail && isName) || attributes.multiline === false) {
      return false;
    } else {
      return attributes.multiline;
    }
  };

  const handleChange = useCallback(
    (value) => {
      // const numberRegex = new RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/);
      const floatRegex = /^[+-]?\d*(?:[.,]\d*)?$/;

      const isChangeValue =
        attributes.inputType !== 'number' ||
        (attributes.inputType === 'number' && floatRegex.test(value));

      if (isChangeValue) {
        changeInput(value);
      }
    },
    [attributes.inputType, changeInput, key]
  );

  return (
    <TouchableOpacity activeOpacity={1} style={styles.container}>
      <View style={styles.wrapper}>
        <TextInput
          style={styles.text}
          value={valueInputs?.toString()}
          onChangeText={handleChange}
          placeholder={formatValue(attributes.placeholder)}
          placeholderTextColor={attributes.placeholderColor}
          keyboardType={keyBoardType}
          multiline={checkMultiline()}
          secureTextEntry={isPassword}
          defaultValue={defaultValue}
          textAlignVertical={
            checkMultiline() && Platform.OS === 'android' ? 'top' : 'auto'
          }
        />
      </View>
    </TouchableOpacity>
  );
};

const getKeyBoardType = (inputType: string) => {
  switch (inputType) {
    case 'email':
      return 'email-address';

    case 'number':
      return 'numeric';

    default:
      return 'default';
  }
};

const formatValue = (str: any) =>
  replace(str, /<br ?\/?>/g, '\n')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/<\/?p[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/<\s*\/?\s*span\s*.*?>/g, '')
    .replace(/<[^>]*>/g, '\n')
    .replace(/&amp;/g, '&')
    .replace(/\n*$/g, '');

export default CusInput;
