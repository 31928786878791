import { pick } from 'lodash';
import { StyleSheet, Dimensions } from 'react-native';
const windowWidth = Dimensions.get('window').width;
const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, ['width', 'height', 'zIndex', 'marginTop']),
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#d1d8e3',
      overflow: 'hidden',
    },
    scannerContainer: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    camera: {
      ...pick(attributes, ['width', 'height']),
    },
    containerScan: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      flex: 1,
      top: 0,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    scan: {
      width: 250,
      height: 130,
      borderLeftWidth: 5,
      borderRightWidth: 5,
      borderTopWidth: 5,
      borderBottomWidth: 5,
      borderTopColor: '#4259AC',
      borderLeftColor: '#4259AC',
      borderRightColor: '#4259AC',
      borderBottomColor: '#4259AC',
    },
    back: {
      width: 29,
      height: 29,
      backgroundColor: 'white',
      position: 'absolute',
      top: windowWidth / 10,
      right: 23,
      zIndex: 99,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 23,
    },
  });
};

export default createStyles;
