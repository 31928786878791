import hybrid from './hybrid.png';
import roadmap from './roadmap.png';
import satellite from './satellite.png';
import terrain from './terrain.png';

export const mapImgInCanvas = {
  roadmap,
  satellite,
  terrain,
  hybrid,
};
