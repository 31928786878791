import { APP_API_TOKEN } from '@common/constants/shared';
import appConfig from '../../appConfig.json';
import axios from 'axios';

const instance = axios.create({
  baseURL: appConfig.baseUrl,
  timeout: 60 * 2 * 1000,
  headers: {
    authorization: 'Bearer ' + APP_API_TOKEN,
  },
});

export default instance;

export const config = {
  pluginSocket: (appConfig as any).pluginSocket,
};

export const callMap = (params: any) => instance.post('/maps', params);
