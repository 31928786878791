import { COMPARATORS, DATE_FORMAT, FIELD_TYPE } from '@common/constants/shared';
import { IImagePickerType } from '@common/types';
import {
  isString,
  find,
  isEmpty,
  includes,
  isNil,
  isNumber,
  get,
} from 'lodash';
import moment from 'moment';
import { DATE_TIME_FORMAT } from './handleBinding/function';
// import { getFieldType, getFieldValue } from '@common/utils/database';

// const keyDateTimeDefault = ['updatedAt', 'createdAt'];

export const inRange = (num: any, start: any, end: any) => {
  // If no end number, use start as end
  // if (!end) {
  //   end = start;
  //   start = 0;
  // }
  if (isNil(start) || isNil(end)) return false;

  return num >= start && num <= end;
};

const checkIsMoment = (value: string) => {
  return moment(value, ['YYYY/MM/DD HH:mm', 'YYYY/MM/DD'], true).isValid();
};

export const isLessThan = (a: any, b: any, dataType: any) => {
  if (isNil(a) || isNil(b)) return false;

  if (dataType !== FIELD_TYPE.TIMESTAMP && dataType !== FIELD_TYPE.DATE) {
    if (checkIsMoment(a) || checkIsMoment(b)) {
      return convertUnix(a) < convertUnix(b);
    }

    return +a < +b;
  }

  return convertUnix(a) < convertUnix(b);
};

export const isLessThanOrEqual = (a: any, b: any) => {
  if (isNil(a) || isNil(b)) return false;

  return +a <= +b;
};

export const isGreaterThan = (a: any, b: any, dataType: any) => {
  if (isNil(a) || isNil(b)) return false;

  if (dataType !== FIELD_TYPE.TIMESTAMP && dataType !== FIELD_TYPE.DATE) {
    if (checkIsMoment(a) || checkIsMoment(b)) {
      return convertUnix(a) > convertUnix(b);
    }
    return +a > +b;
  }

  return convertUnix(a) > convertUnix(b);
};

export const isGreaterThanOrEqual = (a: any, b: any) => {
  if (isNil(a) || isNil(b)) return false;
  return +a >= +b;
};

export const checkTheSameOfImage = (
  a: IImagePickerType,
  b: IImagePickerType
) => {
  if (isEmpty(a) && isEmpty(b)) return true;
  if (isNil(a) || isNil(b)) return false;

  return a.filename == b.filename || a.size == b.size;
};

export const isEqual = (a: any, b: any, dataType: any) => {
  if (dataType === 'timestamp' && dataType !== FIELD_TYPE.DATE) {
    return (
      moment(a).format(DATE_FORMAT.DATE_ONLY) ==
      moment(b).format(DATE_FORMAT.DATE_ONLY)
    );
  }

  if (dataType === 'object') {
    if (isString(a) && typeof b == 'object' && b?.userId) {
      return a == get(b, 'userId');
    }

    if (isString(b) && typeof a === 'object' && a?.userId) {
      return b == get(a, 'email') || b == get(a, 'userId');
    }

    if (a?.userId && b?.userId) {
      return a.userId == b.userId;
    }

    if (isString(a) && isString(b)) {
      return a == b;
    }

    return get(a, 'id') == get(b, 'id', b);
  }

  if (a === 0) {
    if (typeof b === 'string') return b === '0';
    return b === 0;
  }

  if (dataType === 'number') {
    a = isNil(a) ? '' : a;
    b = isNil(b) ? '' : b;

    return a == b;
  }

  if (dataType === 'image') {
    return checkTheSameOfImage(a, b);
  }

  if (isEmpty(a) && isEmpty(b) && !isNumber(a) && !isNumber(b)) {
    return true;
  }

  return a == b;
};

export const isTrue = (a: any, comparator: any) => {
  if (isEmpty(a) && comparator === 'false') return true;
  return String(a).toLowerCase() === 'true';
};

export const isFalse = (a: any, comparator: any) => {
  return String(a).toLowerCase() === 'false';
};

export const isContains = (a: any, b: any) => {
  if (Array.isArray(a)) {
    return a.some((r) => r && r.includes(b));
  }

  if (isEmpty(a) && isEmpty(b) && !isNumber(a) && !isNumber(b)) {
    return true;
  }

  return a && a.includes(b);
};

export const listContains = (a: any, b: any) => {
  if (isEmpty(a) || isEmpty(b)) return false;

  if (!isString(b)) {
    const _id = b?._id || b?.userId;

    return !!find(a, { _id });
  } else {
    return !!find(a, { _id: b });
  }
};

export const isNotContains = (a: any, b: any) => {
  if (!b || b === '') return true;

  if (Array.isArray(a)) {
    return a.some((r) => r && !r.includes(b));
  }

  return a && !a.includes(b);
};

export default ({
  value,
  comparator,
  comparison,
  comparison2,
  dataType,
}: any) => {
  const a =
    dataType === 'boolean' ? String(value !== '' ? value : false) : value;

  let b, c;

  if (includes(['file', 'image'], dataType)) {
    b = isEmpty(comparison) ? null : comparison;
  } else {
    b = comparison;
    c = comparison2;
  }

  // if (a) {
  switch (comparator) {
    case COMPARATORS.gt:
      return isGreaterThan(a, b, dataType);

    case COMPARATORS.gte:
      return isGreaterThanOrEqual(a, b);

    case COMPARATORS.lt:
      return isLessThan(a, b, dataType);

    case COMPARATORS.lte:
      return isLessThanOrEqual(a, b);

    case COMPARATORS.neq:
      return !isEqual(a, b, dataType);

    case COMPARATORS.eq:
      return isEqual(a, b, dataType);

    case 'true':
      return isTrue(a, comparator);

    case 'false':
      return isFalse(a, comparator);

    case COMPARATORS.contains:
      return isContains(a, b);

    case COMPARATORS.notContains:
      return !isContains(a, b);

    case COMPARATORS.between:
      const formatDate = DATE_TIME_FORMAT[dataType] || DATE_TIME_FORMAT.date;

      return dataType === FIELD_TYPE.TIMESTAMP || dataType === FIELD_TYPE.DATE
        ? inRange(
            convertUnix(moment(a).format(formatDate)),
            convertUnix(moment(b).format(formatDate)),
            convertUnix(moment(c).format(formatDate))
          )
        : isEmpty(a) && isEmpty(b) && isEmpty(c)
        ? false
        : inRange(+a, +b, +c);

    case COMPARATORS.listContains:
      return listContains(a, b);

    case COMPARATORS.listNotContains:
      return !listContains(a, b);

    default:
      return isEqual(a, b, dataType);
  }
  // }
};

export const convertUnix = (time: any) =>
  !time ? moment().unix() : moment(time).unix();
