import React, { FC } from 'react';
import useUnivaPaySubscription, { Props, ReceivedProps } from './hook';
import UnivaPayForm from './UnivaPayForm';

const UnivaPaySubscriptionLayout: FC<Props> = (props) => {
  return <UnivaPayForm {...props} />;
};

const UnivaPaySubscription: FC<ReceivedProps> = (props) => (
  <UnivaPaySubscriptionLayout {...useUnivaPaySubscription(props)} />
);

export default UnivaPaySubscription;
