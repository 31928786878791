import React from 'react';
import { TouchableOpacity } from 'react-native';
import Barcode from 'react-barcode';
import queryString from 'query-string';

import createStyles from './style';
import { IBarcode } from '@nocode/types';

const BarCode: React.FC<IBarcode> = (props) => {
  const styles = createStyles(props);
  const { attributes, onPress } = props;
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const barCodeVal = !target ? attributes.value : attributes.text;
  const barCodeHeight = props.height - 5;
  return barCodeVal ? (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Barcode
        value={barCodeVal}
        margin={0}
        marginBottom={10}
        height={barCodeHeight}
      />
    </TouchableOpacity>
  ) : null;
};

export default BarCode;
