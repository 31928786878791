import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Modal } from 'react-native';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import createStyles from './style';
import { isUndefined, isEmpty } from 'lodash';
import queryString from 'query-string';

const BarcodeScanner: React.FC<any> = (props) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const localePreview = search?.locale;
  const styles = createStyles(props);
  let { locale } = props;
  locale = locale || localePreview;
  const { changeInput, valueInputs, onPress } = props;
  const [openScanner, setOpenScanner] = useState(false);
  const onPressModal = () => {
    setOpenScanner(true);
  };

  return (
    <>
      <Modal visible={openScanner} onRequestClose={() => setOpenScanner(false)}>
        <View
          style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
        >
          <View style={styles.containerScan}>
            <View style={styles.scan}></View>
          </View>
          <TouchableOpacity
            onPress={() => setOpenScanner(false)}
            style={styles.back}
          >
            <Text>X</Text>
          </TouchableOpacity>
          <BarcodeScannerComponent
            delay={100}
            onError={(e: any) => setOpenScanner(false)}
            onUpdate={(err, result: any) => {
              if (result) {
                setOpenScanner(false);
                changeInput(result?.text);
                onPress();
              }
            }}
          />
        </View>
      </Modal>
      {!valueInputs ? (
        openScanner ? null : (
          <TouchableOpacity style={styles.container} onPress={onPressModal}>
            <Text>
              {locale === 'ja' ? 'バーコードを読み取る' : 'Click to scan'}
            </Text>
          </TouchableOpacity>
        )
      ) : openScanner ? null : (
        <TouchableOpacity style={styles.container} onPress={onPressModal}>
          <Text> {valueInputs} </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

export default React.memo(BarcodeScanner);
