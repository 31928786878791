import { Linking, Platform } from 'react-native';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { IAction } from '@common/types/';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const docomoLogin: (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => {
  const socialConfig = action.options;

  AsyncStorage.setItem(
    'clickFlows',
    JSON.stringify({
      currentRecord,
      actionComponent,
    })
  );

  let url = `https://id.smt.docomo.ne.jp/cgi8/oidc/authorize?response_type=code&client_id=${socialConfig.clientId}&redirect_uri=${socialConfig.redirectUri}&scope=openid&authif=1&state=docomo&nonce=0`;
  await Linking.openURL(url);

  return {
    status: 'FAILED',
  };
};

export default docomoLogin;
