import { IMAGE_TYPE } from '@common/constants/shared';
import { useCallback, useMemo } from 'react';
import { IImageUpload } from '@common/types/element';
import { get, isEmpty } from 'lodash';
import { getImageBinding } from '@common/utils/handleBinding';
import { useSelector } from 'react-redux';
import { formInputs } from '@common/redux/selectors/formInput';

const useImageDefault = (attributes: IImageUpload) => {
  if (!attributes?.defaultValue) {
    return { url: '' };
  }

  const { imageType, imageURL, imageUploaded, imageUrl, binding } =
    attributes?.defaultValue;

  const placeholder = get(binding, 'options', {});

  const { defaultValues } = useSelector(formInputs);

  const getUrl = useCallback(() => {
    switch (imageType) {
      case IMAGE_TYPE.UPLOAD:
        return imageUploaded;

      case IMAGE_TYPE.URL:
        if (!imageUrl) return;
        const getSoure: any = imageUrl.filter((item: any) => item.id);

        if (!isEmpty(getSoure)) {
          const urlBinding = getImageBinding(
            imageUrl,
            get(attributes, 'record', defaultValues || {})
          );

          return urlBinding;
        }

        if (Array.isArray(imageUrl)) return imageUrl[0];
        return imageUrl;

      case IMAGE_TYPE.INTERNAL:
        const imageBinding = getImageBinding(
          attributes.defaultValue,
          get(attributes, 'record', defaultValues || {})
        );

        return imageBinding?.binding;

      default:
        return;
    }
  }, [imageType, imageURL, imageUrl, attributes]);

  const url = useMemo(() => getUrl(), [attributes]);

  return { url, placeholder };
};

export default useImageDefault;
