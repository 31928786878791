import { AxiosResponse } from 'axios';
import { pick } from 'lodash';
import { setError, setNotification } from '@common/redux/slice/action';
import { loginSuccessWithLine } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { ILineToken } from '@common/types/element';
import api from '@common/configs/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

const getLineToken = async (code: string, app_id: string) => {
  const dispatch = store.dispatch;
  const state: any = store.getState();
  const isCheckFriendshipStored: string | null = await AsyncStorage.getItem(
    'isCheckFriendship'
  );
  const isCheckFriendship = isCheckFriendshipStored
    ? JSON.parse(isCheckFriendshipStored).isCheckFriendship
    : false;

  const data = {
    code: code,
    app_id: app_id,
    type: 'LINE',
    isCheckFriendship: isCheckFriendship,
  };

  try {
    const res: AxiosResponse<ILineToken | any> = await api({
      method: 'post',
      url: '/line',
      data: data,
    });
    const resData = res.data;
    if (resData.code && resData.code === 400) {
      const { message } = resData;
      return {
        status: 'FAILED',
        message,
      };
    }
    if (resData && resData.id_token && resData.dataUser) {
      dispatch(
        loginSuccessWithLine({
          user: {
            ...resData.dataUser.record,
            databaseId: resData.dataUser.databaseId,
            userId: resData?.dataUser?._id,
            appId: state.appInfo.app?.id,
            ...pick(resData, ['createdAt', 'updatedAt']),
          },
          token: resData.id_token,
          friendship: resData.data.friendship,
        })
      );
      dispatch(setNotification('Login Success !'));
      return {
        status: 'SUCCEED',
        message: 'Login success!!',
        friendship: resData.data.friendship,
      };
    }
  } catch (error: any) {
    dispatch(setError(error?.message));
    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default getLineToken;
