import { pick } from 'lodash';
import { StyleSheet } from 'react-native';
import { getFontWeight, getShadow } from '../func';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'borderRadius',
        'width',
        'height',
      ]),
      zIndex: attributes.zIndex,
    },
    text: {
      ...pick(attributes, ['color', 'width', 'borderRadius']),
      // ...(attributes.shadow.size > 0 ? getShadow(attributes) : {}),
      ...getShadow(attributes),
      fontWeight: getFontWeight(attributes),
      ...pick(attributes, [
        'backgroundColor',
        'fontSize',
        // 'padding',
        'opacity',
      ]),
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      minHeight: attributes.height,
      paddingLeft: 10,
      alignSelf: 'center',
      paddingTop: 0,
      paddingBottom: 0,
    },
  });
};

export default createStyles;
