import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  qrCodeWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '30px 0',
  },
  qrCodeDescription: {
    maxWidth: 300,
    marginTop: 25,
    textAlign: 'center',
    color: '#818181',
    fontSize: 14,
  },
});

export default styles;
