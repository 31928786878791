import Loading from '@common/components/Loading';
import { authenticationRouting } from '@common/hooks/authenticationRoute';
import {
  forgotPassword,
  passwordTemporary,
} from '@common/redux/selectors/action';
import { getAuth } from '@common/redux/selectors/database';
import { dimensionSelector } from '@common/redux/selectors/page';
import {
  animatedScreen,
  formatScreens,
  getLocaleApp,
} from '@common/utils/functions';
import { isEmpty } from 'lodash';
import React, { Fragment, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Animated, Platform, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import AnimationWrapper from '../../components/Animation/NormalAnimation';
import ErrorComponent from './ErrorComponent';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ForgotPassword/ResetPassword';
import Render from './Render';

let SplashScreen: any;

(async () => {
  if (Platform.OS !== 'web') {
    SplashScreen = await (await import('react-native-splash-screen')).default;
  }
})();

function RenderScreen() {
  const {
    currentPage,
    animation,
    currentModalPage,
    modalTransition,
    loading,
    appInfo,
  }: any = authenticationRouting();
  const dimension = useSelector(dimensionSelector);
  const isForgotPassword = useSelector(forgotPassword);
  const getPasswordTemporary = useSelector(passwordTemporary);

  const auth = useSelector(getAuth);

  const locale = getLocaleApp();

  const screen = useMemo(
    () =>
      currentPage
        ? formatScreens({ screen: currentPage }).single().toView()
        : undefined,
    [currentPage]
  );

  const modalScreen = useMemo(
    () =>
      currentModalPage
        ? formatScreens({ screen: currentModalPage }).single().toView()
        : null,
    [currentModalPage]
  );

  if (loading) {
    if (Platform.OS == 'web') {
      return <Loading />;
    } else {
      SplashScreen.hide();
      return <Loading />;
    }
  }

  if (!screen) {
    return (
      <Fragment>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: dimension.width,
            height: dimension.height,
            backgroundColor: 'white',
          }}
        >
          <Text>Not found screen on App</Text>
        </View>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>{`${appInfo?.name} | ${currentPage?.name}`}</title>
      </Helmet>
      <Animated.View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <AnimationWrapper animation={animatedScreen(animation)}>
          <Render screen={screen} />
          <ErrorComponent />
        </AnimationWrapper>
      </Animated.View>

      {currentModalPage && modalScreen && (
        <Animated.View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: dimension.width,
            height: dimension.height,
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        >
          <AnimationWrapper animation={animatedScreen(modalTransition)}>
            <Render screen={modalScreen} />
          </AnimationWrapper>
        </Animated.View>
      )}

      {auth?.loggingWithPasswordTemporary && !isEmpty(getPasswordTemporary) && (
        <Animated.View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: '#fff',
          }}
        >
          <AnimationWrapper animation={isForgotPassword.animation}>
            <ResetPassword
              appId={appInfo?.id}
              email={auth.email}
              locale={locale}
            />
          </AnimationWrapper>
        </Animated.View>
      )}

      {isForgotPassword.state && (
        <Animated.View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            backgroundColor: '#fff',
          }}
        >
          <AnimationWrapper animation={isForgotPassword.animation}>
            <ForgotPassword appId={appInfo?.id} locale={locale} />
          </AnimationWrapper>
        </Animated.View>
      )}
    </Fragment>
  );
}
export default memo(RenderScreen);
