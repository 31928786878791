import { StyleSheet } from 'react-native';

const createStyles = () => {
  return StyleSheet.create({
    leftSection: {
      // backgroundColor: '#cccccc',
      borderRadius: 100,
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightSection: {
      borderRadius: 100,
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

export default createStyles;
