import { isEmpty, isNil } from 'lodash';
import * as Yup from 'yup';

export type IField = {
  default: boolean;
  fieldId: string;
  fid: string;
  label: string;
  multiline: boolean;
  name: string;
  placeholder: string;
  required: boolean;
  requiredText: string;
  type: string;
  length: number;
  typeValidateLen: string;
  validateLenText: string;
};

export const generateSchema = (fields: Array<IField>, locale: String) => {
  let schema: any = {};

  fields.forEach((field) => {
    if (!field.required) {
      if (!isNil(field.typeValidateLen) && field.typeValidateLen !== 'none') {
        schema[field.fieldId || field.fid] = createSchema(field, locale);
      }
      return;
    }
    schema[field.fieldId || field.fid] = createSchema(field, locale);
  });
  return Yup.object().shape(schema);
};

const createSchema = (field: IField, locale: String) => {
  const { type, requiredText, fieldId } = field;

  const IEmailField = fieldId === 'email';

  switch (type) {
    case 'boolean':
      // return Yup.bool().nullable(true).default(false);
      return field.required
        ? Yup.string()
            .nullable(true)
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string | any) => value === 'true'
            )
            .required(requiredText)
        : true;

    case 'image':
      return Yup.object().nullable(true).required(requiredText);

    case 'file':
      return Yup.object().nullable(true).required(requiredText);

    case 'string':
      const { typeValidateLen, validateLenText, length } = field;
      const validLength = isNil(length) ? 0 : length;

      switch (typeValidateLen) {
        case 'min':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .min(
              validLength,
              validateLenText?.trim()?.length
                ? validateLenText
                : `Min length is ${validLength} character`
            )
            .test(
              `${field.fieldId || field.fid}`,
              `${
                locale === 'ja'
                  ? 'メールアドレスが有効ではありません'
                  : 'This field must be a valid email'
              }`,
              (value: string) => {
                if (IEmailField) return Yup.string().email().isValidSync(value);
                return Yup.string().nullable(true).isValidSync(value);
              }
            );
        case 'max':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .max(
              validLength,
              validateLenText?.trim()?.length
                ? validateLenText
                : `Max length is ${validLength} character`
            )
            .test(
              `${field.fieldId || field.fid}`,
              `${
                locale === 'ja'
                  ? 'メールアドレスが有効ではありません'
                  : 'This field must be a valid email'
              }`,
              (value: string) => {
                if (IEmailField) return Yup.string().email().isValidSync(value);
                return Yup.string().nullable(true).isValidSync(value);
              }
            );
        case 'equal':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .length(
              validLength,
              validateLenText?.trim()?.length
                ? validateLenText
                : `Field must have ${validLength} character`
            )
            .test(
              `${field.fieldId || field.fid}`,
              `${
                locale === 'ja'
                  ? 'メールアドレスが有効ではありません'
                  : 'This field must be a valid email'
              }`,
              (value: string) => {
                if (IEmailField) return Yup.string().email().isValidSync(value);
                return Yup.string().nullable(true).isValidSync(value);
              }
            );
        default:
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .test(
              `${field.fieldId || field.fid}`,
              `${
                locale === 'ja'
                  ? 'メールアドレスが有効ではありません'
                  : 'This field must be a valid email'
              }`,
              (value: string) => {
                if (IEmailField) return Yup.string().email().isValidSync(value);
                return Yup.string().nullable(true).isValidSync(value);
              }
            );
      }

    case 'number': {
      const { typeValidateLen, validateLenText, length } = field;

      const validLength = isNil(length) ? 0 : length;

      switch (typeValidateLen) {
        case 'min':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .min(validLength, validateLenText);
        case 'max':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .max(validLength, validateLenText);
        case 'equal':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .length(validLength, validateLenText);
        default:
          return Yup.string().nullable(true).required(requiredText);
      }
    }

    default:
      return Yup.string().nullable(true).required(requiredText);
  }
};
