export const DEFAULT_CENTER = {
  lat: 35.6585805,
  lng: 139.7454329,
};
export const DEFAULT_ZOOM = 15;
export const DEFAULT_DISTANCE = 5;

export const defaultImageMarker =
  'https://s3.ap-northeast-1.amazonaws.com/dev.click-platform.dbfile/user853/2022/07/18/dc440bbb094.png?AWSAccessKeyId=AKIA3JCH5IRY54KVZTMW&Expires=1973498884&Signature=pKfv%2Fen4FcxuRMDantLgpTWqHR0%3D';

export const automaticTime = {
  LOCATION: 3000,
};

export const isDevelopment = process.env.REACT_APP_ENV === 'development';

export const paths = [
  'dataBinding',
  'attributes.style.mapStyle',
  'width',
  'height',
  'googleMapisloaded',
];

export const LOCATION_TIME = 5000;
