import { isEmpty } from 'lodash';
import { setCurrentRerordExternal } from '@common/redux/slice/externalCollection';
import { setError } from '@common/redux/slice/formInput';
import { createUpdateRecordExternal } from '@common/utils/database';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import {
  buildObject,
  getUrlExternal,
} from '@common/utils/handleActions/func/func';

const excuteCreateExternal: (
  table: any,
  newPayload: any,
  dispatch: any,
  state: any
) => Promise<ActionResponse> = async (table, newPayload, dispatch, state) => {
  const databaseMetadata = JSON.parse(table.databaseMetadata)[0];

  let yourObject: any = {};

  const endpoints =
    databaseMetadata.endpoints && Array.isArray(databaseMetadata.endpoints)
      ? databaseMetadata.endpoints
      : [];

  const url = getUrlExternal(databaseMetadata, endpoints, 'create', state);

  if (newPayload && !isEmpty(newPayload))
    //build body post
    for (const [key, value] of Object.entries(newPayload)) {
      buildObject(key, value, yourObject);
    }

  try {
    if (databaseMetadata) {
      await createUpdateRecordExternal(
        url,
        databaseMetadata,
        table,
        yourObject
      ).then((value: any) => {
        if (value && value.id) {
          dispatch(
            setCurrentRerordExternal({
              id: value.id,
            })
          );
        }
      });
    }
    return {
      status: 'SUCCEED',
      message: 'Create Succeed',
    };
  } catch (err: any) {
    dispatch(
      setError({
        message: err?.response?.data?.message,
        isError: true,
      })
    );

    return {
      status: 'FAILED',
      message: err?.response?.data?.message || err?.message,
    };
  }
};

export default excuteCreateExternal;
