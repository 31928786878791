import { IGroup } from '@common/types/element';
import React, { FC, useEffect, useState } from 'react';
import createStyles from './style';
import { TouchableOpacity, View } from 'react-native';
import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { isEmpty, map } from 'lodash';

const CusGroup: FC<IGroup> = (attrs) => {
  const styles = createStyles(attrs);
  const { children } = attrs;

  if (children.length == 0) return <React.Fragment />;

  const hasAction = !isEmpty(attrs.actions);
  return (
    <>
      {hasAction ? (
        <View style={styles.container}>
          <ObjectRender
            keyItem={attrs?.selectedItem?.itemIndex}
            arrComp={map(children, (child, index) => {
              return {
                ...child,
                actions: {
                  ...child?.actions,
                  ...attrs?.actions,
                },
                selectedItem: {
                  itemId: attrs.selectedItem?._id,
                  tableId: attrs.selectedItem?.tableId,
                  itemIndex: attrs.selectedItem?.itemIndex,
                },
                record: attrs.record,
                groupActionId: attrs?.actions && Object.keys(attrs.actions)[0],
              };
            })}
            isScreen={false}
            layout={{
              offsetTop: attrs?.y,
              offsetLeft: attrs?.x,
              parentWidth: attrs?.width,
            }}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <TouchableOpacity
            onPress={() => attrs.onPress && attrs.onPress()}
            style={{
              minHeight: attrs.height,
              width: attrs.width,
            }}
            activeOpacity={1}
          >
            <ObjectRender
              keyItem={attrs?.selectedItem?.itemIndex}
              arrComp={map(children, (child, index) => {
                return {
                  ...child,
                  actions: {
                    ...child?.actions,
                    ...attrs?.actions,
                  },
                  selectedItem: {
                    itemId: attrs.selectedItem?._id,
                    tableId: attrs.selectedItem?.tableId,
                    itemIndex: attrs.selectedItem?.itemIndex,
                  },
                  record: attrs.record,
                  groupActionId:
                    attrs?.actions && Object.keys(attrs.actions)[0],
                };
              })}
              isScreen={false}
              layout={{
                offsetTop: attrs?.y,
                offsetLeft: attrs?.x,
                parentWidth: attrs?.width,
              }}
            />
          </TouchableOpacity>
        </View>
      )}
    </>
  );
};

export default CusGroup;
