import { set } from 'lodash';

export const createManifest = async (
  start_url = '',
  short_name = '',
  icon = ''
) => {
  const manifest = await fetch('manifest.json')
    .then((res) => res.json())
    .then((r) => r);
  if (start_url) {
    set(manifest, 'start_url', start_url);
  }
  if (short_name) {
    set(manifest, 'short_name', short_name);
  }
  if (icon) {
    set(manifest, 'icons', [
      {
        src: icon,
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon',
      },
    ]);
  }
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  return URL.createObjectURL(blob);
};

export const createAppleTouchIcon = (url = '') => {
  if (!url) {
    return `${process.env.PUBLIC_URL}/favicon.png`;
  }
  return url;
};
