import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import IconList from './Icon';

type Props = {
  activeIcon: string;
  inactiveIcon: string;
  activeColor: string;
  inactiveColor: string;
};

const ToggleSelect = ({
  activeIcon,
  inactiveIcon,
  activeColor,
  inactiveColor,
}: Props) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <TouchableOpacity
      style={{
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
      }}
      onPress={() => {
        setIsActive(!isActive);
      }}
    >
      {isActive ? (
        <IconList icon={activeIcon} color={activeColor} />
      ) : (
        <IconList icon={inactiveIcon} color={inactiveColor} />
      )}
    </TouchableOpacity>
  );
};

export default ToggleSelect;
