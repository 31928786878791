import React, { FC, useEffect, useState, useMemo } from 'react';
import { BottomNavigation, Text, IconButton } from 'react-native-paper';
import { View } from 'react-native';
import { ITabNavigator } from '../../types';
import { isUndefined, isEmpty, get, isEqual } from 'lodash';
import SvgComponent from '../CustomIcon';
import { CustomIconList } from '../CustomIcon/CustomIcon';

const hasPaddingBottom = ['iphonePro', 'iphoneProMax', 'iphone11'];

const TabNavigator: FC<ITabNavigator> = ({
  originX,
  attributes,
  onPress,
  devicePrev,
  groupActionId,
}) => {
  const { tab0, tab1, tab2, tab3, tab4, activeTab } = attributes;

  const newOriginX = originX < 1.5 ? originX : 1;

  const [index, setIndex] = useState<number>(0);
  const [tabs, setTabs] = useState<Record<string, any>[]>([
    tab0,
    tab1,
    tab2,
    tab3,
    tab4,
  ]);

  const handlePress = (id: string) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});
    if (groupActionId) {
      if (isEmpty(sectionOnpress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  const routes = useMemo(() => {
    return tabs
      .filter((o) => o.enabled || isUndefined(o.enabled))
      .map((item, index) => {
        const tabIndex = [...tabs].findIndex((e) => isEqual(e, item));
        return {
          key: 'tab' + tabIndex,
          title: item.label,
          icon: item.icon,
          tab: 'tab' + tabIndex,
        };
      });
  }, [tabs]);

  const handleOnTabPress = ({ route }: Record<string, any>) => {
    if (!onPress) return;
    handlePress(route.tab);
  };

  const listComponent = useMemo(() => {
    const result: Record<string, any> = {};
    routes.forEach((item) => {
      result[item.tab] = () => (
        <View>
          <Text style={{ fontSize: attributes.fontSize * newOriginX || 14 }}>
            {!window?.location?.origin?.includes('http') ? item.title : ''}
          </Text>
        </View>
      );
    });

    return result;
  }, [routes]);

  const renderScene = BottomNavigation.SceneMap({
    ...listComponent,
  });

  const indexActive = routes.length - 1 < index ? 0 : index;

  const renderLabel = (props: {
    route: Record<string, any>;
    color: string;
  }) => {
    return (
      <Text
        style={{
          fontSize: attributes.fontSize * newOriginX || 14,
          color: props.color,
          textAlign: 'center',
          marginBottom: -5,
        }}
        numberOfLines={1}
      >
        {props.route.title}
      </Text>
    );
  };

  const renderIcon = (props: {
    route: Record<string, any>;
    focused: boolean;
    color: string;
  }) => {
    const CustomIcon = CustomIconList.find(
      (iconName) => props.route.icon === iconName.name
    );

    if (CustomIcon != undefined) {
      return <SvgComponent icon={props.route.icon} heigh={24} width={24} color={props.color} />;
    }

    return (
      <IconButton
        style={{
          justifyContent: 'space-between',
          margin: 0,
        }}
        size={24}
        icon={props.route.icon}
        color={props.color}
      />
    );
  };

  useEffect(() => {
    setTabs([tab0, tab1, tab2, tab3, tab4]);
  }, [attributes]);

  useEffect(() => {
    const indexTab = routes.findIndex((o) => o.tab === 'tab' + activeTab);
    if (indexTab !== -1) setIndex(indexTab);
    else setIndex(0);
  }, [routes, activeTab]);

  return (
    <BottomNavigation
      navigationState={{
        index: indexActive,
        routes: routes,
      }}
      shifting={false}
      renderIcon={renderIcon}
      onIndexChange={setIndex}
      renderScene={renderScene}
      renderLabel={renderLabel}
      onTabPress={handleOnTabPress}
      activeColor={attributes.activeColor}
      inactiveColor={attributes.inactiveColor}
      style={{
        opacity: attributes.opacity,
        borderTopColor: attributes.borderColor,
        borderTopWidth:
          attributes.borderStyle === 'none' ? 0 : attributes.borderWidth,
        borderStyle:
          attributes.borderStyle === 'none'
            ? undefined
            : attributes.borderStyle,
      }}
      barStyle={{
        backgroundColor: attributes.backgroundColor,
        paddingBottom: hasPaddingBottom.includes(devicePrev) ? 15 : 0,
      }}
    />
  );
};

export default TabNavigator;
