import { IChipList } from '@nocode/types';
import { StyleSheet, Platform } from 'react-native';
import { checkFont, getBorderStyle, getBorderWidth } from '../func';

const createStyles = (attrs: IChipList) => {
  const itemMargin = attrs.attributes.gap || 0;

  const itemSize = attrs.attributes.itemSize || 32;

  return StyleSheet.create({
    chipContent: {
      height: itemSize * 0.8,
      marginHorizontal: itemMargin,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: itemSize * 0.1,
      marginBottom: itemSize * 0.1,
      color: attrs.attributes.color,
      shadowColor: attrs.attributes.shadow?.color,
      shadowOffset: {
        width: attrs.attributes.shadow?.x,
        height: attrs.attributes.shadow?.y,
      },
      shadowOpacity: attrs.attributes.shadow?.blur,
      shadowRadius: attrs.attributes.shadow?.size,
      opacity: attrs.attributes.opacity,
      backgroundColor: attrs.attributes.backgroundColor,
      borderColor: attrs.attributes.borderColor,
      borderRadius: attrs.attributes.borderRadius,
      borderWidth: getBorderWidth(
        attrs.attributes.borderWidth,
        attrs.attributes.borderStyle
      ),
      borderStyle: getBorderStyle(attrs.attributes.borderStyle),
    },

    avatar: {
      display: 'flex',
      alignItems: 'center',
      height: itemSize * 0.6,
      width: itemSize * 0.6,
      marginTop: itemMargin * 0.5,
      marginBottom: itemMargin * 0.5,
      borderColor: attrs.attributes.cardCover.borderColor,
      borderWidth: getBorderWidth(
        attrs.attributes.cardCover.borderWidth,
        attrs.attributes.cardCover.borderStyle
      ),
      borderRadius: attrs.attributes.cardCover.borderRadius,
      borderStyle: getBorderStyle(attrs.attributes.cardCover.borderStyle),
    },

    text: {
      fontFamily: checkFont(attrs.attributes.fontFamily),
      fontSize: attrs.attributes.fontSize,
      color: attrs.attributes.color,
    },

    wapperList: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
  });
};

export default createStyles;
