import Icon from '@nocode/components/AppBar/Icon';
import { theme } from '@common/configs/theme';
import React from 'react';
import { Button } from 'react-native-paper';
import { styles } from './style';

type Props = {
  icon?: any;
  onClick: () => void;
  mode: 'text' | 'outlined' | 'contained';
  children: any;
  iconColor?: any;
};

const ShareButton = ({
  mode,
  icon,
  iconColor = theme.colors.primary,
  onClick,
  children,
}: Props) => {
  const renderIcon = (icon: string) => {
    if (!icon) return null;
    return <Icon size={15} color={iconColor} name={icon} />;
  };

  return (
    <Button
      icon={() => renderIcon(icon)}
      mode={mode}
      onPress={onClick}
      style={styles.container}
      contentStyle={{ paddingVertical: 5, alignItems: 'baseline' }}
    >
      {children}
    </Button>
  );
};

export default ShareButton;
