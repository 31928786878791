import { IList, IRecord } from '@common/types/';
import { pick } from 'lodash';
import { Platform, StyleSheet } from 'react-native';

const createStyle = (attrs: IList, records: IRecord[]) => {
  return StyleSheet.create({
    loading: {
      ...pick(attrs, ['marginTop', 'marginLeft', 'width', 'zIndex', 'height']),
      justifyContent: 'center',
      alignItems: 'center',
    },

    loadMoreText: {
      fontSize: 14,
      textDecorationLine: 'underline',
      fontWeight: 'bold',
      textAlign: 'center',
    },

    cusListWrapper: {
      ...pick(attrs, [
        'marginTop',
        'marginLeft',
        'width',
        'backgroundColor',
        'opacity',
        'zIndex',
        // 'height',
      ]),
      ...(((!(records?.length || 0) && Platform.OS === 'web') ||
        attrs.isWeb) && {
        height: attrs.height,
        // comment for related 1842: fix visible scroll when list empty record
        overflowX: attrs.isWeb
          ? 'hidden'
          : records?.length === 0
          ? 'visible'
          : 'scroll',
        overflowY: 'visible',
      }),
    },

    emptyListWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      zIndex: attrs.zIndex,
    },

    emptyRecord: {
      ...pick(attrs, ['marginTop', 'marginLeft', 'height', 'width']),
    },
  });
};

export default createStyle;
