import React, { Suspense } from 'react';
import { Platform } from 'react-native';
const routerApp = require('react-router-native');
const routerDOM = require('react-router-dom');

export function useHistory() {
  if (Platform.OS == 'web') {
    return routerDOM.useHistory();
  } else {
    return routerApp.useHistory();
  }
}

export function useParams() {
  if (Platform.OS == 'web') {
    return routerDOM.useParams();
  } else {
    return routerApp.useParams();
  }
}

export function useLocation() {
  if (Platform.OS == 'web') {
    return routerDOM.useLocation();
  } else {
    return routerApp.useLocation();
  }
}
