import React, { FC, useState, useEffect, useRef } from 'react';
import { View, FlatList, ActivityIndicator, Platform } from 'react-native';
import { get } from 'lodash';

import { IAvatarList } from '@nocode/types';
import EmptyList from '../EmptyList';
import AvatarItem from './Avatar';
import Icon from '../AppBar/Icon';
import useAvatarList, { Props } from './hook';

const AvatarListLayout: FC<Props> = (props) => {
  const flatListRef: any = useRef();
  const [page, setPage] = useState<number>(1);

  const maximum = get(props, 'attributes.items.source.options.limit', null);

  const {
    tableId,
    target,
    listData,
    styles,
    isWeb,
    opacity,
    backgroundColor,
    records,
    isCanvas,
    onPress,
  } = props;

  if (target && !tableId && !props.loading)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );

  useEffect(() => {
    setPage(1);
    flatListRef.current.scrollToOffset({ animated: true, offset: 0, x: 0 });
  }, [props.id]);

  useEffect(() => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0, x: 0 });

    if (props?.hasAction) {
      props.setLoadMore && props.setLoadMore(1);
      setPage(1);
    }
  }, [props?.hasAction]);

  return (
    <View
      //@ts-ignore
      style={[
        {
          opacity,
        },
        styles.wapperList,
      ]}
    >
      <FlatList
        data={listData}
        horizontal
        // pagingEnabled={Platform.OS === 'web'}
        scrollEnabled
        snapToAlignment="start"
        scrollEventThrottle={16}
        decelerationRate={'normal'}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        ref={flatListRef}
        onEndReachedThreshold={0.1}
        {...(props?.loading && {
          ListFooterComponent: (
            <View
              style={{
                display: 'flex',
                height: props.height - 30,
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="small" color="#1976d2" />
            </View>
          ),
        })}
        {...(!props?.loading &&
          listData.length >= 10 && {
            onEndReached: () => {
              if (
                listData.length === props?.total ||
                listData.length === maximum
              ) {
                setPage(1);
                return;
              }

              setPage(page + 1);
              props.setLoadMore && props.setLoadMore(page + 1);
            },
          })}
        renderItem={({ item, index }) => (
          <AvatarItem
            item={item}
            key={index}
            index={index}
            attributes={props.attributes}
            onPress={onPress}
            isWeb={isWeb}
            records={records}
          />
        )}
        keyExtractor={(item, index) => `${(item?._id, index)}`}
      />

      {isCanvas && <Icon name="ellipsis-horizontal" />}
    </View>
  );
};

const AvatarList: FC<IAvatarList> = (props) => (
  <AvatarListLayout {...useAvatarList(props)} />
);

export default AvatarList;
