import React, { Dispatch, SetStateAction, useState } from 'react';
import { View, Text, TextInput } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { get } from 'lodash';

import store from '@common/redux/store';
import {
  setForgotPassword,
  setTemporayPassword,
} from '@common/redux/slice/action';
import createStyles from './style';
import { Response } from './index';
import { convertMessage, useResetPassword } from './func';
import { useSelector } from 'react-redux';
import { passwordTemporary } from '@common/redux/selectors/action';
import { appInfoSelector } from '@common/redux/selectors/app';

interface IProps {
  email: string;
  appId: string;
  locale: string;
}

const ResetPassword = ({ email, appId, locale }: IProps) => {
  const dispatch = store.dispatch;
  const appInfo = useSelector(appInfoSelector);
  const getPasswordTemporary = useSelector(passwordTemporary);

  const styles = createStyles(appInfo);

  const isJa = locale === 'ja';

  const [value, setValue] = useState<string>('');
  const [response, setResponse] = useState<Response>({
    status: false,
    loading: false,
    error: '',
  });

  const onSubmit = async () => {
    try {
      setResponse({
        ...response,
        loading: true,
      });

      const res = await useResetPassword({
        appId,
        email,
        value,
        passwordTemporary: getPasswordTemporary,
      });

      if (!!res) {
        dispatch(setTemporayPassword(''));
        dispatch(
          setForgotPassword({
            state: false,
            animation: 'fadeOutDownBig',
          })
        );
      }

      setResponse({
        ...response,
        status: !!res,
        loading: false,
      });
    } catch (err: any) {
      setResponse({
        ...response,
        error: get(err, 'response')
          ? 'new password is not allowed to be empty'
          : get(err, 'response.data.errors.email', ''),
      });
      console.log('err :>> ', err);
    }
  };

  return (
    <View
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%',
      }}
    >
      <View style={styles.wrapper}>
        <Text style={styles.title}>
          {isJa ? '新しいパスワードの作成' : 'Create New Password'}
        </Text>
        <Text style={styles.desc}>
          {isJa
            ? '仮パスワードで正常にログインしました。新しいパスワードを再設定してください。'
            : `You've successfully logged in with your temporary password. Please create a new permanent password for your account.`}
        </Text>

        <Text style={styles.text}>
          {isJa ? '新しいパスワード' : 'New Pasword'}
        </Text>
        <TextInput
          style={styles.input}
          onChangeText={(e) => setValue(e)}
          placeholder={
            isJa
              ? '新しいパスワードを入力してください'
              : 'Enter new password...'
          }
          placeholderTextColor="#777777"
          secureTextEntry
        />
        <Text style={styles.error}>
          {convertMessage({ message: response.error, locale: locale })}
        </Text>

        <RNButton
          onPress={onSubmit}
          labelStyle={{
            color: '#fff',
            fontSize: 14,
          }}
          style={{
            backgroundColor: response.loading ? '#BDBDBD' : '#34A0DD',
            borderRadius: 4,
            minHeight: 40,
            justifyContent: 'center',
            marginTop: 16,
          }}
          disabled={response.loading}
          loading={response.loading}
        >
          {isJa ? '送信' : 'Submit'}
        </RNButton>
      </View>
    </View>
  );
};

export default ResetPassword;
