import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CustomAction = {
  customActions: any[];
};

type ActionCustomAction = {
  actionCustomActions: any[];
};

type State = {
  customActions: any[];
  actionCustomActions: any[];
  isAction: any;
};

const initialState: State = {
  customActions: [],
  actionCustomActions: [],
  isAction: {
    actionType: '',
    databaseId: '',
  },
};

const appSlice = createSlice({
  name: 'customAction',
  initialState: initialState,
  reducers: {
    updateCustomAction: (state: State, action: PayloadAction<CustomAction>) => {
      if (action.payload) {
        state.customActions = action.payload.customActions;
      }
    },
    actionCustomAction: (
      state: State,
      action: PayloadAction<ActionCustomAction>
    ) => {
      if (action.payload) {
        state.actionCustomActions = action.payload.actionCustomActions;
      }
    },
    setActionType: (state: State, action: PayloadAction<any>) => {
      state.isAction = action.payload;
    },
  },
});

export const { updateCustomAction, actionCustomAction, setActionType } =
  appSlice.actions;
export default appSlice.reducer;
