import { DefaultTheme } from 'react-native-paper';
export const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: '#4259AC',
    background: '#fff',
    // accent: "rgb(0, 168, 152)",
  },
};

export const FONT_SIZES = {
  base: 12,
};
