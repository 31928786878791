import React from 'react';
import { Text, View } from 'react-native';

type IProps = {
  indexQuestion: number;
  textQuestion: string;
  numberLabelsStyle: any;
};

const NumberLabel: React.FC<IProps> = ({
  indexQuestion,
  textQuestion,
  numberLabelsStyle,
}) => {
  return (
    <View style={{ height: '100%' }}>
      <Text
        style={{
          ...numberLabelsStyle,
          marginRight: 5,
          height: numberLabelsStyle.fontSize * 2,
          width: numberLabelsStyle.fontSize * 2,
          borderRadius: numberLabelsStyle.fontSize,
          overflow: 'hidden',
          textAlign: 'center',
          lineHeight: numberLabelsStyle.fontSize * 2,
        }}
        numberOfLines={1}
      >
        {textQuestion}
        {indexQuestion}
      </Text>
    </View>
  );
};

export default NumberLabel;
