import { ICardList } from '@nocode/types';
import { get } from 'lodash';
import queryString from 'query-string';

import { Platform } from 'react-native';

import { HORIZONTAL_CARD_LIST } from '../HorizontalCardList';

export const getInitValues = (
  attrs: Record<string, any> = {},
  type?: string
) => {
  let arr: any[] = [];
  for (let i = 1; i <= 4; i++) {
    if (type === HORIZONTAL_CARD_LIST) {
      arr.push({
        title: attrs.title.text,
        subTitle: attrs.subTitle.text,
        subTitle2: attrs.subTitle2.text,
        tag: attrs.tag.text,
        footer: attrs.footer.text,
      });
    } else {
      arr.push({
        title: attrs.title.text,
        subTitle: attrs.subTitle.text,
        subTitle2: attrs.subTitle2.text,
        button1: attrs.button1.text,
        button2: 'Button 2',
      });
    }
  }

  return arr;
};

export const getItemList = (attrs: ICardList, type?: string) => {
  const { dataBinding, attributes } = attrs;

  const columnCount = get(attributes, 'columnCount', 2);

  if (Platform.OS !== 'web') {
    // App
    return dataBinding || [];
  } else {
    // web
    const search = queryString.parse(window?.location?.search);
    const target = search?.target;

    if (target) {
      // preview
      return dataBinding || [];
    } else {
      // client

      const listData = dataBinding || getInitValues(attributes, type);

      return columnCount === 1
        ? listData.slice(0, 2)
        : columnCount === 3
        ? listData.slice(0, 3)
        : listData;
    }
  }
};
