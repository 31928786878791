import CusImageUpload from '@common/components/ImageUpload';
import CusInput from '@common/components/Input';
import CusSelect from '@common/components/Select';
import BindingComponent from '@common/screens/RenderScreen/BindingComponent';
import { ComponentType } from '@common/types/element';
import React, { FC } from 'react';

const mappingInput: { [key: string]: any } = {
  [ComponentType.input]: CusInput,
  [ComponentType.select]: CusSelect,
  [ComponentType.imageUpload]: CusImageUpload,
};

type IProps = {
  inputProps: any;
};

const BindingField: FC<IProps> = ({ inputProps }) => {
  const InputObject = mappingInput[inputProps.type] as FC;
  return <BindingComponent ObjectClass={InputObject} obj={inputProps} />;
};

export default BindingField;
