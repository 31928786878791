import { authSelector } from '@common/redux/selectors/auth';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { setReloadStatus } from '@common/redux/slice/database';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRefreshTable = (tableId: any, refresh = false) => {
  const dispatch = useDispatch();
  const currentRecord = useSelector(getCurrentRecord) || {};

  const { profile } = useSelector(authSelector);

  useEffect(() => {
    try {
      let fetchData: any;
      if (tableId && refresh) {
        fetchData = setInterval(() => {
          if (tableId) {
            dispatch(setReloadStatus(true));
          }
        }, 5000);
      }
      return () => {
        if (fetchData) {
          clearInterval(fetchData);
        }
      };
    } catch (error) {
      console.log('error', error);
    }
  }, [tableId, refresh, currentRecord, profile]);
};
