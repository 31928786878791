import store from '@common/redux/store';
import { setError } from '@common/redux/slice/formInput';
import { handleCancelUnivaPaySubscription } from '../../func/apiFunction';

const cancelUnivaPaySubscription: (
  metadata: any,
  locale?: string
) => Promise<any> = async (metadata: any, locale?: string) => {
  const state: any = store.getState();
  const appInfo = state.appInfo.app;
  const dispatch = store.dispatch;

  try {
    const { valueUnivaPaySubscription } = metadata || {};
    const { idUnivaPaySubscription } = valueUnivaPaySubscription || {};

    const newPayload = {
      appId: appInfo.id,
      idUnivaPaySubscription: idUnivaPaySubscription,
    };

    await handleCancelUnivaPaySubscription(newPayload);

    return {
      status: 'SUCCEED',
      message: 'Cancel subscription successful',
    };
  } catch (error: any) {
    dispatch(
      setError({
        ...error,
        message: 'Cancel subscription failed',
      })
    );
    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default cancelUnivaPaySubscription;
