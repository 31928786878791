import { IImageUpload } from '@common/types/element';
import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: IImageUpload) => {
  return StyleSheet.create({
    text: {
      fontSize: 16,
      color: 'orange',
      fontFamily: 'Roboto',
    },
    innerWrapper: {
      ...pick(attributes, [
        'opacity',
        'width',
        'height',
        'marginTop',
        'marginLeft',
        'borderRadius',
      ]),
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      zIndex: attributes.zIndex,
      // borderStyle:
      //   attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
    },
    preview: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#eee',
    },
    previewImage: {
      position: 'absolute',
      // left: 0,
      // top: 0,
      width: '100%',
      height: '100%',
    },
    faded: {
      opacity: 0.5,
    },
    icon: {
      opacity: 0.2,
      width: 40,
      height: 30,
    },
    emptyWrapper: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    selectImage: {
      color: 'orange',
      fontWeight: '500',
      fontSize: 14,
    },
    selectAnother: {
      marginTop: 8,
      alignItems: 'center',
      position: 'absolute',
      zIndex: 99,
      justifyContent: 'center',
      alignSelf: 'center',
    },
    image: {
      flex: 1,
      justifyContent: 'center',
    },
    modalWrapper: {
      position: 'absolute',
      bottom: 26,
      right: 0,
      left: 0,
      width: '100%',
      padding: '2%',
    },
    contentModalWrapper: {
      overflow: 'hidden',
      borderRadius: 10,
    },
    select: {
      height: 50,
      backgroundColor: '#f1f1f1',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelModal: {
      color: '#3f7aff',
      fontWeight: 'bold',
      fontSize: 18,
    },
  });
};
export default createStyles;
