import React, { FC } from 'react';
import { Text, SafeAreaView } from 'react-native';
import createStyles from './style';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

type IEmptyList = {
  attributes: Record<string, any>;
};

const EmptyList: FC<IEmptyList> = ({ attributes }) => {
  const styles = createStyles(attributes);
  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const locale = search?.locale || 'ja';

  return (
    <SafeAreaView style={styles.container}>
      <Text style={styles.text}>
        {locale === 'en'
          ? 'Please select database first'
          : 'データベースの選択'}
      </Text>
    </SafeAreaView>
  );
};

export default EmptyList;
