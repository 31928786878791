import React, { memo, NamedExoticComponent, useState } from 'react';

type Props<T = any> = {
  onClose: () => void;
  WrappedComponent: NamedExoticComponent<T>;
  useCommunityProps?: any;
};

const ModalShareScreen: React.FC<Props> = ({
  WrappedComponent,
  onClose,
  useCommunityProps,
}) => {
  const [showModal, setShowModal] = useState<boolean>(true);
  return (
    <WrappedComponent
      onClose={() => {
        setShowModal(false);
        setTimeout(() => onClose(), 200);
      }}
      useCommunityProps={useCommunityProps}
      isShow={showModal}
    />
  );
};

export default memo(ModalShareScreen);
