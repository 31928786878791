import { RootState } from '../store';

export const databaseSelector = (state: RootState) => state.database;

export const getCurrentRecord = (state: RootState) =>
  state.database.currentRecord;

export const getCurrentLocation = (state: RootState) =>
  state.database.currentLocation;

export const getDataSourceStore = (state: RootState) =>
  state.database.dataSource;

export const getDataTableSourceStore = (state: RootState, tableId: string) =>
  state.database.dataSource[tableId];

export const getAuth = (state: RootState) => state.auth.profile;

export const getApp = (state: RootState) => state.appInfo.app;

export const getListDatabases = (state: RootState) => state.database.database;

export const getActions = (state: RootState) => state.customAction;

export const reFreshStatusSelector = (state: RootState) =>
  state.database.isReloadDatabase;
