import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import { updateCustomAction } from '@common/redux/slice/customAction';
import { IApp } from '@common/types/element';
import api from '@common/configs/api';

type Props = {
  appId: string;
  debug?: boolean;
};

type APIResponse<T> = {
  data: T | undefined;
  error: string | null;
  isLoading: boolean;
};

const loadProductionCustomActions = async (appId: string) => {
  try {
    const res: AxiosResponse<{ customActions: IApp }> = await api({
      method: 'get',
      url: `v2/apps/${appId}/versions/latest`,
    });
    return res?.data?.customActions || (await loadDevCustomActions(appId));
  } catch (error) {
    return await loadDevCustomActions(appId);
  }
};

const loadDevCustomActions = async (appId: string) => {
  const res: AxiosResponse<IApp> = await api({
    method: 'get',
    url: `v2/share-custom-actions/${appId}`,
  });
  return res?.data || [];
};

const getCusomActions = ({ appId, debug }: Props) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState<APIResponse<IApp>>({
    data: undefined,
    error: null,
    isLoading: false,
  });

  useEffect(() => {
    const _fetchData = async () => {
      setResponse({ ...response, ...{ isLoading: true } });
      try {
        const customActions = debug
          ? await loadDevCustomActions(appId)
          : await loadProductionCustomActions(appId);
        setResponse({
          ...response,
          ...{ isLoading: false, data: customActions },
        });
        const data: any = customActions;
        dispatch(
          updateCustomAction({
            customActions: [...data],
          })
        );
      } catch (error) {
        setResponse({
          ...response,
          ...{ isLoading: false, error: 'get data error' },
        });
      }
    };

    _fetchData();
  }, []);

  return { ...response };
};

export default getCusomActions;
