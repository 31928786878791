import { BINDING_SELECTOR_TYPE } from '@common/constants/shared';
import { useBinding } from '@common/hooks/useBinding';
import { actionSelector } from '@common/redux/selectors/action';
import { authSelector } from '@common/redux/selectors/auth';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { getValueFields } from '@common/redux/selectors/formInput';
import { setDefaultValue } from '@common/redux/slice/formInput';
import { useLocation } from '@common/routes/hooks';
import { AutoFields, Field, IAction, IQuestion, Obj } from '@common/types';
import { getActions } from '@common/utils/database';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import { forEach, get, isEmpty, map } from 'lodash';
import qs from 'query-string';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import FormField from './Fields';
import { generateSchema, IField } from './schema';
import createStyles from './style';
import SubmitButton from './SubmitButton';

type State = {
  loading: boolean;
  notifications: string;
  formError: string;
  errors: Array<{
    id: string;
    message: string;
  }>;
};

export const ActionContext = createContext({});
export const useActionContext = () => useContext(ActionContext);

const CusQuestion: FC<IQuestion> = (object) => {
  const styles = createStyles(object);
  const dispatch = useDispatch();
  const reduxFormFields = useSelector(getValueFields);
  const currentRecord = useSelector(getCurrentRecord);
  const { handleBindingField } = useBinding();
  const { profile } = useSelector(authSelector);
  const urlSearchParams =
    window?.location && new URLSearchParams(window?.location.search);
  const locale = (urlSearchParams && urlSearchParams.get('locale')) || 'ja';

  const objectAction = useMemo(
    () => getActions(object.actions),
    [object.actions]
  );

  useEffect(() => {
    const defaultAction = objectAction[0];

    const idCollectionSelected = object?.database?.idCollectionSelected;

    const selectorType = defaultAction?.options?.selector?.type;

    if (defaultAction && defaultAction.actionType === 'updateObject') {
      if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
        dispatch(setDefaultValue(profile));
      } else if (!isEmpty(idCollectionSelected) && !isEmpty(currentRecord)) {
        const record = currentRecord[idCollectionSelected];
        dispatch(setDefaultValue(record?.record || {}));
      }
    }

    if (defaultAction && defaultAction.actionType === 'createObject') {
      if (object.fields.length) {
        // let fields = object.fields.map((o) => {
        //   if (o.type != 'dateOnly' && o.type != 'date') {
        //     return { [o.fieldId]: '' };
        //   }
        // });
        // dispatch(setDefaultValue(fields));

        let result: any = {};
        forEach(object.fields, (field) => {
          if (field.type != 'dateOnly' && field.type != 'date') {
            result[field.fieldId] = '';
          }
        });
        dispatch(setDefaultValue(result));
      }
    }
  }, [objectAction, currentRecord]);

  const [state, setState] = useState<State>({
    loading: false,
    errors: [],
    notifications: '',
    formError: '',
  });

  let { fields, submitButton, hidden } = object || {};

  const { error } = useSelector(actionSelector);

  const valuesChanged = useMemo(() => {
    let valueChanges: Obj = {};
    forEach(object.fields, (field) => {
      if (field?.fid) {
        valueChanges[field.fid] = reduxFormFields[field.fid];
      } else {
        valueChanges[field.fieldId] = reduxFormFields[field.fieldId];
      }
    });

    return valueChanges;
  }, [object.fields, reduxFormFields]);

  const handleSubmit = useCallback(() => {
    setState({
      ...state,
      loading: true,
      errors: [],
      notifications: '',
      formError: '',
    });
    const fields: Array<IField | any> = object.fields;
    const automaticFields: Array<AutoFields | any> = object.automaticFields;
    const schema = generateSchema(fields, locale);

    const autoValues: Record<string, any> = {};
    forEach(automaticFields, (item: AutoFields) => {
      const result = handleBindingField(item.value, currentRecord);

      const getSelectorType = get(item, 'value.selector.type', '');

      if (
        !isEmpty(getSelectorType) &&
        getSelectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR
      ) {
        return (autoValues[item.fid] = result.userId);
      } else {
        return (autoValues[item.fid] = result);
      }
    });
    const arrayAction = getActions(object.actions);
    const typeActions = ['signin', 'signup'];

    schema
      .validate(valuesChanged, { abortEarly: false })
      .then(async (val) => {
        val && setState({ ...state, errors: [], formError: '' });
        const fields = map(object.fields, (fields: Record<string, any>) => ({
          fieldId: fields.fid || fields.fieldId,
          ...fields,
        }));

        const parseActions = map(
          arrayAction,
          (action: IAction, index: number) => {
            return index === 0
              ? {
                  ...action,
                  options: {
                    ...action.options,
                    ...(typeActions.includes(action.actionType) && {
                      lock: true,
                    }),
                    fields: fields,
                  },
                  autoValues,
                }
              : { ...action, autoValues };
          }
        );

        setState({ ...state, errors: [], formError: '' });
        return actionPromise(parseActions, '', undefined, locale, true);
      })
      .then((data: any) => {
        if (data.status === 'FAILED') {
          setState({ ...state, formError: data.message, loading: false });
        } else {
          setState({
            ...state,
            errors: [],
            notifications: data.message,
            loading: false,
            formError: '',
          });
        }
      })
      .catch(function (err: any) {
        let errors: any[] = [];
        if (!isEmpty(err.inner)) {
          err.inner.forEach((e: any) => {
            errors.push({ id: e.path, message: e.message });
          });
        }
        setState({ ...state, loading: false, errors, notifications: '' });
      });
  }, [object.fields, valuesChanged, currentRecord]);

  const renderMessage = useCallback(() => {
    return (
      <View>
        <Text style={{ color: 'green' }}>{state.notifications}</Text>
      </View>
    );
  }, [state.notifications]);

  const renderError = useCallback(() => {
    return (
      <View>
        <Text style={{ color: 'red' }}>{state.formError}</Text>
      </View>
    );
  }, [error, state.formError]);

  //fix error not reset value ==========
  const { search } = useLocation();
  const params = qs.parse(search);
  // const targetRef = useRef(params?.target);
  useEffect(() => {
    setState((pre) => ({
      ...pre,
      errors: [],
      formError: '',
      notifications: '',
    }));

    const arrayAction = getActions(object.actions);

    const defaultAction = arrayAction[0];

    if (
      defaultAction &&
      defaultAction.actionType !== 'updateObject'
      // &&
      // targetRef.current === params?.target
    ) {
      if (object.fields.length) {
        let result: any = {};
        forEach(object.fields, (field) => {
          if (field.type != 'dateOnly' && field.type != 'date') {
            result[field.fieldId] = '';
          }
        });
        dispatch(setDefaultValue(result));
      }
    }
  }, [params?.target]);
  // fix error not reset value ==========

  return object.database ? (
    <View style={styles.container}>
      {fields &&
        fields.map((field: Field, index) => {
          const fieldId = field?.fid || field.fieldId;
          // * check if field has an error
          const hasError = state.errors.find((err) => err.id === fieldId);

          return (
            <FormField
              key={fieldId}
              object={object}
              field={field}
              active={hidden}
              hasError={hasError}
              index={index}
            />
          );
        })}
      {state.formError ? renderError() : null}
      {!state.formError && state.notifications ? renderMessage() : null}

      <SubmitButton
        {...submitButton}
        marginTop={fields.length ? 20 : 0}
        onSubmit={handleSubmit}
        submitting={state.loading}
        disabled={state.loading}
      />
    </View>
  ) : (
    <></>
  );
};

export default CusQuestion;
