import { StyleSheet } from 'react-native';
import { checkFont } from '../func';

const createStyles = (styleData: Record<string, any>) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 40,
      width: styleData.width,
      opacity: styleData.opacity,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: styleData.borderRadius,
      borderWidth: 1,
      borderColor: styleData.type === 'contained' ? '#CC0033' : '#848484',
      position: 'relative',
      overflow: 'hidden',
    },
    imageWrapper: {
      paddingVertical: 6,
      paddingHorizontal: 10,
    },
    iconImage: {
      height: 24,
      width: 18,
    },
    textWrapper: {
      height: 60,
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      paddingHorizontal: 18,
      backgroundColor:
        styleData.type === 'contained' ? styleData.backgroundColor : '#FFFFFF',
      borderLeftColor: '#CC0033',
      borderLeftWidth: styleData.type === 'contained' ? 0 : 1,
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      alignContent: 'center',
    },
    text: {
      fontSize: styleData.fontSize,
      color: styleData.type === 'contained' ? styleData.color : '#333333',
      fontFamily: checkFont(styleData.fontFamily),
      fontWeight: styleData.styles?.fontWeight,
      opacity: styleData.opacity,
      textAlign: 'center',
    },
    errorContainer: {
      flexDirection: 'row',
      borderRadius: styleData.borderRadius,
      opacity: styleData.opacity,
      textAlign: 'center',
      borderColor: '#000000',
      borderWidth: 2,
    },
    errorText: {
      padding: 5,
      margin: 'auto',
      fontSize: 16,
      color: '#ff0000',
      fontFamily: styleData.styles?.fontFamily,
      fontWeight: styleData.styles?.fontWeight,
    },
  });
};

export default createStyles;
