import { pick } from 'lodash';
import { StyleSheet, Platform } from 'react-native';
import { checkFont } from '../func';

const createStyles = (attributes: any) => {
  const getShadow = () => {
    if (!attributes.shadow) return {};

    return {
      shadowColor: attributes.shadow.color,
      shadowRadius: attributes.shadow.size,
      shadowOpacity: attributes?.shadow?.blur || 0,
      shadowOffset: {
        width: attributes.shadow.x,
        height: attributes.shadow.y,
      },
      elevation:
        attributes?.shadow?.size === 0
          ? attributes?.shadow?.x
          : attributes?.shadow?.size,
    };
  };

  const getFontWeight = () => {
    if (!attributes.fontWeight) return 'normal';

    return typeof attributes.fontWeight === 'string'
      ? attributes.fontWeight
      : attributes.fontWeight.toString();
  };

  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'borderRadius',
        'width',
        'backgroundColor',
        'height',
        'opacity',
        'backgroundColor',
      ]),
      zIndex: attributes.zIndex,
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      minHeight: attributes.height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...getShadow(),
    },
    text: {
      ...Platform.select({
        android: {
          lineHeight: attributes.fontSize * 1.15,
        },
      }),
      ...pick(attributes, [
        'color',
        //  'width', 'height',
        'fontSize',
      ]),
      // ...(attributes?.shadow?.size > 0 ? getShadow() : {}),
      // ...getShadow(),
      fontWeight: getFontWeight(),
      // ...pick(attributes, [
      //   // 'backgroundColor',
      //   'fontSize',
      //   // 'padding',
      //   'opacity',
      // ]),
      // ...(attributes.borderStyle !== 'none'
      //   ? pick(attributes, ['borderWidth', 'borderColor'])
      //   : {}),
      // borderStyle:
      //   attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      // minHeight: attributes.height,
      fontFamily: checkFont(attributes.fontFamily),
      // paddingTop: 0,
      // paddingBottom: 0,
      // update for bug android not same ios
      // paddingLeft: attributes?.padding || 0,
      // overflow: 'hidden',
    },
  });
};

export default createStyles;
