import { reduce } from 'lodash';
import api from '@common/configs/api';
import {
  ANIMATION_MODE,
  bindType,
  componentName,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  desktopWidth,
  FIELD_TYPE,
  IMAGE_TYPE,
  rowType,
} from '@common/constants/shared';
import { getValueFields } from '@common/redux/selectors/formInput';
import { updateLoggedUserSuccess } from '@common/redux/slice/auth';
import { updateExternal } from '@common/redux/slice/externalCollection';
import store from '@common/redux/store';
import history from '@common/routes/history';
import { useLocation } from '@common/routes/hooks';
import { IPage } from '@common/types';
import { IRecord } from '@common/types/database';
import {
  getFieldTable,
  getRecordExternalCollection,
} from '@common/utils/database';
import { isString } from 'formik';
import {
  ceil,
  filter,
  find,
  get,
  groupBy,
  isArray,
  isEmpty,
  isNil,
  isNull,
  isUndefined,
  map,
  max,
  mean,
  min,
  pick,
  round,
  some,
  sortBy,
  sum,
} from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import { Platform } from 'react-native';
import { getBindingRecord, getImageBinding } from './handleBinding';
import { flatSource } from './handleBinding/function';

export const typeRelation = [
  'belongsToRelation',
  'manyToManyRelation',
  'hasManyRelation',
];

const objectComponentName = {
  Calendar: 'Calendar',
  SimpleList: 'SimpleList',
  CardList: 'CardList',
  Select: 'select',
  LIST: 'list',
  MAP: 'Map',
  Carousel: 'Carousel',
};

const objectPath = {
  LIBRARY_COMPONENT: {
    sort: 'attributes.items.source.sort',
    filter: 'attributes.items.source.options.filter',
    limit: 'attributes.items.source.options.limit',
    source: 'attributes.items.source',
  },
  SELECT: {
    sort: 'database.source.sort',
    source: 'database.source',
    filter: 'database.source.options.filter',
    limit: 'database.source.options.limit',
  },
  LIST: {
    sort: 'dataBinding.source.sort',
    source: 'dataBinding.source',
    filter: 'dataBinding.source.options.filter',
    limit: 'dataBinding.source.options.limit',
  },
  MAP: {
    sort: 'attributes.markerCollection.source.sort',
    filter: 'attributes.markerCollection.source.options.filter',
    limit: 'attributes.markerCollection.source.options.limit',
    source: 'attributes.markerCollection.source',
  },
};

export const bindingType = {
  SUM: 'sum',
  COUNT: 'count',
  AVERAGE: 'avg',
  MIN: 'min',
  MAX: 'max',
  MIN_MAX: 'minMax',
  INPUT: 'input',
  DATE_TIME: 'dateTime',
  CURRENT_LOCATION: 'currentLocation',
  IMAGE_BINDING: 'imageBinding',
  FIELD: 'field',
  HAS_MANY_ONE_RELATIONSHIP: 'hasManyRelation',
  MANY_TO_MANY_RELATIONSHIP: 'manyToManyRelation',
  BE_LONG_RELATIONSHIP: 'belongsToRelation',
  DATA: 'data',
  CUSTOMACTION: 'customAction',
  PLUGIN: 'plugin',
  ID_UNIVAPAY_SUBSCRIPTION: 'idUnivaPaySubscription',
};

export const manyRelation = ['hasManyRelation', 'manyToManyRelation'];

export type SortAndFilterType = {
  sort: object | any;
  filter: Array<any> | any;
  limit: number;
  source: any;
};

export type SortConditionType = {
  sort: any;
};

export type FilterItem = {
  comparison: string | any;
  comparison2?: any;
  fieldId: string | any;
  id: string;
  comparator: string;
  source?: any;
};

//Function format screen ============
interface Props {
  screens?: Array<IPage>;
  screen: IPage;
}

export const formatScreens = ({ screens = [], screen }: Props) => {
  return {
    list: () => ({
      toView: () =>
        map(screens, (s) => ({
          ...s,
          attributes: JSON.parse(get(s, 'attributes', '{}')),
          metadata: JSON.parse(get(s, 'metadata')),
        })),
      toMutate: () =>
        map(screens, (s) => ({
          ...s,
          attributes: JSON.stringify(get(s, 'attributes', {})),
          metadata: JSON.stringify(get(s, 'metadata')),
        })),
    }),
    single: () => ({
      toView: () => ({
        ...screen,
        attributes: JSON.parse(get(screen, 'attributes', '{}')),
        metadata: JSON.parse(get(screen, 'metadata')),
      }),
      toPatch: () => ({
        ...screen,
        attributes: JSON.stringify(get(screen, 'attributes', {})),
        metadata: JSON.stringify(get(screen, 'metadata')),
      }),
    }),
  };
};
//Function format screen ============

// Binding function ===============

export const getValueImagePicker = (attributes: any) => {
  const defaultValue = attributes?.defaultValue;
  const state: any = store.getState();
  const valueInput = getValueFields(state);

  if (isEmpty(defaultValue)) return '';

  const imageURL = defaultValue?.imageUrl;

  switch (defaultValue?.imageType) {
    case IMAGE_TYPE.URL:
      if (!imageURL) return;
      const getSoure: any = imageURL.filter((item: any) => item.id);

      if (!isEmpty(getSoure)) {
        const urlBinding = getImageBinding(
          imageURL,
          get(attributes, 'record', {})
        );

        return urlBinding;
      }

      if (Array.isArray(imageURL)) return imageURL[0];
      else {
        return imageURL;
      }
    case IMAGE_TYPE.UPLOAD:
      return defaultValue?.imageUploaded;
    case 'internal':
      const source = defaultValue?.binding?.source;

      if (source?.type === 'input') {
        const objectId = source?.objectId;
        return valueInput[objectId] || null;
      } else {
        // const flattenSource = flattenObj(source);

        // const image = excuteRoundRelationship(
        //   source,
        //   flattenSource,
        //   attributes?.record
        // );

        return source;
      }
  }
};

export const getValueBindingInput = (
  value: any,
  itemIndex?: number,
  initValBinding?: Record<string, any>
) => {
  const state: any = store.getState();

  const valuesInputs = state.formInputs.values;

  const objectId = value?.source?.objectId || value?.objectId;

  const index = !isNil(itemIndex) ? itemIndex : initValBinding?._id;

  const isItemList = !isUndefined(index) && index >= 0;

  if (!isItemList) {
    return get(valuesInputs, objectId, '');
  } else {
    const inputId = isString(objectId)
      ? `${objectId}__${index}`
      : `${objectId[objectId.length - 1]}__${index}`;

    return get(valuesInputs, inputId, '') || get(valuesInputs, objectId, '');
  }
};

export const getValueBindingPlugin = (
  value: any,
  itemIndex?: number,
  initValBinding?: Record<string, any>
) => {
  const state: any = store.getState();

  const valuesInputs = state.formInputs.values;

  const objectId = value?.source?.objectId || value?.objectId;

  const index = itemIndex ? itemIndex : initValBinding?._id;

  const result = get(
    valuesInputs,
    isArray(objectId)
      ? isUndefined(valuesInputs[objectId[1]])
        ? `${objectId[1]}__${index}`
        : objectId[1]
      : objectId,
    ''
  );

  return result;
};

export const getDatabaseUidObj = (obj: any) =>
  obj?.dataBinding?.tableId ||
  obj?.dataBinding?.source?.tableId ||
  obj?.attributes?.items?.source?.tableId ||
  obj?.attributes?.database?.idCollectionSelected ||
  obj?.database?.source?.tableId;

// Binding function ===============

// Filter and Sort function ==============
export const getSortCondition = (obj: any) => {
  let conditionalSort;

  const sortValueData: SortAndFilterType = getSortAndFilterData(obj);

  if (!isNull(sortValueData.sort) && sortValueData.sort !== 'none') {
    conditionalSort = sortValueData;
  } else {
    conditionalSort = {
      sort: {
        direction: 'desc',
        fieldId: 'createdAt',
        fieldType: 'date',
        value: 'createdAt-desc',
      },
    };
  }

  return {
    sort: get(conditionalSort, 'sort'),
  };
};

export const getSortAndFilterData = (obj: any) => {
  const componentName = obj.componentName;

  if (obj?.isPlugin) {
    return {
      sort: get(obj, objectPath.LIBRARY_COMPONENT.sort, null),
      filter: get(obj, objectPath.LIBRARY_COMPONENT.filter, null),
      limit: get(obj, objectPath.LIBRARY_COMPONENT.limit, null),
      source: get(obj, objectPath.LIBRARY_COMPONENT.source, null),
    };
  }

  switch (componentName) {
    case objectComponentName.Calendar:
    case objectComponentName.SimpleList:
    case objectComponentName.CardList:
    case objectComponentName.Carousel:
      return {
        sort: get(obj, objectPath.LIBRARY_COMPONENT.sort, null),
        filter: get(obj, objectPath.LIBRARY_COMPONENT.filter, null),
        limit: get(obj, objectPath.LIBRARY_COMPONENT.limit, null),
        source: get(obj, objectPath.LIBRARY_COMPONENT.source, null),
      };
    case objectComponentName.Select:
      return {
        sort: get(obj, objectPath.SELECT.sort, null),
        filter: get(obj, objectPath.SELECT.filter, null),
        limit: get(obj, objectPath.SELECT.limit, null),
        source: get(obj, objectPath.SELECT.source, null),
      };
    case objectComponentName.MAP:
      return {
        sort: get(obj, objectPath.MAP.sort, null),
        filter: get(obj, objectPath.MAP.filter, null),
        limit: get(obj, objectPath.MAP.limit, null),
        source: get(obj, objectPath.MAP.source, null),
      };

    default:
      return {
        sort: get(obj, objectPath.LIST.sort, null),
        filter: get(obj, objectPath.LIST.filter, null),
        limit: get(obj, objectPath.LIST.limit, null),
        source: get(obj, objectPath.LIST.source, null),
      };
  }
};

export const getDataTypeFilter = (
  fieldId: any,
  value: any,
  databaseUuid: string
) => {
  if (
    typeof fieldId === 'string' &&
    !['createdAt', 'updatedAt'].includes(fieldId)
  ) {
    if (!databaseUuid) return typeof value;

    const field = getFieldTable(databaseUuid, fieldId) || {};

    const fieldType = field?.type;

    switch (fieldType) {
      case 'image':
        return 'object';

      case 'boolean':
        return 'boolean';

      case 'dateOnly':
      case 'date':
        return FIELD_TYPE.TIMESTAMP;

      case 'number':
        return 'number';

      default:
        return 'string';
    }
  } else if (['createdAt', 'updatedAt'].includes(fieldId)) {
    return FIELD_TYPE.TIMESTAMP;
  }
  return fieldId?.source?.dataType || 'string';
};

export const getValuePaginate = (
  record: IRecord,
  params: any,
  fieldId: any,
  databaseUuid: string,
  table?: any
) => {
  let value;
  if (isString(fieldId)) {
    const field = getFieldTable(databaseUuid, fieldId) || {};
    let value = get(record, fieldId, '');

    if (table && table.isThirdParty) {
      value = '';
      if (field?.key) {
        value = get(record, field?.key?.replace('[0].', ''));
      } else {
        value = record;
      }
    }

    return value;
  } else {
    value = getBindingRecord(fieldId?.source, {}, record);
  }

  return value;
};

export const getComparisonValue = (
  comparison: any,
  record: any,
  params: any
) => {
  if (isString(comparison)) return comparison;

  return getBindingRecord(comparison, params, record) || '';
};

export const arrayToMap = (array = [], key = 'id') => {
  const map = {};

  array.forEach((item) => {
    map[item[key]] = item;
  });

  return map;
};

export const getDatatype = (field: any): any => {
  if (!field) return false;
  if (field.dataType) {
    return field.dataType;
  }
  return getDatatype(field.source);
};

export const getMath = (math: any, value: any): any => {
  const convertValue: any = filter(value, (o) => {
    return !isNil(o);
  });

  const values: any = filter(
    convertValue.map((i: any) => Number(i)),
    (o: any) => {
      return !isNaN(o);
    }
  );

  if (isEmpty(values)) {
    return null;
  }

  switch (math) {
    case 'sum':
      return sum(values);
    case 'min':
      return min(values);
    case 'max':
      return max(values);
    case 'average':
      return mean(values);
    case 'division':
    //   return min(values) / max(values);
    default: {
      return 0;
    }
  }
};

// Filter and Sort function ==============

//Iframe function ===============
export const calcScale = (parent: any, children: any) => {
  if (isEmpty(parent) || isEmpty(children)) return 1;
  const scaleX = parent.width / children.width;
  const scaleY = parent.height / children.height;
  const scale = Math.min(scaleX, scaleY);
  return ceil(scale, 6);
};

export const getElementInfo = (element: any) => {
  if (!element) return null;

  const width = element.clientWidth;
  const height = element.clientHeight;

  return { width, height };
};

//Iframe function=================

//More function =============
export const checkMobileLayout = (dimension: any) => {
  return dimension.width < desktopWidth;
};

export const formatTime = (value: any, field: any) => {
  if (!field || !value) {
    if (value === 0) return 0;
    return '';
  }
  if (field.type === 'timestamp') {
    return moment(value).format(DATE_TIME_FORMAT);
  }

  return value;
};

// export const getId = () => uuidv4();

export const getDeviceLocation = () => {
  const { search } = useLocation();

  const searchJSON = qs.parse(search);

  return get(searchJSON, 'device', '');
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const copyToClipboard = (value: string) => {
  if (value) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator?.clipboard?.writeText(value).then(() => alert('Link copied!'));
    } else {
      const linkCopy = document.createElement('textarea');
      linkCopy.value = value;
      document.body.appendChild(linkCopy);
      linkCopy.focus();
      linkCopy.select();
      document.execCommand('copy');
      linkCopy.remove();
      alert('Link copied!');
    }
  } else {
    alert('error ?');
  }
};

export const getId = () => {
  return `${+new Date()}-${(Math.random() * 100000).toFixed(0)}`;
};

export const animatedScreen = (value: any) => {
  let transition = typeof value == 'string' ? value : 'TRANSITION_NONE';
  switch (transition) {
    case 'TRANSITION_FLOAT_RIGHT':
      return ANIMATION_MODE.floatLeft;
    case 'TRANSITION_FLOAT_UP':
      return ANIMATION_MODE.floatUp;
    case 'TRANSITION_FLOAT_LEFT':
      return ANIMATION_MODE.floatRight;
    case 'TRANSITION_FLOAT_DOWN':
      return ANIMATION_MODE.floatDown;
    case 'TRANSITION_SLIDE_LEFT':
      return ANIMATION_MODE.slideRight;
    case 'TRANSITION_SLIDE_RIGHT':
      return ANIMATION_MODE.slideLeft;
    case 'TRANSITION_SLIDE_UP':
      return ANIMATION_MODE.slideUp;
    case 'TRANSITION_SLIDE_DOWN':
      return ANIMATION_MODE.slideDown;
    default:
      return ANIMATION_MODE.none;
  }
};

//More function ===========

let listUuid: any = [];
let listExternals: any = [];
//binding source healthy======
export const bindSourceHealthy = async (value: any) => {
  if (isString(value)) {
    return value;
  }

  if (isArray(value)) {
    const source = await Promise.all(
      map(value, (item: any) => {
        const isCal = [
          'Count',
          'Minimum',
          'Min / Max',
          'Maximum',
          'Sum',
          'Average',
        ].includes(item?.label);

        if (item?.type === 'health') {
          return '0';
        } else if (item?.type === 'binding' && isCal) {
          return setDataExternal(item);
        }
        return item;
      })
    );

    return source;
  }

  if (value?.type === 'health') {
    return '0';
  }

  return await value;
};

const setDataExternal = async (item: any) => {
  const tableId = item?.source?.source?.tableId;

  if (listUuid.indexOf(tableId) === -1) {
    listUuid.push(tableId);
    const state: any = store.getState();
    const listDatabases = state.database.database;
    const mainTable = find(listDatabases, {
      databaseUuid: item?.source?.source?.tableId,
    });
    const externalCollections = state.externalCollection.externalCollections;
    const external = find(externalCollections, {
      databaseUuid: item?.source?.source?.tableId,
    });

    if (mainTable && mainTable.isThirdParty && !external) {
      const metadata = JSON.parse(mainTable.databaseMetadata)[0];
      await getRecordExternalCollection(metadata, mainTable).then(
        (value: any) => {
          if (value) {
            if (externalCollections?.length > 0) {
              externalCollections.forEach((element: any) => {
                listExternals.push(element);
              });
            }

            listExternals.push({
              data: value,
              databaseUuid: item?.source?.source?.tableId,
            });
            store.dispatch(
              updateExternal({
                externalCollections: [...listExternals],
              })
            );
          }
        }
      );
    }
  }

  return item;
};

export const getSourceComponent = (
  attributes: any,
  defaultValues: any,
  objectBinding: any,
  externals?: any
) => {
  switch (attributes?.type) {
    case 'label':
      return attributes?.text || '';

    case 'toggle':
      return attributes?.value;

    case 'switch':
      return isUndefined(attributes.value) ? false : attributes?.value;

    case 'input':
      const external = find(externals?.externalCollections, {
        databaseUuid: attributes?.field?.tableId,
      });

      const externalIndex = externals?.indexExternal;

      const externalRecord = externals?.externalRecord;

      if (attributes?.field?.externalKey) {
        return (
          get(
            externalRecord,
            attributes?.field?.externalKey?.replace('[0].', '')
          ) ||
          get(
            external?.data,
            attributes?.field?.externalKey?.replace('[0]', `[${externalIndex}]`)
          ) ||
          ''
        );
      }

      // Related to CLICKNEWCANVAS-2103 and CLICKNEWCANVAS-2209
      return (
        get(defaultValues, attributes?.id, null) ||
        attributes?.defaultValue ||
        get(objectBinding, attributes?.id, null)
      );

    case 'password':
    case 'select':
      return attributes?.defaultValue || attributes?.text || '';

    case 'youtube':
      return attributes?.url;

    case 'vimeo':
      return attributes?.url;

    case 'date-picker':
      const defaultDateValue = attributes?.defaultDateValue;

      const inValidDate =
        ['currentTime', 'startOfToday'].includes(defaultDateValue) ||
        ['dateTime'].includes(defaultDateValue?.source?.type);

      return inValidDate ? null : defaultDateValue;

    case 'image-upload':
      return getValueImagePicker(attributes);

    default:
      return attributes?.text || '';
  }
};

const getSourceTab = async (tabData: any) => {
  return { ...tabData, label: await bindSourceHealthy(tabData?.label) };
};

const getSourceText = async (tabData: any) => {
  return { ...tabData, text: await bindSourceHealthy(tabData?.text) };
};

export const handleGetSourceLib = async (obj: any) => {
  const attributes = obj?.attributes;

  switch (obj.componentName) {
    case componentName.BUTTON:
      return {
        text: await bindSourceHealthy(attributes?.text),
      };

    case componentName.TAB_NAVIGATOR:
      return {
        tab0: await getSourceTab(attributes?.tab0),
        tab1: await getSourceTab(attributes?.tab1),
        tab2: await getSourceTab(attributes?.tab2),
        tab3: await getSourceTab(attributes?.tab3),
        tab4: await getSourceTab(attributes?.tab4),
      };

    case componentName.SIMPLE_LIST:
    case componentName.CARD_LIST:
      return {
        title: await getSourceText(attributes?.title),
        subTitle: await getSourceText(attributes?.subTitle),
        subTitle2: await getSourceText(attributes?.subTitle2),
      };

    default:
      return await {};
  }
};

export const roundValue = (value: number) => {
  if (!value) return 0;

  return round(value, 2);
};

export const getDefaultImage = (
  options: {
    placeholderImage: string;
    placeholderImageEnabled: boolean;
  },
  imageType: string | any
) => {
  if (options && options?.placeholderImageEnabled) {
    return { binding: options?.placeholderImage, imageType };
  }
  return { binding: null, imageType };
};

export const getMetadataScreen = (screen: IPage) => {
  return (
    screen.metadata?.length &&
    screen.metadata.map((item: Record<string, any>, index: number) => {
      item.zIndex = index + 1;
      if (item?.children) {
        item.children.map((child: Record<string, any>, i: number) => {
          child.zIndex = index + i + 2;
          return child;
        });
      }
      return item;
    })
  );
};

export const getPaddingBottomScreen = (screen: IPage, originY: number) => {
  const sortArrComp = sortBy(screen.metadata, 'y').reverse();
  const lastFixedObj = sortArrComp.find(
    (o) => o.fixPosition == 'top' || o.fixPosition == 'bottom'
  );

  const lastAbsoluteObj = sortArrComp.find(
    (o) => o.fixPosition == 'none' || !o.fixPosition
  );

  let paddingBottom = 10;
  if (lastAbsoluteObj && lastFixedObj) {
    if (lastFixedObj.y * originY > (screen.height * originY) / 2) {
      paddingBottom =
        screen.height -
        lastFixedObj.y -
        lastFixedObj.height +
        Math.abs(
          lastFixedObj.y -
            lastAbsoluteObj.y -
            lastAbsoluteObj.height +
            lastFixedObj.height
        );
    }
  }

  return paddingBottom;
};

export const getAttributesNormal = (screen: IPage) => {
  const arrComp = screen.metadata;

  return filter(arrComp, (o) => !o?.fixPosition || o.fixPosition == 'none');
};

export const getAttributesFixed = (screen: IPage) => {
  const arrComp = screen.metadata;

  return filter(
    arrComp,
    (o) => o.fixPosition == 'top' || o.fixPosition == 'bottom'
  );
};

export const getHeightStyle = (dimension: any) => {
  let heightStyles = { height: dimension.height };

  if (Platform.OS !== 'web') {
    heightStyles.height = dimension.height;
  }

  return heightStyles;
};

export const getCurrenPage = (pages: any[], query: any) => {
  return pages.find((o) => {
    return (
      o.screenId.toString() == get(query, 'target', null) ||
      o.screenUuid == query?.target
    );
  });
};

export const navigateDeleteUserLogin = () => {
  const state: any = store.getState();

  const appMetadataString: any = state.appInfo.app?.metadata;

  const appMetadataJson = appMetadataString
    ? JSON.parse(appMetadataString)
    : {};

  const search = history.location.search;
  const query = qs.parse(search);

  if (appMetadataJson?.startHomePage) {
    history.replace({
      search: qs.stringify({
        ...query,
        ...{
          target: appMetadataJson.startHomePage,
        },
      }),
    });
  }
};

export const formatValueNumber = (type: string, value: number) => {
  switch (type) {
    case 'default':
      return new Intl.NumberFormat('en-US').format(value);
    case 'currency-eng':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
    case 'currency-jp':
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
      }).format(value);
    case 'currency-eur':
      return (
        Number.parseFloat('' + value)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' €'
      );

    default:
      return value;
  }
};

export const formatValueDate = (type: string, value: Date) => {
  let locale: any;
  if (Platform.OS === 'web') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    locale = urlSearchParams.get('locale');
  } else {
    locale = 'ja';
  }

  const date = moment(value).locale(locale);

  if (!date.isValid()) return value;

  switch (type) {
    case DATE_FORMAT.RELATIVE:
      return date.fromNow();

    case DATE_FORMAT.RELATIVE_SHORT:
      moment.updateLocale('en', {
        relativeTime: {
          future: 'in %s',
          past: '%s ',
          ss: '%ds',
          s: '%ds',
          m: '%dm',
          mm: '%dm',
          h: '%dh',
          hh: '%dh',
          d: '%dd',
          dd: '%dd',
          w: '%dwk',
          ww: '%dwk',
          M: '%dmth',
          MM: '%dmth',
          y: '%dy',
          yy: '%dy',
        },
      });

      const shortRelativeTime = date.fromNow();

      moment.updateLocale('en', {
        relativeTime: {
          future: '%s from now',
          past: '%s ago',
          s: 'a few seconds',
          ss: '%d seconds',
          m: 'a minute',
          mm: '%d minutes',
          h: 'an hour',
          hh: '%d hours',
          d: 'a day',
          dd: '%d days',
          w: 'a week',
          ww: '%d weeks',
          M: 'a month',
          MM: '%d months',
          y: 'a year',
          yy: '%d years',
        },
      });

      return shortRelativeTime;

    case DATE_FORMAT.NONE_FORMAT:
      return date.format('YYYY/MM/DD h:mm A');

    default:
      return date.format(type);
  }
};

export const getSourceImage = (col: any) => {
  const imageType = col?.value?.imageType;

  switch (imageType) {
    case bindType.INTERNAL:
      return col?.value?.binding;

    case bindType.UPLOADED:
      return col?.value?.imageUploaded;

    default:
      return col?.value?.imageUrl;
  }
};

export const getSourceFile = (col: any) => {
  const fileType = col?.value?.fileType;

  switch (fileType) {
    case bindType.INTERNAL:
      return col?.value?.binding;

    default:
      return col?.value?.fileUrl;
  }
};

export const getSource = (col: any) => {
  if (!col) return null;

  switch (col?.type) {
    case rowType.FILE:
      return getSourceFile(col);

    case rowType.IMAGE:
      return getSourceImage(col);

    case rowType.BOOLEAN:
      return col.value.binding;

    default:
      return col?.value?.text || col?.value;
  }
};

export const getSourceRecord = (
  parentRecordList: any,
  source: any,
  record: any
) => {
  const hasParrentRecord = !isEmpty(parentRecordList);
  const srcTableId = get(source, 'source.tableId', undefined);
  const useRecordParrent =
    srcTableId === parentRecordList?.parentListDatabaseUuid;
  const currentParent = parentRecordList?.currentParent;

  switch (source?.source?.type) {
    case bindingType.DATA:
      return useRecordParrent ? currentParent : record;

    default:
      return hasParrentRecord && !useRecordParrent ? currentParent : record;
  }
};

export const getSourceTableUuid = (source: any) => {
  const sourceArray = flatSource(source);

  const lastSource = sourceArray[0];

  return lastSource?.tableId
    ? lastSource?.tableId
    : get(source, 'source.tableId', undefined);
};

export const handleCompareProfile = (record: IRecord) => {
  const state: any = store.getState();
  const appId = state.appInfo.app.id;

  return {
    ...record.record,
    databaseId: record.databaseId,
    userId: record?._id,
    appId,
    loggingWithPasswordTemporary: record.loggingWithPasswordTemporary,
    ...pick(record, ['createdAt', 'updatedAt']),
  };
};

export const getValueUpdateTableParams = (tables: string[]) => {
  const state: any = store.getState();
  const dataSource = state.database.dataSource;

  return tables.map((databaseId) => ({
    databaseId,
    total: get(dataSource, databaseId, []).length,
  }));
};

export const handleGetTableChanged = async (
  tables: string[],
  appId: string
) => {
  const time = new Date();
  const database = getValueUpdateTableParams(tables);

  return await api({
    method: 'get',
    url: `v2/check-update-database`,
    params: {
      appId: appId,
      database: JSON.stringify(database),
      time: moment(time).unix() - 5,
    },
  }).then((response) => {
    const data = response?.data || [];

    return data.map((item: { databaseId: string }) => item.databaseId);
  });
};

export const updateLoggedUserStore = (
  data: any,
  listTableChanged: string[]
) => {
  const dispatch = store.dispatch;
  const state: any = store.getState();
  const auth = state.auth.profile;
  const index = listTableChanged.findIndex(
    (item: any) => item == auth?.databaseId
  );
  const users = get(data, `${[index]}.data.data`, []);
  const newProfile = find(users, { _id: auth?.userId });

  if (index < 0 || isEmpty(newProfile?._id)) return;

  dispatch(
    updateLoggedUserSuccess({
      user: handleCompareProfile(newProfile),
    })
  );
};

export const getScreenThemecolor = (metadata: any[]) => {
  if (metadata.length < 0) return '#4259AC';
  const appBar = metadata.find((com: any) => com?.componentName === 'AppBar');
  const bottom = metadata.find(
    (com: any) => com?.componentName === 'TabNavigator'
  );
  const topColor = get(appBar, 'attributes.backgroundColor');
  const bottomColor = get(bottom, 'attributes.backgroundColor');

  return bottomColor || topColor;
};

export const getLocaleApp = () => {
  try {
    const search = !isEmpty(window) ? qs.parse(window?.location?.search) : {};
    const urlSearchParams: any =
      window?.location && new URLSearchParams(window?.location.search);
    const localeParams = get(search, 'locale', 'ja');

    if (isEmpty(urlSearchParams) || isEmpty(urlSearchParams?._searchParams))
      return localeParams;
    return (urlSearchParams && urlSearchParams.get('locale')) || 'ja';
  } catch (error) {
    console.log('error', error);
  }
};

export const getResetValue = (inputValues: any) => {
  const state: any = store.getState();
  const query = qs.parse(history.location.search);
  const { target } = query;

  if (!target || isEmpty(inputValues)) return inputValues;
  const screenMetadata = find(state.pageInfo.pages || [], {
    screenUuid: target,
  })?.metadata;

  const components = groupBy(
    screenMetadata ? JSON.parse(screenMetadata) : [],
    'id'
  );
  const objectIds = Object.keys(inputValues);

  let resp: any = {};
  objectIds.forEach((item: string) => {
    const defaultValue = get(components, `${item}[0].defaultValue`, null);
    const notEmptyDefaultValue = isArray(defaultValue)
      ? some(defaultValue, (item: any) => !isEmpty(item))
      : !isEmpty(defaultValue);
    if (!notEmptyDefaultValue) {
      resp[item] = inputValues[item];
    }
  });

  return resp;
};

const checkLocationBinding = (text: string | any[]) => {
  if (typeof text === 'string') return false;

  return some(text, (source) => source?.source?.type === 'currentLocation');
};

const checkShowCurrentLocation = (attrs: any) => {
  const markerType = get(attrs, 'attributes.markerType', 'simple');

  return get(
    attrs,
    `attributes.${
      markerType === 'simple' ? 'marker' : 'markers'
    }.currentLocation`,
    false
  );
};

export const getMapComponent = (screens: IPage[]) => {
  if (screens.length > 0) {
    const formatScreen = map(screens, (s) => ({
      ...s,
      attributes: JSON.parse(get(s, 'attributes', '{}')),
      metadata: JSON.parse(get(s, 'metadata')),
    }));

    return formatScreen.reduce((pre: any[], current: IPage) => {
      const mapComponent = current.metadata.reduce(
        (pre: any[], current: any) => {
          const isMap = current?.componentName === 'Map';

          const isShowCurrentLocation = isMap
            ? checkShowCurrentLocation(current)
            : false;

          return [...pre, ...(isMap && isShowCurrentLocation ? [current] : [])];
        },
        []
      );

      const labelComponent = filter(current.metadata, {
        componentName: 'label',
      });

      const labelBindingLocation = labelComponent.reduce(
        (lasValue, current) => {
          const isBindingLocation = checkLocationBinding(current?.text);
          return [...lasValue, ...(isBindingLocation ? [current] : [])];
        },
        []
      );

      return [
        ...pre,
        ...(!isEmpty(mapComponent) ? mapComponent : []),
        ...(!isEmpty(labelBindingLocation) ? labelBindingLocation : []),
      ];
    }, []);
  } else {
    return [];
  }
};

// function isValidURL(string: string) {
//   var res = string.match(
//     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
//   );
//   return res !== null;
// }

export function isValidImageURL(str: string) {
  if (typeof str !== 'string' || !str) return false;
  // return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);

  try {
    return Boolean(new URL(str));
  } catch (e) {
    return false;
  }
}
