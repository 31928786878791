import { bannerFullScreen } from '@common/constants/admob';
import { admobSelector } from '@common/redux/selectors/admob';
import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IRecord } from '@common/types/';
import { IList } from '@common/types/element';
import { get, isEmpty } from 'lodash';
import React, { FC, memo, useState } from 'react';
import { FlatList, Platform, Text, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import EmptyList from '../EmptyList';
import Item from './item';
import ListPlaceholder from './Placeholder';
import createStyle from './style';

const CusList: FC<IList> = (attrs) => {
  const {
    children,
    records,
    rowMargin,
    admobs,
    totalPage,
    onLoadMoreFilter,
    loadingFilter,
    total,
    initializeList,
  } = attrs;

  const styles = createStyle(attrs, records);

  if (initializeList) {
    return <ListPlaceholder attrs={attrs} initialize={true} />;
  }

  //User cannot select table
  if (isEmpty(attrs?.databaseUuid)) {
    return (
      <View style={styles.emptyListWrapper}>
        <EmptyList attributes={attrs} />
      </View>
    );
  }

  if (isEmpty(records)) return <View style={styles.emptyRecord} />;

  const { isUseAdmob } = useSelector(admobSelector);

  const reverseArr = get(attrs, 'dataBinding.source.options.reverse', false);
  const paginateArr = get(attrs, 'dataBinding.source.options.paginate', false);

  const [page, setPage] = useState(1);

  const itemPerPage = reverseArr ? records.reverse() : records;

  if (children.length == 0) return <React.Fragment />;

  const getAdmobComponent = (
    index: number,
    attrs: React.PropsWithChildren<IList>
  ) => {
    if (isUseAdmob && admobs?.length) {
      const admobListComponent = [];
      for (let adIndex = 0; adIndex < admobs.length; adIndex++) {
        if (Platform.OS === 'web' && !admobs[adIndex].placeholder) {
          break;
        }

        if (
          index !== undefined &&
          (index + 1) % admobs[adIndex].perItem === 0
        ) {
          let width: number = 0,
            height: number = 0;
          const isAdFullScreen = bannerFullScreen.includes(
            admobs[adIndex].bannerSize
          );
          if (!isAdFullScreen) {
            const columnCount = attrs.columnCount || 1;
            width = admobs[adIndex].width / columnCount;
            height = (admobs[adIndex].height / admobs[adIndex].width) * width;
          }

          admobListComponent.push(
            <ObjectRender
              keyItem={index}
              arrComp={[
                {
                  ...admobs[adIndex],
                  width,
                  height,
                  hidden: isAdFullScreen,
                },
              ]}
              isScreen={false}
            />
          );
        }
      }
      return (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: rowMargin,
          }}
        >
          {admobListComponent}
        </View>
      );
    }
    return null;
  };

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (totalPage > page) {
            setPage(page + 1);
            onLoadMoreFilter(page + 1);
          }
        }}
      >
        {attrs.loadingFilter ? (
          <ListPlaceholder attrs={attrs} initialize={false} />
        ) : (
          <Text style={styles.loadMoreText}>Load more</Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderItemList = ({ item, index }: any) => {
    return (
      <View>
        <Item key={index} item={item} attrs={attrs} index={index} />
        {getAdmobComponent(index, attrs)}
      </View>
    );
  };

  const keyExtractor = (item: IRecord) => item?._id;

  const isShowFooterFLatList = !reverseArr && paginateArr && totalPage > page;

  const renderList = () => {
    return (
      <View style={styles.cusListWrapper}>
        {itemPerPage?.length ? (
          <FlatList
            {...(isShowFooterFLatList && {
              ListFooterComponent: <ListFooterComponent />,
            })}
            numColumns={attrs.columnCount}
            key={attrs.columnCount}
            showsHorizontalScrollIndicator={attrs.isWeb ? true : false}
            data={itemPerPage}
            showsVerticalScrollIndicator={true}
            keyExtractor={keyExtractor}
            renderItem={renderItemList}
            {...(!paginateArr && {
              onEndReachedThreshold: 0.1,
              scrollEventThrottle: 16,
              onEndReached: () => {
                if (loadingFilter && itemPerPage.length < 10) return;

                if (itemPerPage.length === total) {
                  setPage(1);
                  return;
                }

                setPage(page + 1);
                onLoadMoreFilter(page + 1);
              },
            })}
            // Performance settings
            // removeClippedSubviews={true} // Unmount components when outside of window
            // initialNumToRender={10} // Reduce initial render amount
            // maxToRenderPerBatch={1} // Reduce number in each render batch
            // updateCellsBatchingPeriod={100} // Increase time between renders
            // windowSize={7} // Reduce the window size
          />
        ) : null}
      </View>
    );
  };

  const isShowFooter = reverseArr && paginateArr && totalPage > page;

  return (
    <>
      {isShowFooter && <ListFooterComponent />}
      {renderList()}
    </>
  );
};

export default memo(CusList);
