import { isEmpty } from 'lodash';

import { ActionResponse } from '../excuteAction';
import store from '@common/redux/store';
import { IAction } from '@common/types/';
import api from '@common/configs/api';
import { setError } from '@common/redux/slice/formInput';
import history from '@common/routes/history';

const pushNotfication: (
  action: IAction,
  metadata: any,
  locale?: string
) => Promise<ActionResponse> = async (
  action: IAction,
  metadata: any,
  locale?: string
) => {
  const dispatch = store.dispatch;
  const state = store.getState();

  const { optionsNofitication } = metadata;

  if (isEmpty(optionsNofitication.title)) {
    dispatch(
      setError({
        message: 'Push notification failed !',
        isError: true,
      })
    );

    return {
      status: 'FAILED',
    };
  }

  if (optionsNofitication.title.length > 65) {
    dispatch(
      setError({
        message: 'Title must less than 65 characters !',
        isError: true,
      })
    );

    return {
      status: 'FAILED',
    };
  }

  if (optionsNofitication.body.length > 240) {
    dispatch(
      setError({
        message: `Body must less than 240 characters !`,
        isError: true,
      })
    );

    return {
      status: 'FAILED',
    };
  }

  const appId = history.location.pathname.split('/')[2];

  const payload = {
    title: optionsNofitication.title,
    body: optionsNofitication.body,
    filter: {
      type: optionsNofitication?.listUser || 'all',
      specificUser: '',
      ...(optionsNofitication?.filter &&
        optionsNofitication?.listUser === 'specific' && {
          ...optionsNofitication?.filter,
        }),
    },
    os: 'all',
    schedule: {
      type: 'immediately',
    },
    page: optionsNofitication?.openPage || null,
    isUpdateApp: false,
    isAction: true,
    appId: optionsNofitication?.sendApp ? optionsNofitication?.sendApp : appId,
    databaseUuid: state.database.database[0].databaseUuid,
  };

  try {
    await api({
      method: 'post',
      url: '/v2/push-notifications',
      params: {
        appId: optionsNofitication?.sendApp
          ? optionsNofitication?.sendApp
          : appId,
      },
      data: payload,
    });

    return {
      status: 'SUCCESS',
    };
  } catch (error) {
    dispatch(
      setError({
        message: 'Push notification failed !',
        isError: true,
      })
    );

    return {
      status: 'FAILED',
    };
  }
};

export default pushNotfication;
