import { get, filter, isEmpty } from 'lodash';
import { defaultURL } from './common.constant';

export const imageUrl = (image: any, item: any, key?: any) => {
  const isAvtImgae = key ? 'avatarImage' : 'image';
  const imageType = get(image, `image.imageType`);

  const placeholderImageUrl = get(
    image,
    'image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    image,
    'image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const imageCanvas =
    typeof image?.imageUrl === 'string'
      ? image?.imageUrl || placeholderImage
      : Array.isArray(image?.imageUrl)
      ? image?.imageUrl[0] || placeholderImage
      : placeholderImage;

  const binding = get(item, `${isAvtImgae}.binding`);

  const isObj = filter(image?.imageUrl, (item) => typeof item === 'object');

  if (imageType === 'internal') {
    return binding || placeholderImage || defaultURL;
  }

  if (imageType === 'url' && !isEmpty(isObj)) {
    if (binding) {
      return binding || placeholderImage || defaultURL;
    } else {
      return isAvtImgae === 'avatarImage'
        ? item?.avatarImage || placeholderImage || defaultURL
        : item?.image || placeholderImage || defaultURL;
    }
  }
  return imageCanvas;
};
export const getPlaceholder = (image: any) => {
  const imageType = get(image, `image.imageType`);

  const placeholderImageUrl = get(
    image,
    'image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    image,
    'image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  return placeholderImage;
};
