import { Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { IAction } from '@common/types/';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import RNLine from '@nocode/components/LineLogin/lineModal';
import history from '@common/routes/history';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import getLineToken from '@common/hooks/useGetLineToken';
import { ActionTypes } from '@common/types';

export const lineLogin: (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => {
  const socialConfig = action.options;
  if (Platform.OS === 'web') {
    const queryParams = new URLSearchParams(location.search);
    if (navigator.userAgent.indexOf('Line/') === -1) {
      let textErr = 'Please open in Line browser!';
      if (queryParams.has('locale') && queryParams.get('locale') === 'ja') {
        textErr = 'Lineブラウザーで開いてください！';
      }
      alert(textErr);
      return {
        status: 'FAILED',
      };
    }
    let currentUrl = location.pathname;

    AsyncStorage.setItem(
      'clickFlows',
      JSON.stringify({
        currentRecord,
        actionComponent,
      })
    );
    const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${socialConfig.clientId}&redirect_uri=${socialConfig.redirectUri}&state=${currentUrl}&scope=${socialConfig.scope}`;
    await Linking.openURL(url);

    return {
      status: 'SUCCEED',
    };
  }
  const appId = history.location.pathname.split('/')[2];
  const res: any = await new Promise((resolve, reject) => {
    RNLine.login(socialConfig, (codeAuth: any) => {
      getLineToken(codeAuth, appId)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
  return { status: res?.status, message: res?.message };
};

export default lineLogin;
