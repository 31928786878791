import { IAction } from '@common/types/';
import { Linking, NativeModules, Platform } from 'react-native';
import Config from 'react-native-config';
import { ActionResponse } from '../excuteAction';
import {
  getVersionAppStore,
  getVersionPlayStore,
  isUpdateVersion,
} from '../func/func';

const executeCheckVersion: (
  action: IAction
) => Promise<ActionResponse> = async (action: IAction) => {
  try {
    switch (Platform.OS) {
      case 'android': {
        if (action.options.bundleId) {
          const lastestVersion = await getVersionPlayStore(
            action.options.bundleId
          );

          const isUpdate =
            lastestVersion === 'Varies with device' ||
            isUpdateVersion(Config.APP_VERSION, lastestVersion);

          if (isUpdate) {
            const urlPlayStore = `market://details?id=${action.options.bundleId}`;
            const isSupported = await Linking.canOpenURL(urlPlayStore);

            if (isSupported) {
              await Linking.openURL(urlPlayStore);
            }
          }

          return {
            status: 'SUCCEED',
          };
        } else {
          return {
            status: 'FAILED',
          };
        }
      }

      case 'ios': {
        if (action.options.appId) {
          const locale =
            NativeModules?.SettingsManager?.settings?.AppleLocale ||
            NativeModules?.SettingsManager?.settings?.AppleLanguages?.[0];

          const localeArr = typeof locale === 'string' ? locale.split('_') : [];

          const country = localeArr.length
            ? localeArr[localeArr.length - 1].toLowerCase()
            : null;

          const lastestVersion = await getVersionAppStore(
            action.options.appId,
            country
          );

          const isUpdate = isUpdateVersion(Config.APP_VERSION, lastestVersion);

          if (isUpdate) {
            const urlAppStore = `itms-apps://itunes.apple.com/${
              country ? country : `jp`
            }/app/id${action.options.appId}?mt=8`;

            const isSupported = await Linking.canOpenURL(urlAppStore);

            if (isSupported) {
              await Linking.openURL(urlAppStore);
            }
          }

          return {
            status: 'SUCCEED',
          };
        } else {
          return {
            status: 'FAILED',
          };
        }
      }

      default:
        window.alert(
          'Check update app currently only supported on native apps (Android, IOS)'
        );
        return {
          status: 'SUCCEED',
        };
    }
  } catch (err) {
    console.log('err', err);

    return {
      status: 'FAILED',
    };
  }
};

export default executeCheckVersion;
