import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'width',
        'opacity',
        // 'height', // FIXME: when fix position multiple form in pc
      ]),
      zIndex: attributes.zIndex,
    },
    wrapper: {},
    img: {
      ...pick(attributes, ['width', 'height', 'borderRadius', 'opacity']),
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
    },
  });
};

export default createStyles;
