import CusLabel from '@common/components/Label';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import BindingComponent from '@common/screens/RenderScreen/BindingComponent';
import { Field } from '@common/types';
import React, { FC, useRef, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { isAnswerByRadio } from '../util';

type IProps = {
  radioProps: any;
  answerProps: any;
  field: Field;
};

const Radio: FC<IProps> = ({ field, radioProps, answerProps }) => {
  const { valueInputs, changeInput }: UseValueInputProps =
    useValueInputs(radioProps);

  const answerRef = useRef<number>();

  const handlePress = (index: number) => {
    const answerString = field?.items?.[index].trim() || '';
    answerRef.current = index;
    changeInput(answerString);
  };

  useEffect(() => {
    if (valueInputs === '') {
      answerRef.current = undefined;
    }
  }, [valueInputs]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {field?.items?.map((item, index) => (
        <View
          style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}
          key={index}
        >
          <TouchableOpacity
            onPress={() => handlePress(index)} //{() => changeValue(id, !value)}
            activeOpacity={0.4}
            hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
            style={{ width: 24 }}
          >
            <Icon
              size={24}
              name={
                isAnswerByRadio(index, answerRef.current)
                  ? 'radio-button-on'
                  : 'radio-button-off'
              }
              color={
                isAnswerByRadio(index, answerRef.current)
                  ? radioProps.activeColor
                  : radioProps.inactiveColor
              }
            />
          </TouchableOpacity>
          {!!item && (
            <View
              style={{
                marginLeft: 5,
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <BindingComponent
                ObjectClass={CusLabel as FC}
                obj={{
                  ...answerProps,
                  text: item,
                  multiline: true,
                  width: '100%',
                }}
              />
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

export default Radio;
