export default {
  CAPTURE_ONLY_FOR_CARD_PAYMENT: 'カード決済の場合のみキャプチャが可能。',
  CAPTURE_ONLY_FOR_CREDIT_CARDS:
    'オーソリはカードの種別がクレジットカードのみ可能です。デビットカード、プリペイドカード等ではオーソリは利用できません。',
  CARD_LIMIT_EXCEEDED_FOR_STORE:
    '一定期間内でのカードの合計決済額が限度額を超えました。',
  CHANGE_PROHIBITED: 'このフィールドの変更は禁止されています。',
  CHARGE_AMOUNT_TOO_HIGH: '課金金額が課金最大額より超過しています。',
  CHARGE_AMOUNT_TOO_LOW: '課金金額が課金最少額より不足しています。',
  DEPRECATED: '非推奨のパラメータです。',
  EXPIRATION_DATE_OUT_OF_BOUNDS: '有効期限が許可された範囲外です。',
  FORBIDDEN_PARAMETER:
    'このパラメータを使用する権限が不足しているか、要件を満たしていません。',
  INSTALLMENT_ALREADY_SET: '定期課金が既に開始しているため変更できません。',
  INSTALLMENT_INVALID_PLAN_TYPE: '認識できない分割払いプランです。',
  INVALID_AMOUNT: '金額は 0 より大きい整数である必要があります。',
  INVALID_CARD_BRAND: '認識できないカードブランドです。',
  INVALID_CARD_DESCRIPTOR: 'ディスクリプタの形式が正しくありません。',
  INVALID_CARD_EXPIRATION: 'カード有効期限が過ぎています。',
  REFUND_NOT_WITHIN_BOUNDS:
    '返金金額が有効な範囲ではありません。0より大きく、課金金額以下の必要があります。',
  INVALID_CARD_NUMBER: 'カード番号の形式が正しくありません。',
  INVALID_CHARGE_STATUS: '不正な課金の状態です。',
  INVALID_CURRENCY: '認識できない通貨です。',
  INVALID_CVV: 'CVVは 3から5桁の整数である必要があります。',
  INVALID_FORMAT: '不正な形式です。cardholder',
  INVALID_FORMAT_COUNTRY: '認識できない国コードです。',
  INVALID_FORMAT_CURRENCY: '認識できない通貨コードです。',
  INVALID_FORMAT_DOMAIN: 'ドメイン名の形式が不正です。',
  INVALID_FORMAT_EMAIL: 'メールアドレスの形式が不正です。',
  INVALID_LANGUAGE: '認識できない言語コードです。',
  INVALID_PHONE_NUMBER: '電話番号の形式が不正です。',
  INVALID_SCHEDULED_CAPTURE_DATE:
    'キャプチャ実行日時は、現在から1時間後かつ7日以内である必要があります。',
  INVALID_TIME_PERIOD: '認識できない期間の形式です。',
  MUST_BE_FUTURE_TIME: '未来の日時の必要があります。',
  MUST_BE_LOWER_THAN_FULL_AMOUNT: '合計金額より少ない必要があります。',
  MUST_BE_MONTH_BASED_TO_SET: '課金を行う頻度が monthly である必要があります。',
  NESTED_JSON_NOT_ALLOWED: 'ネストされた JSON の形式は使用できません。',
  NOT_SUPPORTED_BY_PROCESSOR: 'ゲートウェイでサポートされていません。',
  ONLY_FOR_CARD_PAYMENT: '支払い方法がカードでのみ利用できます。',
  ONLY_JAPANESE_PHONE_NUMBER_ALLOWED:
    '日本の電話番号のみサポートされています。',
  REQUIRED_VALUE: '必須のパラメータです。',
  INVALID_FORMAT_LENGTH: 'Cardholderは 2から30桁の整数である必要があります。',
  NO_TEST_CARD_IN_LIVE_MODE: 'No test card in live mode',
};
