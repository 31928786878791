import React, { FC } from 'react';
import { IImage } from '@common/types/element';
import { Image as RNImage, TouchableOpacity, Platform } from 'react-native';
// import ContentLoader, { Rect } from 'react-content-loader/native';
import useImage from '@common/hooks/useImage';
import createStyles from './style';
import { get, isEmpty, isUndefined, pick } from 'lodash';
import { defaultURL } from '@common/constants/shared';

const CusImage: FC<IImage> = (attrs) => {
  const { url } = useImage(attrs);

  const hasPlaceholder = get(attrs, 'imageUrl.binding.options', {});
  const isUrl =
    !url && hasPlaceholder.placeholderImageEnabled
      ? hasPlaceholder?.placeholderImage
      : isEmpty(hasPlaceholder) && !url
      ? ''
      : url;

  const styles = createStyles(attrs, Platform, isEmpty(isUrl));
  return (
    <TouchableOpacity
      disabled={isEmpty(attrs.actions)}
      activeOpacity={1}
      style={styles.container}
      {...(attrs.actions && {
        onPress: () => {
          attrs.onPress ? attrs.onPress() : {};
        },
      })}
    >
      {/* {hasPlaceholder?.placeholderImageEnabled ? null : (
        <ContentLoader
          speed={3}
          viewBox={`0 0 ${attrs.width} ${attrs.height}`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <Rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      )} */}
      {/* <LazyLoadComponent> */}
      {isEmpty(isUrl) &&
      !hasPlaceholder?.placeholderImageEnabled ? null : Platform.OS !==
        'web' ? (
        <RNImage
          style={styles.img}
          resizeMode={attrs?.imageResize === 'contain' ? 'contain' : 'cover'}
          source={{ uri: isUndefined(isUrl) ? defaultURL : isUrl }}
          defaultSource={
            hasPlaceholder?.placeholderImageEnabled
              ? hasPlaceholder?.placeholderImage
                ? hasPlaceholder?.placeholderImage
                : defaultURL
              : ''
          }
        />
      ) : (
        <img
          src={isUndefined(isUrl) ? defaultURL : isUrl}
          style={{
            ...pick(attrs, ['borderRadius', 'opacity']),
            objectFit: attrs?.imageResize === 'contain' ? 'contain' : 'cover',
            width: '100%',
            height: '100%',
          }}
        ></img>
      )}
      {/* </LazyLoadComponent> */}
    </TouchableOpacity>
  );
};

export default React.memo(CusImage);
