import React from 'react';
import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import styles from './style';

type Props = {
  size?: 'small' | 'large' | number;
};

const Loading = (props: Props) => (
  <View style={[styles.container, styles.horizontal]}>
    <ActivityIndicator size="large" />
  </View>
);

export default Loading;
