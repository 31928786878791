import { getCurrentLocation } from '@common/redux/selectors/database';
import { ILibraryComponent } from '@common/types/element';
import { handleGetSourceLib } from '@common/utils/functions';
import { getTextBinding } from '@common/utils/handleBinding';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CusLibrary from './CusLibrary';

const CusLibraryComponent: FC<ILibraryComponent> = (attributes) => {
  const [attrs, setAttrs] = useState<any>();
  const getFieldValue = getTextBinding;

  const currentLocation = useSelector(getCurrentLocation) || undefined;

  useEffect((): any => {
    let isRender = true;
    async function getSource(attributes: any) {
      let value = await handleGetSourceLib(attributes);
      if (isRender) {
        setAttrs({
          ...attributes,
          getFieldValue,
          attributes: { ...attributes.attributes, ...value },
          currentLocation,
        });
      }
    }
    getSource(attributes);

    return () => (isRender = false);
  }, [attributes]);

  if (attrs) {
    return <CusLibrary {...attrs} />;
  } else {
    return <Fragment></Fragment>;
  }
};

export default CusLibraryComponent;
