export const stripeMessage = ({
  locale,
  type,
  message = '',
}: {
  locale: string;
  type: any;
  message?: string;
}) => {
  const checkLocale = (params: string[]) => {
    return locale === 'en' ? params[0] : params[1];
  };

  switch (type) {
    case 'secret_key':
      return checkLocale([
        'Not set secretKey',
        'シークレットキーが設定させていません',
      ]);

    case 'publishable_key':
      return checkLocale([
        'Not set publishableKey',
        '本番キーが設定されていません',
      ]);

    case 'success':
      return checkLocale(['Payment succeeded!', '支払いが成功しました']);

    case 'processing':
      return checkLocale(['Your payment is processing.', '支払い手続き中です']);

    case 'requires_payment_method':
      return checkLocale([
        'Your payment was not successful, please try again.',
        '支払いが失敗しました。再度お試しください。',
      ]);

    case 'error_occured':
      return checkLocale([
        'An unexpected error occured.',
        'エラーが発生しました',
      ]);

    case 'missing_id':
      return checkLocale([
        'Missing required param: ID.',
        '「商品情報」を入力してください',
      ]);

    case 'error_card':
      return checkLocale(['Something went wrong.', 'エラーが発生しました']);

    case 'invalid_number':
      return checkLocale([message, 'カード番号が無効です']);

    case 'incomplete_number':
      return checkLocale([message, 'カード番号が間違っています']);

    case 'invalid_expiry_year_past':
    case 'invalid_expiry_year':
      return checkLocale([message, 'カードの有効期限が切れています']);

    case 'invalid_expiry_month':
    case 'incomplete_expiry':
      return checkLocale([message, 'カードの期限が無効です']);

    case 'invalid_cvc':
    case 'incomplete_cvc':
      return checkLocale([message, 'セキュリティコードが無効です']);

    case 'card_complte':
      return checkLocale([
        'Your card number is incomplete.',
        'カード番号が間違っています',
      ]);

    case 'expiration_date_incomplete':
      return checkLocale([
        "Your card's expiration date is incomplete.",
        'カードの期限が無効です.',
      ]);

    case 'expiration_year':
      return checkLocale([
        "Your card's expiration year is in the past.",
        'カードの有効期限が切れています',
      ]);

    case "card's_security_incomplete":
      return checkLocale([
        "Your card's security code is incomplete.",
        'セキュリティコードが無効です',
      ]);

    case 'publishable_key_invalid':
      return checkLocale(['Publishable Key is invalid', '本番キーが無効です']);

    case 'stripe_configured':
      return checkLocale([
        'Stripe has not been configured !',
        'Stripeが設定されていません !',
      ]);

    case 'incomplete_expired':
      return checkLocale(['incomplete_expired', '支払い期限が切れています.']);

    case 'card_number_incorrect':
      return checkLocale([
        'Your card number is incorrect.',
        'カード番号が無効です',
      ]);

    case 'invalid_email_address':
      return checkLocale([
        'Invalid email address',
        '有効なメールアドレスではありません',
      ]);

    case 'amount_least_¥50':
      return checkLocale([
        'Amount must be at least ¥50 jpy',
        '金額は５０円以上で入力してください',
      ]);

    case 'missing_amount':
      return checkLocale([
        'Missing required param: amount.',
        '「支払い金額」を入力してください',
      ]);

    case 'card_declined':
      return checkLocale(['Your card was declined.', 'カードが拒否されました']);

    case 'value_greater':
      return checkLocale([
        'This value must be greater than or equal to 1.',
        '値は１以上で入力してください',
      ]);

    case 'invalid_api':
      return checkLocale(['Invalid API Key provided', 'APIキーが無効です']);

    case 'such_price':
      return checkLocale(['No such price', '商品が存在しません']);

    case 'MM':
      return checkLocale(['MM', '月']);

    case 'YY':
      return checkLocale(['YY', '年']);

    case 'card_number':
      return checkLocale(['Card number', 'カード番号']);

    case 'secret_key_invalid':
      return checkLocale([
        'Secret Key is invalid',
        'シークレットキーが無効です',
      ]);

    case 'max_number':
      return checkLocale([
        'You have exceeded the maximum number of declines on this card in the last 24 hour period.Please contact us via https://support.stripe.com/contact',
        '過去24時間以内に、このカードの上限を超えています、https://support.stripe.com/contact までご連絡ください。',
      ]);

    //TODO: Backend update object error
    case `${type.split(':')[0] + ':' + type.split(':')[1]}`:
      return checkLocale([
        type,
        `「支払い金額」を入力してください:${type.split(':')[1]}`,
      ]);
    default:
      return type;
  }
};
