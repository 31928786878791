import api from '@common/configs/api';
import { appInfoSelector } from '@common/redux/selectors/app';
import { authSelector } from '@common/redux/selectors/auth';
import {
  databaseSelector,
  getCurrentRecord,
  getDataSourceStore,
  reFreshStatusSelector,
} from '@common/redux/selectors/database';
import { updateLoggedUserSuccess } from '@common/redux/slice/auth';
import {
  setCurrentRecord,
  setReloadStatus,
  updateDataSource,
} from '@common/redux/slice/database';
import { IApp, ICollection, IRecord } from '@common/types/';
import { getRecordById } from '@common/utils/database';
import { convertEncrypt } from '@common/utils/encryptData';
import { handleCompareProfile } from '@common/utils/functions';
import axios from 'axios';
import { get, map, reduce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type TableChangeProps = {
  databaseId: string;
  total: number;
};

type MetadataProps = { appId: string; currentRecord: any; profile: any };

const useDatabaseListener = () => {
  const dispatch = useDispatch();
  const dataSource = useSelector(getDataSourceStore);
  const appInfo: IApp | undefined = useSelector(appInfoSelector);
  const currentRecord = useSelector(getCurrentRecord);
  const { profile } = useSelector(authSelector);
  const isRefresh = useSelector(reFreshStatusSelector);
  const { database } = useSelector(databaseSelector);

  const databases = useMemo(() => {
    return reduce(
      database,
      (prev: any, current: ICollection) => {
        const isExternalDb = current.isThirdParty;
        return [
          ...prev,
          ...(!isExternalDb
            ? [
                {
                  databaseId: current.databaseUuid,
                  total: get(dataSource, current.databaseUuid, []).length,
                },
              ]
            : []),
        ];
      },
      []
    );
  }, [database, dataSource]);

  const handleUpdateRedux = async (
    listTableChanged: string[],
    metadata: MetadataProps
  ) => {
    const fetchDataPromise = map(listTableChanged, (id: string) =>
      api({
        method: 'get',
        url: `v2/database/${id}/records`,
        params: {
          appId: metadata.appId,
        },
      })
    );

    const data: Record<string, any> = await axios
      .all(fetchDataPromise)
      .then((responseApi: any) => convertEncrypt(responseApi))
      .then((resp) => resp.map((item) => item?.data.data || []));

    let updatedDataSource: Record<string, any> = {};
    for (let i = 0; i < listTableChanged.length; i++) {
      const tableId: string = listTableChanged[i];
      const records = data[i];

      //update profile
      if (profile && profile?.databaseId == tableId) {
        const newProfile: IRecord | any = getRecordById(
          records,
          profile?.userId
        );
        if (!newProfile?._id) return;
        dispatch(
          updateLoggedUserSuccess({
            user: handleCompareProfile(newProfile),
          })
        );
      }

      //update currentRecord
      // let record = (currentRecord && currentRecord[tableId]) || {};
      // let recordId = record && record._id;
      // const newCurrentRecord: IRecord | any = getRecordById(records, recordId);
      // newCurrentRecord?._id && dispatch(setCurrentRecord(newCurrentRecord));

      updatedDataSource[tableId] = records || [];
    }
    dispatch(updateDataSource(updatedDataSource));
  };

  const handleCheckDatabaseChange = useCallback(
    (databases: TableChangeProps[]) => {
      if (!appInfo?.id) return;

      const metadata = { appId: appInfo.id, currentRecord, profile };
      const time = new Date();
      return api({
        method: 'get',
        url: `v2/check-update-database`,
        params: {
          appId: appInfo.id,
          database: JSON.stringify(databases),
          time: moment(time).unix() - 20,
        },
      })
        .then((response) => {
          const data = response?.data || [];
          return data.map((item: { databaseId: string }) => item.databaseId);
        })
        .then((listTableChanged) => {
          if (listTableChanged.length > 0) {
            handleUpdateRedux(listTableChanged, metadata);
          }
        })
        .finally(() => {
          dispatch(setReloadStatus(false));
        });
    },
    [currentRecord, profile, appInfo]
  );

  useEffect(() => {
    if (isRefresh) {
      handleCheckDatabaseChange(databases);
    }
  }, [isRefresh]);
};

export default useDatabaseListener;
