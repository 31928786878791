import React from 'react';
import { StyleSheet, Platform, View } from 'react-native';

type Props = {
  attributes: Record<string, string>;
  height: number;
};

type BorderStyle = 'solid' | 'dotted' | 'dashed';

const Line: React.FC<Props> = ({ attributes, height }) => {
  const { borderStyle, backgroundColor } = attributes;
  const styles = StyleSheet.create({
    container: {
      width: attributes.borderStyle !== 'none' ? attributes.width : 0,
      height,
      color: backgroundColor,
      ...(attributes.borderStyle !== 'none'
        ? {
            borderStyle: borderStyle as BorderStyle,
            borderColor: backgroundColor,
          }
        : { borderWidth: 0 }),
      backgroundColor: 'transparent',
      ...Platform.select({
        ios: {
          borderWidth: height / 2,
          borderRadius: 1,
          marginTop: -height / 2,
          marginLeft: -height / 2,
          marginRight: -height / 2,
        },
        android: {
          borderWidth: height / 2,
          borderRadius: 1,
          marginTop: -height / 2,
          marginLeft: -height / 2,
          marginRight: -height / 2,
        },
        default: {
          borderTopWidth: height,
          // other platforms, web for example
        },
      }),
    },
  });

  return (
    <View style={{ height: height, overflow: 'hidden' }}>
      <View style={styles.container}></View>
    </View>
  );
};

export default Line;
