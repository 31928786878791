import FacebookIcon from '@common/assets/icons/facebook-icon.png';
import TwitterIcon from '@common/assets/icons/twitter-icon.png';
import EmailIcon from '@common/assets/icons/email-icon.jpg';
import PinterestIcon from '@common/assets/icons/pinterest-icon.png';
import LineIcon from '@common/assets/icons/line-icon.png';

export type AppShare = {
  icon: string;
  name: string;
  getLink: () => string;
};

export const getPostUrl = () => {
  return `${window.location.href}`;
};

export const APPS_SHARE: AppShare[] = [
  {
    icon: FacebookIcon,
    name: 'Facebook',
    getLink: () =>
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        getPostUrl()
      )}%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore`,
  },
  {
    icon: TwitterIcon,
    name: 'Twitter',
    getLink: () =>
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        getPostUrl()
      )}`,
  },
  {
    icon: EmailIcon,
    name: 'Email',
    getLink: () => `mailto:?body=${encodeURIComponent(getPostUrl())}`,
  },

  {
    icon: LineIcon,
    name: 'Line',
    getLink: () =>
      `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
        getPostUrl()
      )}`,
  },
  {
    icon: PinterestIcon,
    name: 'Pinterest',
    getLink: () =>
      `https://www.pinterest.com/pin-builder/?url=${encodeURIComponent(
        getPostUrl()
      )}&method=button`,
  },
];
