import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    size: {
      width: attributes.width,
      height: attributes.height,
      zIndex: attributes.zIndex,
    },
  });
};

export default createStyles;
