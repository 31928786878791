import { StyleSheet } from 'react-native';

const createStyles = (styleData: Record<string, any>) => {
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      height: 40,
      width: styleData.width,
      borderRadius: styleData.borderRadius,
      backgroundColor:
        styleData.type === 'contained' ? styleData.backgroundColor : '',
      opacity: styleData.opacity,
      display: 'flex',
      justifyContent: 'center',
    },
    image: {
      width: 40,
      height: 40,
    },
    text: {
      fontSize: 16,
      lineHeight: 40,
      color: styleData.type === 'contained' ? styleData.color : '#000000',
      fontFamily: styleData.styles?.fontFamily,
      fontWeight: styleData.styles?.fontWeight,
      opacity: styleData.opacity,
      marginLeft: 3,
    },
    errorContainer: {
      flexDirection: 'row',
      borderRadius: styleData.borderRadius,
      opacity: styleData.opacity,
      lineHeight: 40,
      textAlign: 'center',
      borderColor: '#000000',
      borderStyle: 'solid',
      borderWidth: 2,
    },
    errorText: {
      padding: 5,
      margin: 'auto',
      fontSize: 16,
      color: '#ff0000',
      fontFamily: styleData.styles?.fontFamily,
      fontWeight: styleData.styles?.fontWeight,
    },
  });
};

export default createStyles;
