import moment from 'moment';
const offset = 100;

function buildEvent(
  column: {
    start: moment.MomentInput;
    end: moment.MomentInput;
    top: number;
    height: number;
    width: any;
    left: any;
  },
  left: number,
  width: number,
  dayStart: number
) {
  const startTime = moment(column.start);
  const endTime = column.end
    ? moment(column.end)
    : startTime.clone().add(1, 'hour');
  const dayStartTime = startTime.clone().hour(dayStart).minute(0);
  const diffHours = startTime.diff(dayStartTime, 'hours', true);

  column.top = diffHours * offset;
  column.height = endTime.diff(startTime, 'hours', true) * offset;
  column.width = width;
  column.left = left;
  return column;
}

function collision(
  a: { end: number; start: number },
  b: { start: number; end: number }
) {
  return a.end > b.start && a.start < b.end;
}

function expand(
  ev: { end: number; start: number },
  column: number,
  columns: string | any[]
) {
  var colSpan = 1;

  for (var i = column + 1; i < columns.length; i++) {
    var col = columns[i];
    for (var j = 0; j < col.length; j++) {
      var ev1 = col[j];
      if (collision(ev, ev1)) {
        return colSpan;
      }
    }
    colSpan++;
  }

  return colSpan;
}

function pack(
  columns: string | any[],
  width: number,
  calculatedEvents: any[],
  dayStart: number
) {
  var colLength = columns.length;

  for (var i = 0; i < colLength; i++) {
    var col = columns[i];
    for (var j = 0; j < col.length; j++) {
      var colSpan = expand(col[j], i, columns);
      var L = (i / colLength) * width;
      var W = (width * colSpan) / colLength - 10;

      calculatedEvents.push(buildEvent(col[j], L, W, dayStart));
    }
  }
}

export function populateEvents(
  events: any,
  screenWidth: number,
  dayStart: any
) {
  let lastEnd: number | null;
  let columns: any[];
  let calculatedEvents: never[] = [];

  events =
    events?.length > 0 &&
    events
      .map((ev: any, index: any) => ({ ...ev, index: index }))
      .sort(function (
        a: { start: number; end: number },
        b: { start: number; end: number }
      ) {
        if (a.start < b.start) return -1;
        if (a.start > b.start) return 1;
        if (a.end < b.end) return -1;
        if (a.end > b.end) return 1;
        return 0;
      });

  columns = [];
  lastEnd = null;

  events?.length > 0 &&
    events.forEach(function (ev: { start: any; end: any }, index: any) {
      if (lastEnd !== null && ev.start >= lastEnd) {
        pack(columns, screenWidth, calculatedEvents, dayStart);
        columns = [];
        lastEnd = null;
      }

      var placed = false;
      for (var i = 0; i < columns.length; i++) {
        var col = columns[i];
        if (!collision(col[col.length - 1], ev)) {
          col.push(ev);
          placed = true;
          break;
        }
      }

      if (!placed) {
        columns.push([ev]);
      }

      if (lastEnd === null || ev.end > lastEnd) {
        lastEnd = ev.end;
      }
    });

  if (columns.length > 0) {
    pack(columns, screenWidth, calculatedEvents, dayStart);
  }
  return calculatedEvents;
}
