import { signOut } from '@common/redux/slice/auth';
import { resetAllValue } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { IAction } from '@common/types/';
import { ActionResponse } from '../excuteAction';
import { unregisterFCMUser } from './FCMregister';

const excuteLogout: (action: IAction) => Promise<ActionResponse> = async (
  action: IAction
) => {
  const dispatch = store.dispatch;

  try {
    const state: any = store.getState();

    await unregisterFCMUser();

    dispatch(signOut({}));
    dispatch(resetAllValue());

    return {
      status: 'SUCCEED',
      message: 'Logout successful !',
    };
  } catch (e) {
    return {
      status: 'FAILED',
      message: 'Logout failed !',
    };
  }
};

export default excuteLogout;
