import { setCurrentRerordExternal } from '@common/redux/slice/externalCollection';
import { setError } from '@common/redux/slice/formInput';
import { deleteRecordExternal } from '@common/utils/database';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { getUrlExternal } from '@common/utils/handleActions/func/func';

const excuteDeleteExternal: (
  state: any,
  table: any,
  dispatch: any,
  currentRecordId: string
) => Promise<ActionResponse> = async (
  state,
  table,
  dispatch,
  currentRecordId
) => {
  const databaseMetadata = JSON.parse(table.databaseMetadata)[0];

  try {
    if (databaseMetadata) {
      const endpoints =
        databaseMetadata.endpoints && Array.isArray(databaseMetadata.endpoints)
          ? databaseMetadata.endpoints
          : [];

      const url = getUrlExternal(databaseMetadata, endpoints, 'delete', state);

      await deleteRecordExternal(
        url,
        databaseMetadata,
        table,
        currentRecordId
      ).then((value: any) => {
        if (value && value.id) {
          dispatch(
            setCurrentRerordExternal({
              id: value.id,
            })
          );
        }
      });
    }
    return {
      status: 'SUCCEED',
      message: 'Delete Succeed',
    };
  } catch (err: any) {
    dispatch(
      setError({
        message: err?.response?.data?.message,
        isError: true,
      })
    );

    return {
      status: 'FAILED',
      message: err?.response?.data?.message || err?.message,
    };
  }
};

export default excuteDeleteExternal;
