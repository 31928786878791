import { StyleSheet } from 'react-native';

const createStyles = () =>
  StyleSheet.create({
    wapperList: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    emptyList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });

export default createStyles;
