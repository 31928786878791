import React, { Dispatch, SetStateAction } from 'react';
import { View, Text, TextInput } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { get } from 'lodash';

import store from '@common/redux/store';
import { setForgotPassword } from '@common/redux/slice/action';
import { convertMessage, useForgotPassword } from './func';
import createStyles from './style';
import { Response } from './index';

interface IProps {
  appId: string;
  response: Response;
  value: string;
  locale: string;
  setResponse: Dispatch<SetStateAction<Response>>;
  setValue: Dispatch<SetStateAction<string>>;
}

const ForgotPassword = ({
  appId,
  response,
  value,
  locale,
  setValue,
  setResponse,
}: IProps) => {
  const styles = createStyles();
  const dispatch = store.dispatch;
  const state = store.getState();
  const actionComponent = state.action.actionComponent;

  const isJa = locale === 'ja';

  const onCancel = () => {
    dispatch(
      setForgotPassword({
        state: false,
        animation: 'fadeOutDownBig',
      })
    );
  };

  const onSubmit = async () => {
    try {
      setResponse({
        ...response,
        loading: true,
      });

      const res = await useForgotPassword({ appId: appId, value });

      setResponse({
        ...response,
        status: !!res,
        loading: false,
      });
    } catch (err: any) {
      setResponse({
        ...response,
        error:
          get(err, 'response.data.errors.email', '') ||
          get(err, 'response.data.message', ''),
      });

      console.log('err :>> ', err);
    }
  };

  return (
    <View>
      <Text style={styles.title}>
        {isJa ? 'パスワードのリセット' : 'Password Reset'}
      </Text>
      <Text style={styles.desc}>
        {isJa
          ? 'メールアドレスを入力すると、パスワードをリセットするためのメールが送信されます'
          : `Enter your email address and we'll send you instructions on how to reset your password.`}
      </Text>

      <Text style={styles.text}>{isJa ? 'メール' : 'Email'}</Text>
      <TextInput
        style={styles.input}
        onChangeText={(e) => setValue(e)}
        placeholder={
          isJa ? 'メールアドレスを入力してください' : 'Enter email...'
        }
        placeholderTextColor="#777777"
      />
      <Text style={styles.error}>
        {convertMessage({ message: response.error, locale: locale })}
      </Text>

      <RNButton
        onPress={onSubmit}
        labelStyle={{
          color: '#fff',
          fontSize: 14,
        }}
        style={{
          backgroundColor: response.loading ? '#BDBDBD' : '#34A0DD',
          borderRadius: 4,
          minHeight: 40,
          justifyContent: 'center',
          marginTop: 16,
        }}
        disabled={response.loading}
        loading={response.loading}
      >
        {isJa ? '送信' : 'Submit'}
      </RNButton>
      <RNButton
        onPress={onCancel}
        labelStyle={{
          fontSize: 14,
          color: '#777777',
        }}
        style={{
          borderRadius: 4,
          minHeight: 40,
          justifyContent: 'center',
          marginTop: 5,
        }}
      >
        {isJa ? 'キャンセル' : 'Cancel'}
      </RNButton>
    </View>
  );
};

export default ForgotPassword;
