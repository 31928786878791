import { Button, Input } from 'antd';
import React, { FC } from 'react';

const SearchComponent: FC<Record<string, any>> = ({
  disableDeleteButton,
  disableSearch,
  handleSearch,
  handleDelete,
  selectable,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
        marginBottom: 5,
      }}
    >
      {selectable && (
        <Button
          disabled={disableDeleteButton}
          danger
          type="primary"
          onClick={handleDelete}
        >
          Delete
        </Button>
      )}

      {!disableSearch && (
        <Input.Search
          style={{ width: 300, marginLeft: 5 }}
          placeholder="Search"
          onChange={handleSearch}
        />
      )}
    </div>
  );
};

export default SearchComponent;
