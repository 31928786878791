import imageBanner from '@common/assets/images/admob-320x50.png';
import imageSmartBanner from '@common/assets/images/admob-375x60.png';
import imageLargeBanner from '@common/assets/images/admob-320x100.png';
import imageMediumRectangle from '@common/assets/images/admob-300x250.png';
import imageInterstitial from '@common/assets/images/admob-interstitial.png';
import imageReward from '@common/assets/images/admob-reward.png';

export type AdmobBannerSize =
  | 'banner'
  | 'largeBanner'
  | 'mediumRectangle'
  | 'smartBanner'
  | 'interstitial'
  | 'rewarded';

export const ADMOB_IMAGE_BANNER: Record<AdmobBannerSize, string> = {
  banner: imageBanner,
  largeBanner: imageLargeBanner,
  mediumRectangle: imageMediumRectangle,
  smartBanner: imageSmartBanner,
  interstitial: imageInterstitial,
  rewarded: imageReward,
};

export const ADMOB_IMAGE_BANNER_FOR_MOBILE: Record<AdmobBannerSize, string> = {
  banner: require('@common/assets/images/admob-320x50.png'),
  largeBanner: require('@common/assets/images/admob-320x100.png'),
  mediumRectangle: require('@common/assets/images/admob-300x250.png'),
  smartBanner: require('@common/assets/images/admob-375x60.png'),
  interstitial: require('@common/assets/images/admob-interstitial.png'),
  rewarded: require('@common/assets/images/admob-reward.png'),
};

export const bannerFullScreen: Array<AdmobBannerSize> = [
  'interstitial',
  'rewarded',
];
