import * as React from 'react';
import * as ReactNative from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { Provider as StoreProvider } from 'react-redux';
import store from './redux/store';
import { Routes } from './routes';
import { theme } from './configs/theme';
import 'moment/locale/ja';

declare global {
  interface Window {
    ReactNative: any;
    React: any;
  }
}

window.React = React;
window.ReactNative = ReactNative;

declare global {
  interface Window {
    ReactNative: any;
    React: any;
  }
}

window.React = React;
window.ReactNative = ReactNative;
console.disableYellowBox = true;

export default function App() {
  return (
    <StoreProvider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <PaperProvider theme={theme}>
        <Routes />
      </PaperProvider>
      {/* </PersistGate> */}
    </StoreProvider>
  );
}
