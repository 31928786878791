import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateNotification = {
  token: string;
  targetScreen: string;
};

const initialState: StateNotification = {
  token: '',
  targetScreen: '',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setNotificationToken: (
      state: StateNotification,
      action: PayloadAction<string>
    ) => {
      if (action.payload) {
        state.token = action.payload;
      }
    },
    setTargetSCreen: (
      state: StateNotification,
      action: PayloadAction<string>
    ) => {
      if (action.payload) {
        state.targetScreen = action.payload;
      }
    },
  },
});

export const { setNotificationToken, setTargetSCreen } =
  notificationSlice.actions;
export default notificationSlice.reducer;
