import React, { FC, useMemo } from 'react';
import { WebView } from 'react-native-webview';
import { useSelector } from 'react-redux';

import { getCurrentRecord } from '@common/redux/selectors/database';
import { useBinding } from '@common/hooks/useBinding';

import createStyles from './style';
import { IWebView } from '@common/types/element';
import { View, Image as RNImage, Platform } from 'react-native';
import { isEmpty, pick } from 'lodash';

const CusWebView: FC<IWebView> = (attributes) => {
  const styles = createStyles(attributes);
  const binding = attributes.uri;
  const currentRecord = useSelector(getCurrentRecord) || {};
  const { handleBindingField } = useBinding();

  const uri = useMemo<string>(
    () => handleBindingField(binding, attributes.record || currentRecord) || '',
    [binding, currentRecord, handleBindingField]
  );
  // const urlConvert = uri.match(/^https?:/) ? uri : '//' + uri;

  // return <WebView style={styles.container} source={{ uri: urlConvert }} />;

  const validLink = isEmpty(uri.match(/^((http|https):\/\/)/));
  const check = validLink ? `https://${uri}` : uri;

  const expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);
  if (!uri) return <View style={styles.container} />;

  if (!uri.match(regex)) {
    return (
      <View style={styles.container}>
        <RNImage
          source={{
            uri: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5JMgzHBTZO_V1iTlq6Fr-Lywo85GD65RMtOFU1GgUjOhsw-DoYI4V0nzG99A9wqSduZU&usqp=CAU',
          }}
          style={{
            ...pick(attributes, ['width', 'height']),
          }}
          resizeMode={'contain'}
        />
      </View>
    );
  }
  return (
    <View
      style={{
        zIndex: attributes.zIndex,
        ...(Platform.OS !== 'web' && styles.container),
      }}
    >
      <WebView
        style={styles.container}
        source={{ uri: check as any }}
        mediaPlaybackRequiresUserAction={true}
        allowsInlineMediaPlayback={true}
        contentInsetAdjustmentBehavior={'automatic'}
        nestedScrollEnabled={true}
        renderError={() => {
          return <View style={styles.errorRender} />;
        }}
      />
    </View>
  );
};

export default CusWebView;
