import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {CustomIconList} from './CustomIcon';

interface Props {
    icon: string,
    color?: string,
    heigh?: number,
    width?: number,
};

function SvgComponent(props: Props) {
    const customIcon = CustomIconList.find(
        (iconName) => iconName.name === props.icon
    );
    if (customIcon !== undefined){
      return (
          <Svg
        id="prefix___x32_"
        x={0}
        y={0}
        viewBox= {customIcon.viewBox}
        opacity={1}
        {...props}
      >
        <Path
          d={customIcon.path}
          fill={props.color}
        />
      </Svg>
    )
  }else{
      return null;
  };
    
};

export default SvgComponent;
