import React, { memo, useCallback, useState } from 'react';
import { Button as RNButton } from 'react-native-paper';

import { APPS_SHARE, getPostUrl } from '@common/constants/shareApp.constant';
import { View } from 'react-native-animatable';
import styles from './style';
import { Image, Modal, Text, TouchableOpacity } from 'react-native';
import { copyToClipboard } from '@common/utils/functions';
import Icon from 'react-native-vector-icons/AntDesign';

interface Props {
  onClose: () => void;
  isShow: boolean;
}
const SharePopup: React.FC<Props> = ({ onClose, isShow }) => {
  const handleClose = () => {
    onClose();
  };

  const handleClickShare = useCallback((shareLink: string): void => {
    window.open(shareLink, '_blank');
  }, []);

  const handleCopy = useCallback((postUrl) => {
    copyToClipboard(postUrl);
  }, []);

  const renderOptionShare = useCallback(() => {
    return (
      <View style={styles.optionList}>
        {APPS_SHARE.map(({ icon, name, getLink }, index: number) => (
          <TouchableOpacity
            key={index}
            style={styles.optionTouch}
            onPress={() => handleClickShare(getLink())}
          >
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Image
                style={{
                  resizeMode: 'contain',
                  width: 66,
                  height: 66,
                  borderRadius: 66 / 2,
                  padding: 7,
                }}
                source={{
                  uri: icon,
                }}
              />
            </View>
            <View style={{}}>{name}</View>
          </TouchableOpacity>
        ))}
      </View>
    );
  }, [handleClickShare]);

  const getTargetUrl = window.location.href;

  const renderCopyLink = useCallback(() => {
    return (
      <View style={styles.optionCopyLink}>
        <View style={{ width: '88%' }}>
          <Text numberOfLines={1}>
            {
              // getPostUrl()
              getTargetUrl
            }
          </Text>
        </View>
        <RNButton onPress={() => handleCopy(getTargetUrl)}>
          <Text style={styles.optionButtonName}>{'Copy'}</Text>
        </RNButton>
      </View>
    );
  }, [handleCopy]);

  return (
    <TouchableOpacity style={styles.centeredView} onPress={handleClose}>
      <Modal
        visible={isShow}
        animationType="fade"
        transparent={true}
        onRequestClose={handleClose}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTop}>
              <Text style={styles.modalTopTitle}>Share</Text>
              <Icon
                style={{
                  marginLeft: 'auto',
                  marginVertical: 'auto',
                }}
                name={'close'}
                size={14}
                onPress={handleClose}
              />
            </View>
            <View style={styles.modalBody}>
              <View style={styles.modalBodyContent}>
                {renderOptionShare()}
                {renderCopyLink()}
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </TouchableOpacity>
  );
};

export default memo(SharePopup);
