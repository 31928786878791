import { StyleSheet, Platform } from 'react-native';
import { checkFont } from '../func';
import { get } from 'lodash';

const createStyles = ({ attrs, width, height }: any) => {
  const { fontFamily, opacity } = attrs;

  return StyleSheet.create({
    container: {
      width,
      opacity,
    },

    title: {
      fontFamily: checkFont(fontFamily),
      marginBottom: 5,
    },

    wrapperInput: {
      padding: 10,
      borderRadius: 5,
      borderColor: '#BDBDBD',
      borderWidth: 1,
      minHeight: 40,
    },
    block: {
      borderColor: '#BDBDBD',
      borderWidth: 1,
      borderRadius: 5,
    },
    image: {
      width: 26,
      height: 23,
      top: 14,
      position: 'absolute',
    },
    cardNumber: {
      width: width,
      height: 50,
      top: 0,
      textAlignVertical: 'center',
    },
    right: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 250,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    month: {
      width: 36,
      height: 40,
      position: 'absolute',
      top: 6.5,
      right: 73,
      textAlignVertical: 'center',
    },
    space: {
      width: 38,
      height: 40,
      position: 'absolute',
      top: Platform.OS === 'ios' ? 16.3 : 6.8,
      right: Platform.OS === 'android' ? 41 : 44.5,
      textAlignVertical: 'center',
      color: '#BDBDBD',
      alignSelf: 'center',
    },
    year: {
      width: 30,
      height: 40,
      position: 'absolute',
      top: Platform.OS === 'ios' ? 18 : 6,
      right: 44,
      textAlignVertical: 'center',
    },
    cvc: {
      width: 42,
      height: 40,
      position: 'absolute',
      top: 6,
      right: 7,
      textAlignVertical: 'center',
    },
    click: {
      backgroundColor: get(attrs, 'submitButton.backgroundColor', 'white'),
      borderRadius: get(attrs, 'submitButton.borderRadius', 5),
      justifyContent: 'center',
      borderColor: get(attrs, 'submitButton.backgroundColor', 'white'),
      marginTop: 20,
      minHeight: 44,
      alignItems: 'center',
    },
    label: {
      fontFamily: checkFont(fontFamily),
      fontSize: 15,
      color: get(attrs, 'submitButton.color', 'black'),
      textTransform: 'uppercase',
    },
    inputPk: {
      borderWidth: 2,
      borderRadius: 5,
      height: 45,
      borderColor: '#BDBDBD',
      position: 'relative',
    },
    group: {
      position: 'absolute',
      top: 52,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      paddingHorizontal: 8,
    },
    placeHolder: { color: '#BDBDBD' },
  });
};

export default createStyles;
