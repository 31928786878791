import { IChipList } from '@nocode/types';
import { get } from 'lodash';
import queryString from 'query-string';

import { Platform } from 'react-native';

export const getInitValues = (attrs: Record<string, any> = {}) => {
  let arr: any[] = [];
  for (let i = 1; i <= 4; i++) {
    arr.push({
      title: attrs.title.text,
    });
  }
  return arr;
};

export const getItemChipList = (attrs: IChipList) => {
  const { dataBinding, attributes } = attrs;

  const columnCount = get(attributes, 'columnCount', 2);

  if (Platform.OS !== 'web') {
    // App
    return dataBinding || [];
  } else {
    // web
    const search = queryString.parse(window?.location?.search);
    const target = search?.target;

    if (target) {
      // preview
      return dataBinding || [];
    } else {
      // client

      const listData = dataBinding || getInitValues(attributes);

      return columnCount === 1
        ? listData.slice(0, 2)
        : columnCount === 3
        ? listData.slice(0, 3)
        : listData;
    }
  }
};
