import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'width',
        'opacity',
        // 'height', // FIXME: when fix position multiple form in pc
      ]),
      zIndex: attributes.zIndex,
    },
  });
};

export const createFieldStyles = () => {
  return StyleSheet.create({
    formRow: {
      marginBottom: 18,
    },
    errorMessage: {
      marginTop: 6,
    },
    startRequired: { color: 'red', marginLeft: 3, fontSize: 17 },
    fieldLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
    },
  });
};

export default createStyles;
