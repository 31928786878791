import React, { FC, useMemo, useRef } from 'react';
import { IRectangle } from '@common/types/element';
import {
  GestureResponderEvent,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native';
import createStyles from './style';
import { find, isEmpty } from 'lodash';
import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { useSelector } from 'react-redux';
import { getDataSourceStore } from '@common/redux/selectors/database';

const Rectangle: FC<IRectangle> = (attrs) => {
  const datasource = useSelector(getDataSourceStore);
  const touchRef = useRef(null);

  const parentRecord = useMemo(() => {
    if (!attrs.parentListDatabaseUuid || !attrs.parentListItemId)
      return undefined;
    const parentDB = datasource[attrs.parentListDatabaseUuid];

    const parentData = find(
      parentDB,
      (item: Record<string, any>) => item._id === attrs.parentListItemId
    );
    return parentData;
  }, [attrs, datasource]);

  const styles = createStyles(attrs);

  const hasAction = !isEmpty(attrs.actions);

  if (hasAction) {
    const handlePress = (e: GestureResponderEvent) => {
      if (Platform.OS === 'web') {
        if ((touchRef.current as any) === (e.target as any) && attrs.onPress)
          attrs.onPress();
      } else {
        attrs.onPress ? attrs.onPress() : {};
      }
    };

    return (
      <TouchableOpacity
        style={styles.container}
        activeOpacity={1}
        ref={touchRef}
        onPress={handlePress}
      >
        <ObjectRender
          keyItem={attrs?.selectedItem?.itemIndex}
          arrComp={attrs?.children || []}
          isScreen={false}
          layout={{
            offsetTop: attrs?.y,
            offsetLeft: attrs?.x,
            parentWidth: attrs?.width,
          }}
          parentRecord={parentRecord}
        />
      </TouchableOpacity>
    );
  }

  return (
    <View style={styles.container}>
      <ObjectRender
        keyItem={attrs?.selectedItem?.itemIndex}
        arrComp={attrs?.children || []}
        isScreen={false}
        layout={{
          offsetTop: attrs?.y,
          offsetLeft: attrs?.x,
          parentWidth: attrs?.width,
        }}
        parentRecord={parentRecord}
      />
    </View>
  );
};

export default Rectangle;
