import { StyleSheet } from 'react-native';

const createStyles = () =>
  StyleSheet.create({
    cardWrapper: {
      paddingVertical: 3,
    },
    cardCover: {
      height: '100%',
      width: '100%',
    },
    cardContent: {
      paddingVertical: 4,
    },
  });

export default createStyles;
