import { size } from 'mathjs';
import React, { ComponentProps } from 'react';
import { FAB } from 'react-native-paper';
import SvgComponent from '../CustomIcon';
import { CustomIconList } from '../CustomIcon/CustomIcon';

type Props = ComponentProps<typeof FAB> | any;

const ActionButton: React.FC<Props> = (props) => {
  const { attributes, onPress } = props;
  const { nameIcon, backgroundColor, color, fontSize, opacity, zIndex } =
    attributes;

const CustomIcon = CustomIconList.find(
  (CustomIconName) => CustomIconName.name === nameIcon
)

  const childProps: Props = {
    icon:
      CustomIcon !== undefined
        ? () => <SvgComponent icon={nameIcon} color={color} />
        : nameIcon,
    style: {
      opacity,
      fontSize,
      backgroundColor,
      color,
      width: 56,
      height: 56,
      zIndex,
    },
  };

  return <FAB onPress={onPress} {...childProps} />;
};

export default ActionButton;
