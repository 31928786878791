import api from '@common/configs/api';
import { useEffect, useState } from 'react';

export type ReceivedProps = {
  domain: string;
};

const useShareCustomDomain = (props: ReceivedProps) => {
  const [loading, setLoading] = useState(true);
  const [app, setApp] = useState<any>();
  const [url, setUrl] = useState('');
  const { domain } = props;
  const getAppbyDomain = async () => {
    try {
      const response = await api({
        method: 'get',
        url: `v2/apps-domain/${domain.replace(/^https?:\/*/, '')}`,
      });

      setApp(response?.data as any);
      if (response?.data?.version == 'v3') {
        setUrl(
          `https://sharev3.click.dev/preview/${response?.data?.appid}?locale=ja&hiddenStatusBar=true`
        );
      } else {
        setUrl(
          `https://share.click.dev/preview/${response?.data?.appid}?locale=ja&hiddenStatusBar=true`
        );
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppbyDomain();
  }, []);
  return {
    ...props,
    app,
    url,
    loading,
  };
};

export type Props = ReturnType<typeof useShareCustomDomain>;

export default useShareCustomDomain;
