import React, { ComponentProps, useState, useEffect, useCallback } from 'react';
import { ToggleButton } from 'react-native-paper';
import { IToggle } from '@nocode/types';
import { get, isEmpty, isNil } from 'lodash';
import createStyles from './style';
import SvgComponent from '../CustomIcon';
import { CustomIconList } from '../CustomIcon/CustomIcon';

type Props = ComponentProps<typeof ToggleButton>;

const Toggle: React.FC<IToggle> = ({
  attributes,
  onPress,
  changeInput,
  valueInputs,
  actions,
  groupActionId,
}) => {
  const [isActive, setIsActive] = useState<boolean>(valueInputs);
  const styles = createStyles(attributes);

  const handleToggle = (id: string, isAction: any) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});

    if (groupActionId && isNil(isAction)) {
      if (isEmpty(sectionOnpress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  const activeCustomIcon = CustomIconList.find(
    (customIcon) => attributes.activeIcon === customIcon.name
  );

  const inactiveCustomIcon = CustomIconList.find(
    (customIcon) => attributes.inactiveIcon === customIcon.name
  );

  const childProps: Props = {
    status: 'unchecked',
    icon: isActive
      ? activeCustomIcon?.name != undefined
        ? () => (
            <SvgComponent
              icon={attributes.activeIcon}
              heigh={attributes.size}
              width={attributes.size}
              color={attributes.activeColor}
            />
          )
        : attributes.activeIcon
      : inactiveCustomIcon?.name != undefined
      ? () => (
          <SvgComponent
            icon={attributes.inactiveIcon}
            heigh={attributes.size}
            width={attributes.size}
            color={attributes.inactiveColor}
          />
        )
      : attributes.inactiveIcon,
    color: isActive ? attributes.activeColor : attributes.inactiveColor,
    size: attributes.size,
  };

  useEffect(() => {
    setIsActive(valueInputs);
  }, [valueInputs]);

  const handlePress = useCallback(async () => {
    changeInput && changeInput(!valueInputs);
    const { clickActions, activeActions, inactiveActions } = attributes;

    if (!onPress) return;

    if (isActive === true && activeActions) {
      await handleToggle('activeActions', activeActions);
      return;
    }

    if (isActive === false && inactiveActions) {
      await handleToggle('inactiveActions', inactiveActions);
      return;
    }

    return handleToggle('clickActions', clickActions);
  }, [valueInputs, isActive]);

  return (
    <ToggleButton onPress={handlePress} style={styles.icon} {...childProps} />
  );
};

export default Toggle;
