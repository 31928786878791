import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import useAnimate, { Props, ReceivedProps } from './hook';

import * as Animatable from 'react-native-animatable';
import { enumDefault } from '../types';

const AnimationWrapperView: FC<Props> = ({
  animation = enumDefault.screen,
  children,
}) => {
  return (
    <Animatable.View animation={animation} style={styles.container} duration={900}>
      {children}
    </Animatable.View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    // backgroundColor: 'white',
  },
});

const AnimationWrapper: FC<ReceivedProps> = (props) => {
  return <AnimationWrapperView {...useAnimate(props)} />;
};

export default AnimationWrapper;
