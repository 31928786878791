import io from 'socket.io-client';
import { config } from '@common/configs/api';
import { PLUGIN_WRAPPER_KEY, loadPlugins } from '@common/utils/plugin';

export let pluginSocket: any;

export const handlePluginsSocket = (
  ownerId: number | undefined,
  callback: any // TODO
) => {
  // if (pluginSocket && pluginSocket.disconnect) {
  //   pluginSocket.disconnect();
  // }
  // pluginSocket = io(config.pluginSocket, {
  //   query: {
  //     userId: ownerId,
  //   },
  // });
  // pluginSocket.on('availableLibraries', async (plugins: any) => {
  //   const pluginsFormatted = [...plugins].map((plugin) => {
  //     return {
  //       url: `${config.pluginSocket}?userId=${ownerId}&pluginType=${plugin.type}`,
  //       name: plugin.type,
  //     };
  //   });
  //   await Promise.all(
  //     loadPlugins(PLUGIN_WRAPPER_KEY.DEV_PLUGINS, pluginsFormatted)
  //   );
  //   callback();
  // });
};
