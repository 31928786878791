import axios from 'axios';

export enum PLUGIN_WRAPPER_KEY {
  DEV_PLUGINS = 'devPlugins',
  INSTALLED_PLUGINS = 'installedPlugins',
}

export interface CustomWindow extends Window {
  [PLUGIN_WRAPPER_KEY.INSTALLED_PLUGINS]: any;
  [PLUGIN_WRAPPER_KEY.DEV_PLUGINS]: any;
}
declare const window: CustomWindow;

export const loadPlugins = (
  mode: PLUGIN_WRAPPER_KEY,
  plugins: {
    url: string;
    name: string;
  }[]
): Promise<string>[] => {
  const pluginsLoading: Promise<string>[] = [];
  if (!window[mode]) window[mode] = {};

  [...plugins].forEach((plugin) => {
    const pluginName = plugin.name.trim();
    const url = plugin.url;

    if (
      mode === PLUGIN_WRAPPER_KEY.INSTALLED_PLUGINS &&
      window[mode][pluginName]
    ) {
      return window[mode][pluginName];
    }

    const script = document.createElement('script');
    script.src = url;
    script.setAttribute('crossorigin', '');
    document.body.appendChild(script);

    const promise = new Promise<string>((resolve, reject) => {
      script.addEventListener('load', () => {
        resolve('Load done');
      });

      script.addEventListener('error', () => {
        reject(new Error(`Script could not load: ${url}`));
      });
    });
    pluginsLoading.push(promise);
    window[mode][pluginName] = promise;
  });

  return pluginsLoading;
};

export const loadSinglePlugin = async (
  plugin: { url: string; code: string },
  force: boolean
): Promise<any> => {
  console.log('loadSinglePlugin', plugin);

  const pluginName = plugin.code.trim();
  const url = plugin.url;
  if (window[pluginName] && !force) {
    return window[pluginName]?.dev?.default || window[pluginName].default;
  }

  const { data } = await axios.get(url);
  eval(data);
  return window[pluginName].default;
};

export const isPluginDataBinding = (prop?: {
  role?: string;
  reference?: string;
  type?: string;
  props?: [];
}) => {
  return prop?.role && prop.reference;
};

export const formatPluginDataSource = (dataSource: {
  type?: string;
  imageType?: string;
  binding?: any;
  imageUrl?: any;
}) => {
  if (dataSource?.type === 'imageBinding') {
    switch (dataSource?.imageType) {
      case 'internal':
        return dataSource?.binding;
      case 'url':
      case 'uploaded':
        return dataSource?.imageUrl;
    }
  }
  return dataSource?.binding?.options?.placeholderImage || dataSource;
};
