import { IAdMob } from '@common/types/element';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = IAdMob & {
  isUseAdmob: boolean;
};

const initialState: InitialState = {
  id: 0,
  appid: '',
  iosAppId: '',
  androidAppId: '',
  isUseAdmob: false,
};

const { reducer, actions } = createSlice({
  name: 'admob',
  initialState,
  reducers: {
    setAppIDs: (
      state: InitialState,
      action: PayloadAction<Partial<InitialState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    setIsUseAdmob: (state: InitialState, action: PayloadAction<boolean>) => {
      state.isUseAdmob = action.payload;
    },
  },
});

export const { setAppIDs, setIsUseAdmob } = actions;

export default reducer;
