import { IMap } from '@common/types/element';
import axios from 'axios';
import {
  chunk,
  compact,
  filter,
  find,
  first,
  get,
  isNil,
  isUndefined,
  map,
  pick,
  uniqBy,
  isEmpty,
} from 'lodash';
import queryString from 'query-string';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as rax from 'retry-axios';
import { MarkerType } from '../../../types/map.type';
import {
  DEFAULT_CENTER,
  DEFAULT_DISTANCE,
  DEFAULT_ZOOM,
  isDevelopment,
  LOCATION_TIME,
} from '../constans';

import Geolocation, {
  GeolocationResponse,
} from '@react-native-community/geolocation';
import {
  convertKmToM,
  convertLat,
  getCurrentLocationStatus,
  getDistanceFromCurrent,
} from '../functions';

const useMap = (props: IMap) => {
  let clusterRef: any = useRef();
  const { onPress, currentLocation } = props;
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const customMapStyle = props?.attributes?.style?.customStyle;
  const markerType = get(props, 'attributes.markerType');
  const selectedAddressMarker = get(
    props,
    'attributes.markers.selectedAddressMarker'
  );
  const markersTypeOfPin = get(
    props,
    'attributes.markers.markerTypeOfPin',
    'address'
  );

  const markerTypeOfPin = get(
    props,
    'attributes.marker.markerTypeOfPin',
    'address'
  );

  const imgCluster = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  };

  const cusAxios = axios.create();
  cusAxios.defaults.raxConfig = {
    instance: cusAxios,
  };
  rax.attach(cusAxios);

  const apiKey = props?.attributes?.apiKey;

  const loadScriptMap = () => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.id = apiKey;
    script.onload = () => props.loadScriptMap && props.loadScriptMap();

    document.head.appendChild(script);
  };

  //Map state
  const [markers, setMarkers] = useState<Array<MarkerType>>([]);
  const [mapState, setMapState] = useState<any>(null);

  //Get current location
  const showCurrentUserLocation = getCurrentLocationStatus(props.attributes);

  // get markers
  const handleSelectedMarker: any = useMemo(() => {
    const firstSelectedMarker: any = first(props?.dataBinding);

    return firstSelectedMarker?.selectedAddressMarker;
  }, [props.dataBinding]);

  const requestSelectedMarker = useMemo(
    () => ({
      apiKey,
      addresses: [handleSelectedMarker],
    }),
    [apiKey, target, handleSelectedMarker]
  );

  const handledDistance =
    props.getFieldValue &&
    props.getFieldValue(props.attributes.markers?.distanceSearch, null, false);

  const distanceToSearch =
    handledDistance < 0 || !handledDistance
      ? DEFAULT_DISTANCE
      : Number(handledDistance);

  const selectedMaker = markers.find((marker) => marker.center);
  const closeMarkers = useMemo(() => {
    return markers
      .map((marker: any) => {
        return {
          ...marker,
          dis: selectedMaker
            ? getDistanceFromCurrent(selectedMaker, marker)
            : !isUndefined(currentLocation) &&
              getDistanceFromCurrent(currentLocation, marker),
        };
      })
      .filter((marker) => marker.dis <= distanceToSearch);
  }, [markers, distanceToSearch]);

  const requestBody = useMemo(() => {
    const addressMarker = chunk(
      compact(map(props.dataBinding, 'markerAddress')),
      50
    );
    return (
      target &&
      map(addressMarker, (address) => ({
        apiKey,
        addresses: address,
      }))
    );
  }, [apiKey, target, props.dataBinding]);

  const postAddressMarker = async (requestBody: any) => {
    const { data } = await cusAxios.post(
      isDevelopment
        ? 'http://13.115.182.81/maps'
        : 'https://api.click.dev/maps',
      requestBody,
      {
        raxConfig: {
          shouldRetry: (err) => {
            const cfg = rax.getConfig(err);
            if (
              typeof cfg?.currentRetryAttempt === 'number' &&
              typeof cfg.retry === 'number'
            ) {
              if (cfg?.currentRetryAttempt >= cfg.retry) return false;
              return true;
            }
            return false;
          },
        },
      }
    );
    return data;
  };

  const getGeometryMarker = async () => {
    let data: any[] = [];

    const getGeometryFromAddress = async () => {
      if (
        requestBody &&
        requestBody.length &&
        get(requestBody, '[0].addresses')?.length &&
        get(requestBody, '[0].apiKey')
      ) {
        // get lat, long of dataBinding
        const requestData: any[] = [];
        for (const requestBD of requestBody) {
          const dataMarker = await postAddressMarker(requestBD);
          requestData.push(...dataMarker);
        }
        const availableData = filter(props.dataBinding, 'markerAddress').map(
          (record, idx) => ({
            ...get(requestData.flat(), idx, {}),
            ...pick(record, ['_id', 'imageMarker']),
          })
        );
        const listMarkers = uniqBy(availableData, 'lat' && 'lng').filter(
          (marker) => !isNil(marker.lat) && !isNil(marker.lng)
        );
        data.push(...listMarkers);
      }
    };
    if (props.attributes.markerType === 'simple') {
      if (markerTypeOfPin === 'address') {
        await getGeometryFromAddress();
      } else {
        const geometry = props.dataBinding[0];
        if (geometry) {
          const geometryData = {
            lng: Number(geometry.lng),
            lat: convertLat(Number(geometry.lat)),
            imageMarker: geometry?.imageMarker,
          };
          geometryData && data.push(geometryData);
        }
      }
    } else {
      if (markersTypeOfPin === 'address') {
        await getGeometryFromAddress();
      } else {
        const records = filter(props.dataBinding, 'markerGeometry').map(
          (record) => ({
            ...pick(record.markerGeometry, ['lat', 'lng', '_id']),
            imageMarker:
              record.markerGeometry?.imageMarker || record?.imageMarker,
          })
        );
        const availableData = records
          .filter((marker) => !isNil(marker.lat) && !isNil(marker.lng))
          .map((marker) => ({
            lng: Number(marker.lng),
            lat: convertLat(Number(marker.lat)),
            _id: marker?._id,
            imageMarker: marker?.imageMarker,
          }));
        data.push(...uniqBy(availableData, 'lat' && 'lng'));
      }
      if (requestSelectedMarker && props.attributes.markers?.selectedMarker) {
        const currentPin = await postAddressMarker(requestSelectedMarker);
        if (currentPin && !isNil(currentPin[0])) {
          currentPin[0].center = true;
          data.push(...currentPin);
        }
      }
    }
    setMarkers(data);
  };

  const getMarkers = useCallback(async () => {
    getGeometryMarker();
  }, [
    requestBody,
    props.dataBinding,
    markerType,
    selectedAddressMarker,
    markersTypeOfPin,
    markerTypeOfPin,
  ]);

  //center location
  const centerLocation = useMemo(() => {
    const selectedCenter = markers.find((marker) => marker.center);
    if (selectedCenter) {
      return selectedCenter;
    } else {
      if (!isUndefined(currentLocation) && showCurrentUserLocation) {
        return get(compact([currentLocation, ...markers]), '0', DEFAULT_CENTER);
      } else {
        return get(compact(markers), '0', DEFAULT_CENTER);
      }
    }
  }, [markers, currentLocation]);

  //others
  const handleClick = useCallback(
    (marker: any) => () => {
      if (marker?.isCurrentLocation) return;
      return onPress && onPress('', { itemId: marker._id });
    },
    [onPress]
  );

  const getCurrentUserMaker = (clusterRef: any) => {
    const markers = clusterRef.getMarkers() || [];
    return find(markers, (item) => item?.icon?.isCurrentLocation);
  };

  const handlePress = useCallback(() => {
    if (mapState && clusterRef.current) {
      const currentUserMarker = getCurrentUserMaker(clusterRef.current);
      if (isEmpty(currentUserMarker)) return;
      const userPosition = currentUserMarker.getPosition();
      mapState.setCenter(userPosition);
      mapState?.setZoom(DEFAULT_ZOOM);
    }
  }, [clusterRef, mapState]);

  // hook effects
  useEffect(() => {
    const scriptMap = document.getElementById(apiKey) as HTMLScriptElement;

    apiKey && !scriptMap && !isNil(props.googleMapisloaded) && loadScriptMap();
  }, []);

  useEffect(() => {
    if (showCurrentUserLocation) {
      //realtime location
      const watchID = navigator.geolocation.watchPosition(
        (position: GeolocationResponse) => {
          if (position && showCurrentUserLocation && clusterRef.current) {
            const currentUserMarker = getCurrentUserMaker(clusterRef.current);
            if (!isEmpty(currentUserMarker)) {
              currentUserMarker.setPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            }
          }
        },
        (err) => {
          console.log('watchPosition error: ', err);
        },
        {
          enableHighAccuracy: true,
        }
      );

      return () => {
        watchID != null && navigator.geolocation.clearWatch(watchID);
      };
    }
  }, [showCurrentUserLocation, clusterRef]);

  useLayoutEffect(() => {
    if (target) {
      getMarkers();
    }
  }, [props.dataBinding]);

  useLayoutEffect(() => {
    if (mapState) {
      const Bounds = new window.google.maps.LatLngBounds();
      if (
        (!isUndefined(currentLocation) && showCurrentUserLocation) ||
        selectedMaker
      ) {
        !isUndefined(currentLocation) &&
          !selectedMaker &&
          currentLocation &&
          Bounds.extend(currentLocation);
        selectedMaker && Bounds.extend(selectedMaker);
        if (selectedMaker) {
          if (props.attributes.markerType === 'multiple') {
            if (closeMarkers.length > 1) {
              // closeMarkers > 0
              closeMarkers.map((closeMarker) => {
                Bounds.extend(closeMarker);
              });
              mapState.fitBounds(Bounds);
            } else {
              // closeMarkers < 0
              const centerMarker = find(markers, 'center');

              if (centerMarker) {
                var myLatLng = new google.maps.LatLng({
                  lat: centerMarker?.lat,
                  lng: centerMarker?.lng,
                });
                const radiusZoom = convertKmToM(distanceToSearch) / 2;
                var circleOptions = {
                  center: myLatLng,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                  map: mapState,
                  radius: radiusZoom /*  meters */,
                };
                var myCircle = new google.maps.Circle(circleOptions);
                mapState.fitBounds(myCircle.getBounds());
              }
            }
          } else {
            return;
          }
        } else {
          if (props.attributes.markerType === 'multiple') {
            if (closeMarkers.length > 0) {
              //closeMarkers > 0
              closeMarkers.map((closeMarker) => {
                Bounds.extend(closeMarker);
              });
              mapState.fitBounds(Bounds);
            } else {
              //closeMarkers < 0
              if (currentLocation) {
                var myLatLng = new google.maps.LatLng({
                  lat: currentLocation.lat,
                  lng: currentLocation.lng,
                });
                const radiusZoom = convertKmToM(distanceToSearch) / 2;
                var circleOptions = {
                  center: myLatLng,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                  map: mapState,
                  radius: radiusZoom /*  meters */,
                };
                var myCircle = new google.maps.Circle(circleOptions);
                mapState.fitBounds(myCircle.getBounds());
              }
            }
          } else if (
            markers.length > 0 &&
            props.attributes.markerType === 'simple'
          ) {
            markers.map((marker) => {
              Bounds.extend(marker);
            });
            mapState.fitBounds(Bounds);
          } else {
            return;
          }
        }
      } else if (
        (isUndefined(currentLocation) || !showCurrentUserLocation) &&
        !selectedMaker &&
        markers.length > 1
      ) {
        markers.map((marker) => {
          Bounds.extend(marker);
        });
        mapState.fitBounds(Bounds);
      } else {
        return;
      }
    }
  }, [
    mapState,
    markers,
    showCurrentUserLocation,
    currentLocation,
    handledDistance,
    closeMarkers,
  ]);

  return {
    ...props,
    apiKey,
    cusAxios,
    currentLocation,
    showCurrentUserLocation,
    customMapStyle,
    requestBody,
    mapState,
    setMapState,
    markers,
    setMarkers,
    centerLocation,
    imgCluster,
    handleClick,
    handlePress,
    clusterRef,
  };
};

export default useMap;
