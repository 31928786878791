import axios from 'axios';
import { cloneDeep, isEmpty } from 'lodash';
import store from '@common/redux/store';
import { IAction } from '@common/types/element';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { getTextBinding } from '@common/utils/handleBinding';
import appConfig from '../../../../appConfig.json';
import { actionCustomAction } from '@common/redux/slice/customAction';

const executeCustom: (action: IAction) => Promise<ActionResponse> = async (
  action: IAction
) => {
  const state: any = store.getState();
  const listInputs: any[] = [];
  const dispatch = store.dispatch;

  if (action.options.custom) {
    action.options.custom.forEach((element) => {
      const input = getTextBinding(
        element,
        !isEmpty(state.database.currentRecord)
          ? state.database.currentRecord.record
          : {}
      );
      listInputs.push(input);
    });
  }
  const { options } = action;
  const customActionObj = state.customAction.customActions.find(
    (element: any) => element.id === action.options.customActionId
  );
  const dataToJson = customActionObj?.config
    ? customActionObj?.config
    : options.customAction?.config;

  const customActionConfig = JSON.parse(dataToJson);
  const bodyClone = cloneDeep(customActionConfig.body);

  const handleBody = (body: any) => {
    let bodyString = '';
    if (!body) return body;
    Object.keys(body).forEach((key) => {
      Object.keys(body[key]).forEach((value: any) => {
        const KeyValue = body[key][value];
        let parseStr: any;
        try {
          parseStr = JSON.parse(KeyValue);
        } catch {
          parseStr = KeyValue;
        }

        if (parseStr?.type) {
          if (listInputs[parseStr.index]) {
            bodyString += listInputs[parseStr.index];
          } else {
            bodyString += '';
          }
        } else {
          if (/^[a-zA-Z0-9]+$/.test(parseStr)) {
            parseStr = `"${parseStr}"`;
          }
          bodyString += parseStr;
        }
      });
    });
    return bodyString;
  };

  const convertHeader = (header: any) => {
    if (!header) return {};
    let headerTemp: any = {};
    Object.keys(header).forEach(
      (key) => (headerTemp = Object.assign(headerTemp, header[key]))
    );

    return headerTemp;
  };

  const convertParams = (params: any) => {
    if (!params) return [];
    let paramsTemp: any = {};
    let paramArr = [];
    Object.keys(params).forEach(
      (key) => (paramsTemp = Object.assign(paramsTemp, params[key]))
    );
    for (const property in paramsTemp) {
      paramArr.push({
        key: property,
        value: paramsTemp[property],
      });
    }
    return paramArr;
  };

  const handleDetachedParamHeader = (arr: any[]) => {
    const objNew: any = {};
    arr.forEach((e) => {
      let valueNew = convertObjToStringValue(e.value);
      if (typeof e.value === 'boolean') {
        valueNew = e.value ? 'true' : 'false';
      }
      const arrTemp: any[] = objNew[e?.type?.toLocaleLowerCase()] || [];
      objNew[e?.type?.toLocaleLowerCase()] = [
        ...arrTemp,
        { [e?.name]: valueNew },
      ];
    });
    return objNew;
  };

  const convertObjToStringValue = (obj: any) => {
    let textNew: any = [];
    try {
      if (obj) {
        const objNew = JSON.parse(obj);
        let objAssign: any = {};
        Object.keys(objNew).forEach((e: any) => {
          objAssign = Object.assign(objAssign, objNew[e]);
        });
        Object.keys(objAssign).forEach((f: any) => {
          try {
            const objParse: any = JSON.parse(objAssign[f]);
            const value = objParse?.type ? objParse.testValue : objParse;

            if (listInputs[objParse.index] && objParse?.type) {
              textNew.push(listInputs[objParse.index]);
            } else if (objParse?.type && !listInputs[objParse.index]) {
              textNew.push('');
            } else {
              textNew.push(value);
            }
          } catch {
            if (objAssign[f]?.trim().length > 0) {
              textNew.push(objAssign[f]);
            }
          }
        });
      }
    } catch {
      textNew = [obj];
    }
    return textNew.join('');
  };

  let paramHeaderArr: any[] = [];
  if (customActionConfig?.headers) {
    paramHeaderArr.push(customActionConfig?.headers);
  }
  if (customActionConfig?.params) {
    paramHeaderArr.push(customActionConfig?.params);
  }

  const { header, param } = handleDetachedParamHeader(paramHeaderArr.flat());

  const bodyMapStr = handleBody(bodyClone);
  let jsonBody = bodyMapStr;
  if (bodyMapStr && typeof bodyMapStr === 'string') {
    jsonBody = JSON.parse(bodyMapStr);
  }

  if (
    typeof jsonBody === 'object' &&
    header &&
    JSON.stringify(header).includes('application/x-www-form-urlencoded')
  ) {
    const cloneBody: any = cloneDeep(jsonBody);
    var formBody: any = [];
    for (var property in cloneBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(cloneBody[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    formBody = formBody.join('&');
    jsonBody = formBody;
  }

  let baseUrl = customActionConfig.baseUrl;

  if (customActionConfig?.urlGeneralApi) {
    baseUrl = convertObjToStringValue(
      JSON.stringify(customActionConfig?.urlGeneralApi)
    );
  }

  const appInfo = state.appInfo.app;

  try {
    const response = await axios({
      method: 'POST',
      baseURL: `${appConfig.baseUrl}/api-test`,
      data: {
        url: baseUrl,
        method: customActionConfig.method,
        headers: convertHeader(header),
        parameters: convertParams(param),
        body: jsonBody,
        appID: appInfo?.id,
      },
    });
    dispatch(
      actionCustomAction({
        actionCustomActions: [
          ...state.customAction.actionCustomActions,
          {
            data: response?.data,
            customActionId: action?.options?.customAction?.id,
          },
        ],
      })
    );
    return {
      status: 'SUCCEED',
      message: 'Custom action successful !',
    };
  } catch (error) {
    return {
      status: 'SUCCEED',
      message: 'Custom action failed !',
    };
  }
};

export default executeCustom;
