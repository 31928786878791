import React from 'react';
import { View, Image, Platform } from 'react-native';
import ContentLoader, { Circle } from 'react-content-loader/native';

type Props = {
  avatarURL: string;
  placeholder: any;
};

const AvatarList = ({ avatarURL, placeholder }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <View
      style={{
        height: 50,
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {avatarURL && (
        <>
          {isLoading ? null : (
            <ContentLoader
              speed={3}
              viewBox="0 0 198 115"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              width="100px"
              height="100px"
              style={{ top: 25 }}
            >
              <Circle cx="100" cy="58" r="50" />
            </ContentLoader>
          )}
          <Image
            source={{
              uri: typeof avatarURL !== 'string' ? placeholder : avatarURL,
            }}
            onError={() => {
              setIsLoading(false);
            }}
            onLoad={() => {
              setIsLoading(true);
            }}
            style={{
              width: 45,
              height: 45,
              borderRadius: 50,
              opacity: isLoading ? 1 : 0,
              display: isLoading || Platform.OS !== 'web' ? 'flex' : 'none',
            }}
          />
        </>
      )}
    </View>
  );
};

export default AvatarList;
