import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(243, 242, 247)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  selectWrapper: {
    width: 200,
    height: '100vh',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    paddingVertical: 30,
  },
  buttonBackDashboard: {
    fontWeight: '600',
    borderRadius: 8,
    color: 'rgb(255, 255, 255)',
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
});

export default styles;
