import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { IconButton } from 'react-native-paper';
import SvgComponent from '../CustomIcon';
import { CustomIconList } from '../CustomIcon/CustomIcon';

type Props = {
  icon: string;
  color?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
  handlePress?: () => any;
};

const IconList = ({
  icon,
  color = '#bbb',
  size = 24,
  style,
  handlePress,
}: Props) => {
  const CustomIconName = CustomIconList.find(
    (customIcon) => customIcon.name === icon
  );

  return (
    <View>
      {CustomIconName !== undefined ? (
        <SvgComponent icon={icon} width={size} heigh={size} color={color} />
      ) : (
        <IconButton
          icon={icon}
          size={size}
          color={color}
          style={style}
          onPress={handlePress}
        />
      )}
    </View>
  );
};

export default IconList;
