import { pick, map } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from 'rn-placeholder';

type SkeletonLoadingProps = {
  attrs: any;
  initialize: boolean;
};

function SkeletonLoading({ attrs, initialize }: SkeletonLoadingProps) {
  const {
    attributes: { dividerType },
  } = attrs;

  const SkeletonItem = (index: number | any) => {
    return (
      <Placeholder
        Animation={Fade}
        Left={PlaceholderMedia}
        style={{
          borderTopWidth:
            dividerType && dividerType === 'none'
              ? 0
              : index === 0 && dividerType && dividerType === 'line'
              ? 0
              : dividerType && dividerType === 'shadow'
              ? 1
              : 0,
          borderBottomWidth:
            dividerType && dividerType === 'none'
              ? 0
              : index !== 3
              ? 1
              : dividerType && dividerType === 'shadow'
              ? 1
              : 0,
          borderColor: '#e2e1e1',
          justifyContent: 'center',
          alignItems: 'center',
          ...(dividerType && dividerType === 'shadow' && { borderRadius: 20 }),
          paddingVertical: 15,
        }}
      >
        <PlaceholderLine width={20} />
        <PlaceholderLine width={60} />
        <PlaceholderLine width={40} />
      </Placeholder>
    );
  };

  const renderSkeleton = () => {
    return map(Array.from(Array(4).keys()), (item, index: number) => {
      return <SkeletonItem key={item} index={index} />;
    });
  };

  return (
    <View
      style={{
        padding: 10,
        ...(initialize
          ? pick(attrs, ['marginTop', 'marginLeft', 'width', 'zIndex'])
          : {}),
      }}
    >
      {renderSkeleton()}
    </View>
  );
}

export default SkeletonLoading;
