import { ILabel } from '@common/types/element';
import { Platform } from 'react-native';

export const getTextDecorationLine = (attributes: ILabel) => {
  let textDecorationLine = 'none';
  if (attributes.linethrough && attributes.textDecoration) {
    textDecorationLine = 'underline line-through';
  } else {
    if (attributes.linethrough) {
      textDecorationLine = 'line-through';
    }
    if (attributes.textDecoration) {
      textDecorationLine = 'underline';
    }
  }
  return textDecorationLine;
};

export const getShadow = (attributes: any) => {
  if (!attributes.shadow) return {};

  return {
    shadowColor: attributes.shadow.color,
    shadowRadius: attributes.shadow.size,
    shadowOpacity:
      attributes?.shadow?.size === 0 ? 0 : attributes?.shadow?.blur || 0,
    shadowOffset: {
      width: attributes.shadow.x,
      height: attributes.shadow.y,
    },
    elevation:
      attributes?.shadow?.size === 0
        ? attributes?.shadow?.x
        : attributes?.shadow?.size,
  };
};

export const getFontWeight = (attributes: any) => {
  if (!attributes.fontWeight) return 'normal';

  return typeof attributes.fontWeight === 'string'
    ? attributes.fontWeight
    : attributes.fontWeight.toString();
};

export const checkFont = (font?: any, fontWeight?: boolean) => {
  if (font) {
    if (Platform.OS !== 'web') {
      switch (font) {
        case 'Noto Serif, serif':
          if (fontWeight === true) {
            return 'ShipporiMincho-Bold';
          }
          return 'ShipporiMincho-Medium';
        case 'Noto Sans JP, sans-serif':
          return 'NotoSansJP-Regular';
        case 'NotoSansJP-Regular':
          return 'NotoSansJP-Regular';
        case 'Roboto':
          if (font === 'Roboto' && Platform.OS === 'android') {
            if (fontWeight === true) {
              return 'KaiseiOpti-Bold';
            }
            return 'KaiseiOpti-Regular';
          } else {
            return 'Roboto-Light';
          }
        case 'Roboto, sans-serif':
          if (font === 'Roboto, sans-serif' && Platform.OS === 'android') {
            if (fontWeight === true) {
              return 'KaiseiOpti-Bold';
            }
            return 'KaiseiOpti-Regular';
          } else {
            return 'Roboto-Light';
          }
        case 'KaiseiOpti-Regular':
          return 'KaiseiOpti-Regular';
        case 'Roboto-Light':
          return 'Roboto-Light';
        default:
          return 'Roboto-Bold';
      }
    } else {
      if (fontWeight === true) {
        switch (font) {
          case 'Noto Sans JP, sans-serif':
            return 'NotoSansJP-Bold';
          case 'Noto Serif, serif':
            return 'NotoSerif-Bold';
          default:
            return 'Roboto-Bold';
        }
      }
      switch (font) {
        case 'Noto Sans JP, sans-serif':
          return 'NotoSansJP-Regular';
        case 'Noto Serif, serif':
          return 'NotoSerif-Regular';
        default:
          return 'Roboto';
      }
    }
  }
  return 'Roboto';
};
