import {
  setForgotPassword,
  setTemporayPassword,
} from '@common/redux/slice/action';
import { loginSuccess } from '@common/redux/slice/auth';
import { resetValue, setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { MetaData } from '@common/types/action';
import { get, pick } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { signinUser } from '../func/apiFunction';
import { registerFCMUser, unregisterFCMUser } from './FCMregister';

const excuteSignin: (
  action: Record<string, any>,
  metadata: MetaData,
  locale?: string
) => Promise<ActionResponse> = async (
  action: Record<string, any>,
  metadata: MetaData,
  locale?: string
) => {
  const { record, mappingField, handleResetValue } = metadata;

  const isLock = get(action, 'options.lock', false);

  const authType = get(action, 'options.authType');

  const dispatch = store.dispatch;

  try {
    const params: any = !isLock
      ? {
          ...pick(record, ['password', authType]),
        }
      : {
          email: record.email,
          password: record.password,
        };

    const resp = await signinUser(params);

    if (resp.code && [400, 401].includes(resp.code)) {
      if (!isLock) {
        if (resp.code === 401) {
          dispatch(
            setError(
              locale === 'ja'
                ? { ...resp, message: 'ユーザーが見つかりませんでした' }
                : resp
            )
          );
        } else {
          dispatch(
            setError(
              locale === 'ja'
                ? { ...resp, message: 'パスワードが異なります' }
                : resp
            )
          );
        }
      }

      const { message } = resp;
      return {
        status: 'FAILED',
        message:
          locale === 'ja'
            ? message === 'User not found.'
              ? 'ユーザーが見つかりませんでした'
              : 'パスワードが異なります'
            : message,
      };
    } else {
      const state: any = store.getState();

      mappingField && dispatch(resetValue(mappingField));

      await unregisterFCMUser(); //remove old token

      await registerFCMUser(resp, state.appInfo.app?.id);

      dispatch(
        loginSuccess({
          user: {
            ...resp.record,
            databaseId: resp.databaseId,
            userId: resp?._id,
            appId: state.appInfo.app?.id,
            loggingWithPasswordTemporary: resp.loggingWithPasswordTemporary,
            ...pick(resp, ['createdAt', 'updatedAt']),
          },
        })
      );

      handleResetValue();

      dispatch(setTemporayPassword(record?.password));

      dispatch(
        setForgotPassword({
          state: false,
          animation: 'fadeInUpBig',
        })
      );

      return {
        status: 'SUCCEED',
        message: resp.loggingWithPasswordTemporary
          ? ''
          : locale === 'ja'
          ? 'ログインが成功しました'
          : 'Login success',
      };
    }
  } catch (err: any) {
    return {
      status: 'FAILED',
      message:
        get(err, 'response.data.message', '') ===
          'Your temporary password has experied.' && locale === 'ja'
          ? '仮パスワードは有効ではありません'
          : get(err, 'response.data.message', '') || err?.message,
    };
  }
  // callBack();
};

export default excuteSignin;
