import React from 'react';
import { Text, View } from 'react-native';
import createStyles from './style';
import { useLocation } from '@common/routes/hooks';
import queryString, { ParsedQuery } from 'query-string';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { DEVICE_NOT_TIMER } from '@common/constants/shared';

type Props = {
  originX: number;
  isShowStatusBar?: boolean;
  barStyle?: any;
};

const StatusBar = (props: Props) => {
  const { search } = useLocation();
  const { device, hiddenStatusBar }: ParsedQuery<any> =
    queryString.parse(search);

  const hasTimer = DEVICE_NOT_TIMER.includes(device);

  const originX = props.originX < 1 ? props.originX : 1;
  const styles = createStyles({
    isIphone: hasTimer,
    originX,
  });
  const checkStatusBar = () => {
    if (props.barStyle === 'dark') {
      return 'black';
    }
    if (props.barStyle === 'light') {
      return 'white';
    } else {
      return 'black';
    }
  };
  return (
    <React.Fragment>
      {props.isShowStatusBar && (
        <View style={styles.container}>
          <React.Fragment>
            <View style={styles.signal}>
              <Icon
                name="signal"
                size={10 * originX}
                color={checkStatusBar()}
              />
              <Text style={[styles.signalText, { color: checkStatusBar() }]}>
                Click
              </Text>
            </View>
            <View style={[styles.battery]}>
              <Text style={[styles.batteryText, { color: checkStatusBar() }]}>
                100%
              </Text>
              <Icon
                name={'battery-full'}
                size={14 * originX}
                color={checkStatusBar()}
              />
            </View>
          </React.Fragment>
        </View>
      )}
    </React.Fragment>
  );
};

export default StatusBar;
