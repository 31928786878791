import { isEmpty, isNil } from 'lodash';
import * as Yup from 'yup';

export type IField = {
  default: boolean;
  fieldId: string;
  fid: string;
  label: string;
  multiline: boolean;
  name: string;
  placeholder: string;
  required: boolean;
  requiredText: string;
  type: string;
  length: number;
  typeValidateLen: string;
  validateLenText: string;
};

export const generateSchema = (fields: Array<IField>, locale: String) => {
  let schema: any = {};

  fields.forEach((field) => {
    if (!field.required) {
      if (!isNil(field.typeValidateLen) && field.typeValidateLen !== 'none') {
        schema[field.fieldId || field.fid] = createSchema(field, locale);
      }
      return;
    }
    schema[field.fieldId || field.fid] = createSchema(field, locale);
  });
  return Yup.object().shape(schema);
};

const createSchema = (field: IField, locale: String) => {
  const { type, requiredText, fieldId } = field;

  switch (type) {
    case 'image-upload':
      return Yup.object().nullable(true).required(requiredText);

    case 'input':
      const { typeValidateLen, validateLenText, length } = field;
      const validLength = isNil(length) ? 0 : length;

      switch (typeValidateLen) {
        case 'min':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .min(
              validLength,
              validateLenText?.trim()?.length
                ? validateLenText
                : locale === 'ja'
                ? `最低文字数は${validLength}文字です`
                : `Min length is ${validLength} character`
            );
        case 'max':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .max(
              validLength,
              validateLenText?.trim()?.length
                ? validateLenText
                : locale === 'ja'
                ? `最大値は${validLength}文字です`
                : `Max length is ${validLength} character`
            );
        case 'equal':
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            )
            .length(
              validLength,
              validateLenText?.trim()?.length
                ? validateLenText
                : locale === 'ja'
                ? `項目は${validLength}文字にしてください`
                : `Field must have ${validLength} character`
            );
        default:
          return Yup.string()
            .ensure()
            .test(
              `${field.fieldId || field.fid}`,
              requiredText,
              (value: string) => {
                if (field.required) return !isEmpty(value);
                return true;
              }
            );
      }

    default:
      return Yup.string().nullable(true).required(requiredText);
  }
};
