import { StyleSheet } from 'react-native';
import { checkFont } from '../func';

const createStyles = (styleData: Record<string, any>) => {
  const isContained = styleData.type !== 'contained';
  const imageSizeHeight = isContained ? 1.2 : 1.8;
  const imageSizeWidth = isContained ? 0.7 : 1.2;

  return StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: styleData.height,
      width: styleData.width,
      opacity: styleData.opacity,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isContained ? '#fff' : styleData.backgroundColor,
      borderRadius: styleData.borderRadius,
      borderWidth: 1,
      borderColor: styleData.backgroundColor,
      position: 'relative',
      overflow: 'hidden',
      paddingHorizontal: 20,
    },

    iconImage: {
      height: styleData.fontSize * imageSizeHeight,
      width: styleData.fontSize * imageSizeWidth,
      marginTop: isContained ? 0 : 2,
      marginRight: isContained ? 2 : 0,
    },

    text: {
      fontSize: styleData.fontSize,
      color: isContained ? '#000' : styleData.color,
      fontFamily: checkFont(styleData.fontFamily),
      fontWeight: styleData.styles?.fontWeight,
      opacity: styleData.opacity,
      textAlign: 'center',
    },
  });
};

export default createStyles;
