import { Linking, Platform } from 'react-native';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { IAction } from '@common/types/';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const trustdockLogin: (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => {
  const socialConfig = action.options;
  AsyncStorage.setItem(
    'clickFlows',
    JSON.stringify({
      currentRecord,
      actionComponent,
    })
  );
  if (Platform.OS !== 'web') {
    let url = `https://auth.trustdock.id/v1/authorization?client_id=${socialConfig.clientId}&redirect_uri=${socialConfig.redirectUri}&scope=openid&response_type=code%20id_token&state=trustdock&nonce=0&display=touch`;
    await Linking.openURL(url);
  }
  return {
    status: 'FAILED',
  };
};

export default trustdockLogin;
