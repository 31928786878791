import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Obj = { [key: string]: any };

type State = {
  values: Obj;
  defaultValues: Obj;
  error: Record<string, any>;
};

type Action = {
  objectId: string;
  value: string;
};

const initialState: State = {
  values: {},
  defaultValues: {},
  error: {
    message: '',
    isError: false,
  },
};

const fromInputSlice = createSlice({
  name: 'formInput',
  initialState: initialState,
  reducers: {
    setDefaultValue: (state: State, action: PayloadAction<Obj>) => {
      return {
        ...state,
        defaultValues: action.payload,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    },
    resetValue: (state: State, action: PayloadAction<Obj>) => {
      let newState = { ...state, values: { ...state.values } };
      let arrId = Object.keys(action.payload);
      for (let i = 0; i < arrId.length; i++) {
        delete newState.values[arrId[i]];
        // delete newState.defaultValues[arrId[i]];
      }
      return newState;
    },
    resetAllValue: (state: State) => {
      state.defaultValues = {};
      state.values = {};
    },
    setValues: (state: State, action: PayloadAction<any>) => {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload.values,
        },
      };
    },
    changeValue: (state: State, action: PayloadAction<Action>) => {
      if (action.payload) {
        state.values = {
          ...state.values,
          [action.payload.objectId]: action.payload.value,
        };
      }
    },
    setError: (state: State, action: PayloadAction<any>) => {
      state.error = {
        message: action.payload.message,
        isError: true,
      };
    },
    resetError: (state: State) => {
      state.error = initialState.error;
    },
  },
});
export const {
  setDefaultValue,
  resetValue,
  setValues,
  changeValue,
  resetAllValue,
  setError,
  resetError,
} = fromInputSlice.actions;

export default fromInputSlice.reducer;
