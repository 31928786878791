import React, { FC, useMemo, useState } from 'react';
import {
  TouchableOpacity,
  Text,
  View,
  Image,
  ActivityIndicator,
  Platform,
  ImageBackground,
} from 'react-native';
import { IImageUpload } from '@common/types/element';
import { ImagePicker } from '@common/utils/upload';
import createStyles from './style';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { isEmpty, isString } from 'lodash';
import useImageDefault from '@common/hooks/useImageDefault';
import { defaultURL } from '@common/constants/shared';

const CusImageUpload: FC<any> = (attributes) => {
  const { url, placeholder } = attributes;

  const [error, setError] = useState('');

  const styles = createStyles(attributes);

  const { valueInputs, changeInput }: UseValueInputProps =
    useValueInputs(attributes);

  const [pending, setPending] = useState(false);
  const isPlaceholderImage =
    placeholder?.placeholderImage && placeholder?.placeholderImageEnabled;

  const defaultValueImg = isEmpty(valueInputs) && !isEmpty(url);

  const handlePress = () => {
    if (Platform.OS !== 'web') return;
    setPending(true);
    error && setError('');

    ImagePicker.showImagePicker(
      { quality: 0.7, setPending, imageUpload: true, setError, attributes },
      (response: any) => {
        if (response) {
          changeInput(response);
          setPending(false);
        }
      }
    );
  };

  const Loading = () => {
    if (!pending) return null;
    return (
      <View>
        <ActivityIndicator />
        <Text style={{ color: 'white' }}>Uploading...</Text>
      </View>
    );
  };

  const uri =
    error && isPlaceholderImage
      ? placeholder.placeholderImage
      : error
      ? defaultURL
      : isString(valueInputs)
      ? valueInputs
      : valueInputs?.url;

  if (!isEmpty(valueInputs)) {
    return (
      <React.Fragment>
        <View style={styles.innerWrapper}>
          <TouchableOpacity onPress={handlePress} style={{ flex: 1 }}>
            <View style={styles.preview}>
              <Image
                style={[styles.previewImage, pending ? styles.faded : {}]}
                source={{
                  uri,
                }}
                resizeMode="contain"
                onError={({ nativeEvent }: any) => {
                  setError(nativeEvent?.error);
                }}
              />
              <Loading />

              {!valueInputs.url ||
                (!valueInputs && (
                  <TouchableOpacity
                    onPress={handlePress}
                    style={styles.selectAnother}
                  >
                    <Text style={styles.selectImage}>Choose Photo</Text>
                  </TouchableOpacity>
                ))}
            </View>
          </TouchableOpacity>
        </View>
      </React.Fragment>
    );
  }

  return (
    <View style={styles.innerWrapper}>
      <ImageBackground
        source={{
          uri: defaultValueImg
            ? url
            : isString(valueInputs)
            ? valueInputs
            : valueInputs?.url,
        }}
        resizeMode="contain"
        style={styles.image}
      >
        <TouchableOpacity style={styles.emptyWrapper} onPress={handlePress}>
          <Loading />

          {!valueInputs && !pending && (
            <Text style={styles.text}>Choose Photo</Text>
          )}
        </TouchableOpacity>
      </ImageBackground>
    </View>
  );
};

const Main: FC<IImageUpload> = (attributes) => {
  const { url, placeholder } = useImageDefault(attributes);

  return (
    <CusImageUpload
      {...{ ...attributes, ...{ url: url, placeholder: placeholder } }}
    />
  );
};

export default Main;
