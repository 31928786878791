import { useMemo } from 'react';
import { IDatePicker } from '@common/types/element';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { getDateFormat, OPTIONS_MODEL } from './helps/func';
import { PickerType } from './helps/type';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import { get, isEmpty, isString } from 'lodash';
import { getBindingRecord } from '@common/utils/handleBinding';

const useDatePicker = (attributes: IDatePicker) => {
  const PlatformWeb = Platform.OS === 'web';

  const { defaultDateFormat, defaultDateValue } = attributes;

  const convertDate = (params: any) => {
    if (defaultDateFormat === 'dateOnly') {
      return moment(params).format(OPTIONS_MODEL[defaultDateFormat]);
    } else if (defaultDateFormat === 'dateTextInput') {
      return moment(params).format(OPTIONS_MODEL[defaultDateFormat]);
    } else {
      return moment(params).format(OPTIONS_MODEL[defaultDateFormat]);
    }
  };

  const { valueInputs: value, changeInput }: UseValueInputProps =
    useValueInputs(attributes);

  const valueDatePicker = useMemo(() => {
    return value ? convertDate(value) : value;
  }, [value]);

  const settings: PickerType = {
    mode: 'date',
    confirmText: 'OK',
    cancelText: 'Cancel',
    theme: 'ios',
    dateFormat: getDateFormat(defaultDateFormat),
  };

  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [defaultValue, setDefaultValue] = useState<any>(null);

  const initValue = useMemo(() => {
    let initValue;

    if (isString(defaultDateValue)) {
      switch (defaultDateValue) {
        case 'startOfToday':
          initValue = moment().startOf('day').toISOString();
          break;
        case 'currentTime':
          initValue = moment().toISOString();
          break;
        default:
          initValue = defaultDateValue;
      }
    } else {
      const record = attributes?.record;
      const getSource = get(attributes, 'defaultDateValue.source');

      initValue =
        getBindingRecord(getSource, {}, record) || moment().toISOString();
    }

    return convertDate(initValue);
  }, [attributes]);

  useEffect(() => {
    setDefaultValue(initValue);
  }, [initValue]);

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };

  useEffect(() => {
    if (isEmpty(valueDatePicker)) {
      changeInput(defaultValue);
    }
  }, [valueDatePicker, defaultValue]);

  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };

  const handleConfirm = (date: any) => {
    // setValue(date);
    changeInput(convertDate(date));
    hideDatePicker();
  };

  return {
    attributes,
    settings,
    isDatePickerVisible,
    value: valueDatePicker,
    showDatePicker,
    handleConfirm,
    PlatformWeb,
    hideDatePicker,
    convertDate,
    defaultValue,
  };
};

export type Props = ReturnType<typeof useDatePicker>;

export default useDatePicker;
