import React from 'react';
import { Card, IconButton, Paragraph } from 'react-native-paper';
import { View, Image, TouchableOpacity, Text, Platform } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import { CardDetail } from './index';
import { getPlaceholder, imageUrl } from '../../utils/handleCardDetail';
import { parseText } from '../func';
import { defaultURL } from '../../utils/common.constant';
import createStyles from './style';
import { Attributes } from '@nocode/types/horizontalCardList.type';

type Props = {
  cardItem: CardDetail;
  attributes: Attributes;
  index: number;
  heightCard: number | undefined;
  onPress?: (id: string | undefined, options: Record<string, any>) => void;
  isLast: boolean;
  cardMargin?: {
    marginBottom: number;
    marginTop: number;
    marginRight: number;
    marginLeft: number;
  };
};

const CardItem = ({
  cardItem,
  attributes,
  index,
  heightCard,
  onPress,
  isLast,
  cardMargin,
}: Props) => {
  const styles = createStyles(attributes, cardMargin, isLast, index);

  const cardCoverUri = React.useMemo(
    () => imageUrl(attributes?.cardCover, cardItem),
    [attributes?.cardCover, cardItem]
  );
  const avatarUri = React.useMemo(
    () => imageUrl(attributes?.avatarImage, cardItem, 'avtImge'),
    [attributes?.avatarImage, cardItem]
  );

  const [cardUrl, setCardUrl] = React.useState(cardCoverUri);
  const [avatarUrl, setAvatarUrl] = React.useState(avatarUri);

  React.useLayoutEffect(() => {
    setCardUrl(cardCoverUri);
  }, [cardCoverUri]);
  React.useLayoutEffect(() => {
    setAvatarUrl(avatarUri);
  }, [avatarUri]);

  const handlePress = (id: string, item: Record<string, any>) => {
    const groupActionId = get(attributes, 'groupActionId', '');
    const records = get(attributes, 'records', []);

    if (id === 'onPress') {
      if (isNil(attributes[id]) || isEmpty(attributes[id])) {
        onPress &&
          onPress(undefined, {
            itemId: item.itemId,
            groupActionId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
          });
      } else {
        onPress &&
          onPress(id, {
            itemId: item.itemId,
            indexRecord: item.indexRecord,
            externalId: records[item.indexRecord]?.id,
            externalRecord: records[item.indexRecord],
          });
      }
    }
  };

  const isElementEmpty =
    !attributes.title.enabled && attributes.avatarImage.enabled;

  const isCanvas =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  return (
    <View style={Platform.OS === 'ios' ? styles.cardWrapperContent : {}}>
      <Card
        style={[
          styles.cardWrapper,
          {
            ...(heightCard &&
              !isCanvas && {
                height:
                  heightCard -
                  (cardMargin?.marginTop ?? 0) -
                  (cardMargin?.marginBottom ?? 0),
              }),
          },
        ]}
        onPress={() => {
          handlePress('onPress', {
            itemId: cardItem?._id,
            indexRecord: index,
          });
        }}
      >
        {Platform.OS === 'web' ? (
          <img
            src={
              cardUrl
                ? cardUrl
                : getPlaceholder(attributes?.cardCover) || defaultURL
            }
            alt=""
            style={{
              width: '100%',
              height: attributes?.cardCoverStyle?.height,
              borderRadius: attributes?.cardCoverStyle?.rounding,
              borderTopLeftRadius: attributes?.cardCoverStyle?.rounding,
              borderTopRightRadius: attributes?.cardCoverStyle?.rounding,
              objectFit: attributes?.cardCover?.image?.imageResize || 'cover',
            }}
            onError={() => {
              setCardUrl(getPlaceholder(attributes?.cardCover) || defaultURL);
            }}
          />
        ) : (
          <Image
            style={styles.cardImage}
            defaultSource={{
              uri: getPlaceholder(attributes?.cardCover) || defaultURL,
            }}
            source={{
              uri: cardUrl,
            }}
            onError={() => {
              setCardUrl(getPlaceholder(attributes?.cardCover) || defaultURL);
            }}
            resizeMode={attributes?.cardCover?.image?.imageResize || 'cover'}
          />
        )}

        <Card.Content style={styles.cardContentWrapper}>
          {attributes?.title?.enabled && (
            <View style={[styles.contentWrapper, styles.cardTitleWrapper]}>
              {!!attributes.title.icon && (
                <IconButton
                  size={16}
                  icon={attributes.title.icon}
                  color={attributes.titleStyle.iconColor}
                />
              )}
              <Text
                style={styles.cardTitle}
                ellipsizeMode="tail"
                numberOfLines={attributes?.title?.titleLineNum === 1 ? 1 : 2}
              >
                {parseText(cardItem?.title)}
              </Text>
            </View>
          )}
          {isElementEmpty && <View style={styles.emptyWrapper} />}

          {attributes?.subTitle?.enabled && (
            <View style={[styles.contentWrapper, styles.cardSubTitleWrapper]}>
              {!!attributes.subTitle.icon && (
                <IconButton
                  size={16}
                  icon={attributes.subTitle.icon}
                  color={attributes.subTitleStyle.iconColor}
                />
              )}
              <Text
                style={styles.cardSubTitle}
                ellipsizeMode="tail"
                numberOfLines={
                  attributes?.subTitle?.subtitleLineNum === 1 ? 1 : 2
                }
              >
                {parseText(cardItem?.subTitle)}
              </Text>
            </View>
          )}

          {attributes.subTitle2?.enabled && (
            <TouchableOpacity style={styles.cardWrapperSubTitle2}>
              {!!attributes.subTitle2.icon && (
                <IconButton
                  size={16}
                  icon={attributes.subTitle2.icon}
                  color={attributes.subTitle2Style.iconColor}
                />
              )}

              <Text
                style={styles.cardSubTitle2}
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {parseText(cardItem.subTitle2)}
              </Text>
            </TouchableOpacity>
          )}

          {attributes?.avatarImage?.enabled && (
            <View style={styles.avatarWrapper}>
              <Image
                style={styles.avatar}
                defaultSource={{
                  uri: getPlaceholder(attributes?.avatarImage) || defaultURL,
                }}
                source={{
                  uri: avatarUrl,
                }}
                onError={() => {
                  setAvatarUrl(
                    getPlaceholder(attributes?.avatarImage) || defaultURL
                  );
                }}
                resizeMode={
                  attributes?.avatarImage?.image?.imageResize || 'cover'
                }
              />
            </View>
          )}

          {attributes?.tag?.enabled && (
            <TouchableOpacity style={styles.cardWrapperTag}>
              {!!attributes.tag.icon && (
                <IconButton
                  size={16}
                  icon={attributes.tag.icon}
                  color={attributes.tagStyle.iconColor}
                />
              )}

              <Paragraph
                style={styles.cardTag}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {parseText(cardItem?.tag)}
              </Paragraph>
            </TouchableOpacity>
          )}
        </Card.Content>

        {attributes?.footer?.enabled && (
          <Card.Actions style={styles.cardFooterWrapper}>
            {!!attributes.footer.icon && (
              <IconButton
                style={{ marginLeft: 0 }}
                size={16}
                icon={attributes.footer.icon}
                color={attributes.footerStyle.iconColor}
              />
            )}
            <Text
              style={styles.cardFooter}
              ellipsizeMode="tail"
              numberOfLines={attributes?.footer?.footerLineNum}
            >
              {cardItem?.footer}
            </Text>
          </Card.Actions>
        )}
      </Card>
    </View>
  );
};

export default CardItem;
