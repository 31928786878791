import React, {
  useRef,
  useState,
  useImperativeHandle,
  useEffect,
  useCallback,
} from 'react';
import { forwardRef } from 'react';
import { Linking, NativeModules, Platform } from 'react-native';
import WebView from 'react-native-webview';

const RNLine = {
  context: { login: (config: any, a: any) => {} },
  component: forwardRef((pros, ref): any => {
    const [visible, setVisible] = useState(false);
    const scheme = NativeModules?.ReactNativeConfigModule?.SCHEME_DEEP_LINK_APP;
    const local = useRef<any>({ config: {}, callback: () => {} }).current;

    const login = (config: any, callback = () => {}) => {
      local.callback = callback;
      local.config = config;
      setVisible(true);
      if (Platform.OS === 'ios') {
        Linking.openURL(
          `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${local.config?.clientId}&redirect_uri=${local.config.redirectUri}&state=${scheme}&scope=${local.config.scope}`
        );
      }
    };

    const handleUrl = useCallback((url: any) => {
      if (url?.url) {
        const code = url?.url?.split('code=')[1];
        local.callback(code);
        setVisible(false);
      }
    }, []);

    useEffect(() => {
      Linking.getInitialURL()
        .then((ev) => {
          if (ev) {
            handleUrl(ev);
          }
        })
        .catch((err) => {
          console.warn('An error occurred', err);
        });
      Linking.addEventListener('url', handleUrl);
      return () => Linking.removeEventListener('url', handleUrl);
    }, []);
    useImperativeHandle(ref, () => ({ login }));

    return (
      visible && (
        <WebView
          style={{ flex: 1, display: 'none' }}
          source={{
            uri: `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${local.config?.clientId}&redirect_uri=${local.config.redirectUri}&state=${scheme}&scope=${local.config.scope}`,
          }}
          originWhitelist={['intent://']}
        />
      )
    );
  }),
  init: (e: any) => (RNLine.context = e),
  login: (config: any, callback: any) =>
    RNLine.context?.login(config, callback),
};

export default RNLine;
