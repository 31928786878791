import axios from 'axios';
import { DATE_FORMAT, TABLE_ID_PATH } from '@common/constants/shared';
import { databaseSelector } from '@common/redux/selectors/database';
import store, { RootState } from '@common/redux/store';
import { ICollection, IRecord } from '@common/types';
import { find, findIndex, get, isArray, isEmpty } from 'lodash';
import moment from 'moment';
import appConfig from '../../appConfig.json';
import { setParamsAndHeaders } from '@common/components/LibraryComponent/function';
import { getUrlExternal } from '@common/utils/handleActions/func/func';

export const getCollections = () => {
  const state: any = store.getState();

  const { database: collections } = databaseSelector(state);
  return collections;
};

export const getCollection = (appDatabaseId: number | string) => {
  const collections = getCollections();

  return (
    find(collections, {
      databaseUuid: appDatabaseId,
    }) ||
    find(collections, {
      appDatabaseId: appDatabaseId,
    })
  );
};

export const getFieldName = (tableId: number, fieldId: string) => {
  const collections = getCollections();

  const table: any = find(collections, { appDatabaseId: tableId });
  if (isEmpty(table)) return null;
  const field: any = find(
    table.databaseColumn,
    findIndex(table.databaseColumn, (o: any) => {
      return o.fid === fieldId;
    }) > -1
      ? { fid: fieldId }
      : {
          db: {
            relationshipId: fieldId,
          },
        }
  );
  if (isEmpty(field)) return null;

  return field.name;
};

export const findTable = (tables: Array<ICollection>, tableId: number) => {
  if (isArray(tables)) {
    return (
      find(tables, { [TABLE_ID_PATH]: tableId }) ||
      find(tables, { databaseUuid: tableId })
    );
  }
  return get(tables, tableId);
};

export const getFieldTable = (tableId: string, fieldId: string) => {
  if (!tableId && !fieldId) return {};

  const collection: ICollection | any = getCollection(tableId);

  return find(collection?.fields, { fid: fieldId }) || {};
};

export const getLastedRecordCreate = (databaseUuid: string | any) => {
  if (!databaseUuid) return null;
  const state: any = store.getState();

  const listRecordCreated = get(state, 'database.recordCreated', {});

  return get(listRecordCreated, databaseUuid, {});
};

export const getActions = (actions: any) => {
  const c_actions = get(Object.values(actions || {}), '0.actions', []);
  return c_actions || [];
};

export const getFieldType = (databaseId: string, fieldId: string) => {
  const field = getFieldTable(databaseId, fieldId);

  return field?.type;
};

const fieldTypeDateTime = {
  DATE: 'date',
  DATE_ONLY: 'dateOnly',
};

export const getFieldValue = (value: any, type: string) => {
  if (!value) return '';

  switch (type) {
    case fieldTypeDateTime.DATE:
      return moment(value).format(DATE_FORMAT.LLL);
    case fieldTypeDateTime.DATE_ONLY:
      return moment(value).format(DATE_FORMAT.DATE_ONLY);
    default:
      return value;
  }
};

export const getRecordFormId = (appDatabaseId: number) => {
  const collection: ICollection | any = getCollection(appDatabaseId);

  const { data } = getRecords(collection.databaseUuid);

  return data || [];
};

export const getRecordExternalCollection = async (
  metadata: any,
  collection: any
) => {
  const state: any = store.getState();

  const endpoints =
    metadata.endpoints && Array.isArray(metadata.endpoints)
      ? metadata.endpoints
      : [];

  const url = getUrlExternal(metadata, endpoints, 'getAll', state);

  let method = metadata?.method ? metadata.method : 'GET';

  if (endpoints.length) {
    const itemEndpoint = endpoints.find((item: any) => item.key === `getAll`);
    method = itemEndpoint?.method;
  }

  const { paramArr, header } = setParamsAndHeaders(metadata?.config);
  const body = {
    url: url,
    headers: header,
    parameters: [...paramArr],
    body: metadata?.body ? JSON.parse(metadata.body) : '',
    method,
  };
  const appDatabaseId = collection.appDatabaseId;

  const appInfo = state.appInfo.app;

  try {
    const records = await axios({
      method: 'POST',
      baseURL: `${appConfig.baseUrl}/external-collection?appId=${appInfo?.id}&appDatabaseId=${appDatabaseId}`,
      data: body,
    });
    const previewData = getPreviewData(records.data, metadata?.resultKeys);
    return previewData;
  } catch (error) {
    console.log(error);
  }
};

const getPreviewData = (response: any, key: any) => {
  let data;
  if (!key.length) {
    data = response;
  } else {
    if (key.length === 1) {
      data = response[key[0]];
    } else {
      data = response[key[0]][key[1]];
    }
  }
  if (Array.isArray(data)) {
    return data;
  }
  if (isConvertableToArray(data)) {
    let newData: any;
    newData = Object.values(data);
    return newData;
  }
};

const isConvertableToArray = (object: any) => {
  const keys =
    typeof object === 'object' && !Array.isArray(object)
      ? Object.keys(object)
      : [];
  if (
    (keys.length > 1 && typeof keys[0] !== 'string') ||
    (keys.length > 1 && typeof Number(keys[0] === 'number'))
  ) {
    for (let i = 0; i < keys.length - 1; i++) {
      if (typeof +keys[i] === 'string' || +keys[i] + 1 !== +keys[i + 1]) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const createUpdateRecordExternal = async (
  url: string,
  metadata: any,
  collection: any,
  bodyObj: any,
  currentRecordId?: string
) => {
  const { appDatabaseId } = collection;

  const { paramArr, header } = setParamsAndHeaders(metadata?.config);

  let method = currentRecordId ? 'PUT' : 'POST';

  const endpoints =
    metadata.endpoints && Array.isArray(metadata.endpoints)
      ? metadata.endpoints
      : [];

  const typeCall = currentRecordId ? 'update' : 'create';

  if (endpoints.length) {
    const itemEndpoint = endpoints.find(
      (item: any) => item.key === `${typeCall}`
    );
    method = itemEndpoint?.method;
  }

  if (url.includes('{{id}}') && currentRecordId) {
    url = url.replace('{{id}}', currentRecordId);
  }

  const body = {
    url: url,
    headers: header,
    parameters: [...paramArr],
    body: bodyObj,
    method,
  };

  const state: any = store.getState();
  const appInfo = state.appInfo.app;

  const response = await axios({
    method: 'POST',
    baseURL: `${appConfig.baseUrl}/external-collection?appId=${appInfo?.id}&appDatabaseId=${appDatabaseId}`,
    data: body,
  });

  return response?.data;
};

export const deleteRecordExternal = async (
  url: string,
  metadata: any,
  collection: any,
  currentRecordId: string
) => {
  const { appDatabaseId } = collection;

  const { paramArr, header } = setParamsAndHeaders(metadata?.config);

  let method = 'DELETE';

  const endpoints =
    metadata.endpoints && Array.isArray(metadata.endpoints)
      ? metadata.endpoints
      : [];

  if (endpoints.length) {
    const itemEndpoint = endpoints.find((item: any) => item.key === `delete`);
    method = itemEndpoint?.method;
  }

  if (url.includes('{{id}}')) {
    url = url.replace('{{id}}', currentRecordId);
  }

  const body = {
    url: url,
    headers: header,
    parameters: [...paramArr],
    body: '',
    method,
  };

  const state: any = store.getState();
  const appInfo = state.appInfo.app;

  const response = await axios({
    method: 'POST',
    baseURL: `${appConfig.baseUrl}/external-collection?appId=${appInfo?.id}&appDatabaseId=${appDatabaseId}`,
    data: body,
  });

  return response?.data;
};

export const getRecordDataSource = (TableId: string, recordId: string) => {
  const state: any = store.getState();

  const dataSource = state.database.dataSource;

  const records = dataSource[TableId] || [];

  if (isEmpty(records)) return null;

  return find(records, { _id: recordId });
};

export const getRecordById = (records: IRecord[], _id: string) => {
  return (
    find(records, {
      _id,
    }) || undefined
  );
};

export const getParentListRecordComponent = (obj: any) => {
  if (isEmpty(obj?.parentListDatabaseUuid) && isEmpty(obj?.parentListItemId))
    return null;
  return obj?.parentListDatabaseUuid && obj?.parentListItemId
    ? {
        currentParent: getRecordDataSource(
          obj?.parentListDatabaseUuid,
          obj?.parentListItemId
        ),
        parentListDatabaseUuid: obj?.parentListDatabaseUuid,
      }
    : {};
};

export const getRecords = (collectionId: string) => {
  const state: any = store.getState();

  const listTable = state.database.dataSource;

  return listTable[collectionId] || [];
};

export const getRecord = (collectionId: string, recordId: string) => {
  const records = getRecords(collectionId);

  return find(records, { _id: recordId }) || null;
};

export const getTable = (databaseUuid: string) => {
  const state: RootState = store.getState();

  const listTable = state.database.database;

  return find(listTable, { databaseUuid: databaseUuid }) || null;
};
