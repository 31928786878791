import { IAppBar } from '@nocode/types';
import React, { FC } from 'react';
import { Platform, View, Dimensions } from 'react-native';
import { Appbar } from 'react-native-paper';
import { checkFont } from '../func';
import { isEmpty, get } from 'lodash';
import SvgComponent from '../CustomIcon';
import { CustomIconList } from '../CustomIcon/CustomIcon';

const iconAppBarWidth = 48;

const AppBar: FC<IAppBar> = ({ y, attributes, onPress, groupActionId }) => {
  const {
    leftIcon,
    title,
    rightIcon1,
    rightIcon2,
    backgroundColor,
    color,
    opacity,
    fontFamily,
    fontSize,
    borderColor,
    borderStyle,
    borderWidth,
    zIndex,
  } = attributes;

  const { width } = Dimensions.get('window');

  const handlePress = (id: string) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});
    if (groupActionId) {
      if (isEmpty(sectionOnpress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  const originX = attributes.originX < 1 ? attributes.originX : 1;
  const checkState = () => {
    return (
      <Appbar.Action
        style={{ display: 'flex', alignItems: 'center' }}
        color={color}
        icon={''}
        animated={false}
      />
    );
  };

  type AppBarIconObj = {
    AppBarLeft: iconType,
    AppBarRight1: iconType,
    AppBarRight2: iconType,
  }

  type iconType =  {
    isCustomIcon: boolean, 
    index?: number,
    name: string,
  }
  
  const AppBarIcon: AppBarIconObj = findIcon(leftIcon.icon, rightIcon1.icon, rightIcon2.icon);
  
  function getCustomIcon(customIconName: string, iconName: string, index: number){
    if(customIconName === iconName){
      return {isCustomIcon: true, index: index, name: customIconName};
    }else{
      return {isCustomIcon: false, name: iconName};
    }
  }

  function findIcon (left: string, right1: string, right2: string){
    const copyAppbarIcon: AppBarIconObj = {
      AppBarLeft: {
        isCustomIcon: false,
        name: ''
      },
      AppBarRight1: {
        isCustomIcon: false,
        name: ''
      },
      AppBarRight2: {
        isCustomIcon: false,
        name: ''
      }
    };

    for(let i=0; i < CustomIconList.length; i++){
      if(!copyAppbarIcon.AppBarLeft.isCustomIcon || !copyAppbarIcon.AppBarRight1.isCustomIcon || !copyAppbarIcon.AppBarRight2.isCustomIcon){

        if (!copyAppbarIcon.AppBarLeft.isCustomIcon) {
          copyAppbarIcon.AppBarLeft = getCustomIcon(CustomIconList[i].name, left, i);
        } 
        if (!copyAppbarIcon.AppBarRight1.isCustomIcon) {
          copyAppbarIcon.AppBarRight1 = getCustomIcon(CustomIconList[i].name, right1, i);
        } 
        if (!copyAppbarIcon.AppBarRight2.isCustomIcon) {
          copyAppbarIcon.AppBarRight2 = getCustomIcon(CustomIconList[i].name, right2, i);
        }
      }else{
        return copyAppbarIcon;
      }

    }
    return copyAppbarIcon;
  }


  return (
    <View
      style={{
        opacity,
      }}
    >
      <Appbar.Header
        style={{
          paddingTop: Platform.OS === 'web' && y === 0 ? 20 * originX : 0,
          backgroundColor: backgroundColor,
          ...Platform.select({
            web: {
              borderStyle: borderStyle === 'none' ? 'solid' : borderStyle,
              borderBottomColor: borderColor,
              borderBottomWidth: borderWidth,
            },
            ios: {
              borderRadius: 1,
            },
            android: {
              borderRadius: 1,
            },
          }),

          zIndex: zIndex,
        }}
      >
        {leftIcon && leftIcon?.enabled ? (
          <Appbar.Action
            color={color}
            icon={ 
              AppBarIcon.AppBarLeft.isCustomIcon === true
              ? () => <SvgComponent 
                      heigh={24} width={24}
                      icon={AppBarIcon.AppBarLeft.name}
                      color={'#fff'}
                    />
              :leftIcon.icon
              }
            onPress={() => {
              handlePress('leftIcon');
            }}
            animated={false}
          />
        ) : (
          <View style={{ width: iconAppBarWidth }} />
        )}

        {rightIcon1?.enabled && checkState()}

        <Appbar.Content
          titleStyle={{
            fontSize: +fontSize * originX,
            fontFamily: checkFont(fontFamily),
            // textAlign: 'center',
            // flex: 1,
            alignSelf: 'center',
          }}
          title={title?.text}
          color={color}
        />

        {rightIcon1?.enabled && !rightIcon2?.enabled ? checkState() : null}

        {rightIcon1 && rightIcon1.enabled && (
          <Appbar.Action
            color={color}
            icon={
              AppBarIcon.AppBarRight1.isCustomIcon === true
                ? () => <SvgComponent 
                          heigh={24} width={24}
                          icon={AppBarIcon.AppBarRight1.name}
                          color={'#fff'}
                        />
                : rightIcon1.icon
            }
            onPress={() => {
              handlePress('rightIcon1');
            }}
            animated={false}
          />
        )}

        {rightIcon2 && rightIcon2.enabled && (
          <Appbar.Action
            color={color}
            icon={
              AppBarIcon.AppBarRight2.isCustomIcon === true
                ? () => <SvgComponent
                          heigh={24} width={24}
                          icon={AppBarIcon.AppBarRight2.name}
                          color={'#fff'}
                        />
                : rightIcon2.icon
            }
            onPress={() => {
              handlePress('rightIcon2');
            }}
            animated={false}
          />
        )}
        {!rightIcon1?.enabled && !rightIcon2?.enabled ? checkState() : null}
        {Platform.OS !== 'web' && borderWidth > 0 && borderStyle !== 'none' && (
          <View
            style={{
              position: 'absolute',
              height: borderWidth,
              bottom: 0,
              overflow: 'hidden',
              width,
            }}
          >
            <View
              style={{
                ...Platform.select({
                  ios: {
                    borderRadius: 0,
                  },
                  android: {
                    borderRadius: borderStyle === 'solid' ? 0 : 0.001,
                  },
                  default: {
                    borderRadius: 0,
                  },
                }),
                borderColor: borderColor,
                borderStyle: borderStyle === 'none' ? 'solid' : borderStyle,
                borderWidth: borderWidth,
              }}
            ></View>
          </View>
        )}
      </Appbar.Header>
    </View>
  );
};

export default AppBar;
