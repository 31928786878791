import { StyleSheet } from 'react-native';
import { checkFont } from '../func';

const createStyles = (styleData: Record<string, any>) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 40,
      width: styleData.width,
      opacity: styleData.opacity,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor:
        styleData.type === 'contained'
          ? styleData.backgroundColor
          : styleData.color,
      borderRadius: styleData.borderRadius,
      borderWidth: 1,
      borderColor: '#01bf7c',
      position: 'relative',
      overflow: 'hidden',
    },
    text: {
      fontSize: styleData.fontSize,
      color:
        styleData.type === 'contained'
          ? styleData.color
          : styleData.backgroundColor,
      fontFamily: checkFont(styleData.fontFamily),
      fontWeight: styleData.styles?.fontWeight,
      opacity: styleData.opacity,
      textAlign: 'center',
    },
    errorContainer: {
      flexDirection: 'row',
      height: 60,
      borderRadius: styleData.borderRadius,
      opacity: styleData.opacity,
      textAlign: 'center',
      borderColor: '#000000',
      borderWidth: 2,
    },
    errorText: {
      padding: 5,
      margin: 'auto',
      fontSize: 16,
      color: '#ff0000',
      fontFamily: styleData.styles?.fontFamily,
      fontWeight: styleData.styles?.fontWeight,
    },
  });
};

export default createStyles;
