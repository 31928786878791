import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    icon: {
      width: attributes.width,
      height: attributes.height,
      margin: 0,
      zIndex: attributes.zIndex,
    },
  });
};

export default createStyles;
