export default {
  CAPTURE_ONLY_FOR_CARD_PAYMENT:
    'Authorization is only available when payment method is credit card',
  CAPTURE_ONLY_FOR_CREDIT_CARDS:
    'Authorization is only available when card type is credit card. Debit card and prepaid card can not be used for authorization',
  CARD_LIMIT_EXCEEDED_FOR_STORE:
    'Total amount by credit card within a certain period exceeded the configured limit',
  CHANGE_PROHIBITED: 'Change this field is prohibited',
  CHARGE_AMOUNT_TOO_HIGH:
    'Charge amount is greater than the maximum charge amount',
  CHARGE_AMOUNT_TOO_LOW:
    'Charge amount is lower than the minimum charge amount',
  DEPRECATED: 'This parameter is deprecated',
  EXPIRATION_DATE_OUT_OF_BOUNDS: 'Expiration is out of allowed range',
  FORBIDDEN_PARAMETER:
    'Permission to use this parameter is not granted, or the requirement is not satisfied',
  INSTALLMENT_ALREADY_SET:
    'Subscripiton is can not be change because it already started',
  INSTALLMENT_INVALID_PLAN_TYPE: 'Invalid installment plan type',
  INVALID_AMOUNT: 'Amount must be a number greater than 0',
  INVALID_CARD_BRAND: 'Unrecognized card brand',
  INVALID_CARD_DESCRIPTOR: 'Invalid descriptor format',
  INVALID_CARD_EXPIRATION: 'The card expired',
  REFUND_NOT_WITHIN_BOUNDS:
    'Refund amount is not between 1 to the charge amount inclusive',
  INVALID_CARD_NUMBER: 'Invalid card number format',
  INVALID_CHARGE_STATUS: 'Invalid charge status',
  INVALID_CURRENCY: 'Unrecognized currency',
  INVALID_CVV: 'CVV must be between 3 to 5 digits',
  INVALID_FORMAT: 'Invalid format cardholder',
  INVALID_FORMAT_COUNTRY: 'Unrecognized country code',
  INVALID_FORMAT_CURRENCY: 'Unrecognized currency code',
  INVALID_FORMAT_DOMAIN: 'Invalid domain format',
  INVALID_FORMAT_EMAIL: 'Invalid email address format',
  INVALID_LANGUAGE: 'Unrecognized language code',
  INVALID_PHONE_NUMBER: 'Invalid phone number format',
  INVALID_SCHEDULED_CAPTURE_DATE:
    'Capture date must be after 1 hour from now and within 7 days',
  INVALID_TIME_PERIOD: 'Unrecognized time period format',
  MUST_BE_FUTURE_TIME: 'It must be future time',
  MUST_BE_LOWER_THAN_FULL_AMOUNT: 'It must be lower than the full amount',
  MUST_BE_MONTH_BASED_TO_SET: 'Period must be monthly',
  NESTED_JSON_NOT_ALLOWED: 'Nested JSON format is not supported',
  NOT_SUPPORTED_BY_PROCESSOR: 'It is not supported by the gateway',
  ONLY_FOR_CARD_PAYMENT:
    'This is available when the payment method is credit card',
  ONLY_JAPANESE_PHONE_NUMBER_ALLOWED: 'Only Japanese phone number is supported',
  REQUIRED_VALUE: 'Required value',
  INVALID_FORMAT_LENGTH: 'Cardholder must be between 2 to 30 digits',
  NO_TEST_CARD_IN_LIVE_MODE: 'No test card in live mode',
};
