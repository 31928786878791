import { createBrowserHistory, createMemoryHistory } from 'history';
import { Platform } from 'react-native';

function history() {
  if (Platform.OS == 'web') {
    return createBrowserHistory();
  } else {
    return createMemoryHistory();
  }
}

export default history();
