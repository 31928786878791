import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { IPage, IRecord } from '@common/types/index';
import { Platform } from 'react-native';
import api from '@common/configs/api';
import {
  loadPages,
  loadDevelopPlugin,
  setHaveDevelopPlugin,
} from '@common/redux/slice/page';
import { useHistory, useLocation } from '@common/routes/hooks';
import qs from 'query-string';
import { pagesSelector } from '@common/redux/selectors/page';
import { appInfoSelector } from '@common/redux/selectors/app';
import { authSelector } from '@common/redux/selectors/auth';
import { getDataSourceStore } from '@common/redux/selectors/database';
import { checkAuth, checkAuthError } from '@common/redux/slice/auth';
import { AUTHEN_KEY } from '@common/utils/handleActions/func/func';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PLUGIN_WRAPPER_KEY, loadPlugins } from '@common/utils/plugin';
import { handlePluginsSocket } from '@common/configs/plugin-socket';
import { find, get, isEmpty, pick, some } from 'lodash';
import { getMapComponent } from '@common/utils/functions';

type APIResponse = {
  data: any;
  error: string | null;
  isLoading: boolean;
  isUseMap: boolean;
};

const loadProductionPages = async (appId: string, params: object) => {
  try {
    const res: AxiosResponse<{ screens: Array<IPage> }> = await api({
      method: 'get',
      url: `v2/apps/${appId}/versions/latest`,
      params,
    });
    return res?.data?.screens || (await loadDevPages(appId, params));
  } catch (error) {
    return await loadDevPages(appId, params);
  }
};

const loadDevPages = async (appId: string, params: object) => {
  const res: AxiosResponse<Array<IPage>> = await api({
    method: 'get',
    url: `v2/share/${appId}/screens`,
    params,
  });
  return res?.data || [];
};

export const getAllPages = (appId: string, debug?: boolean) => {
  const dispatch = useDispatch();
  const appInfo = useSelector(appInfoSelector);
  const [response, setResponse] = useState<APIResponse>({
    data: [],
    error: null,
    isLoading: false,
    isUseMap: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      setResponse({ ...response, ...{ isLoading: true } });
      try {
        const appMetadata = JSON.parse(appInfo?.metadata || '{}');
        const params = {
          hasCache: appMetadata?.hasCache,
        };
        const pages = debug
          ? await loadDevPages(appId, params)
          : await loadProductionPages(appId, params);

        if (Platform.OS === 'web') {
          const plugins = pages.map((screen: any) => {
            const metadata = JSON.parse(screen.metadata);
            return metadata
              .filter((component: any) => component.componentUrl)
              .map((component: any) => ({
                url: component.componentUrl,
                name: component.componentCode,
              }));
          });
          const isHaveDevelopPlugin = pages.some((screen: any) => {
            const metadata = JSON.parse(screen.metadata);
            return metadata.some((component: any) => !component.componentUrl);
          });
          dispatch(setHaveDevelopPlugin({ isHaveDevelopPlugin }));
          if (isHaveDevelopPlugin) {
            dispatch(loadDevelopPlugin({ isDevelopPluginLoading: true }));
            handlePluginsSocket(appInfo?.ownerId, () => {
              dispatch(loadDevelopPlugin({ isDevelopPluginLoading: false }));
            });
          }
          await Promise.all(
            loadPlugins(PLUGIN_WRAPPER_KEY.INSTALLED_PLUGINS, plugins.flat())
          );
        }

        dispatch(loadPages(pages));
        const useLocationComponennts = getMapComponent(pages);
        setResponse({
          ...response,
          ...{
            isLoading: false,
            data: pages,
            isUseMap: !isEmpty(useLocationComponennts),
          },
        });
      } catch (error: any) {
        setResponse({
          ...response,
          ...{ isLoading: false, error: error.message || 'get data error' },
        });
      }
    };
    if (appInfo?.ownerId) {
      fetchData();
    }
  }, [appInfo?.ownerId]);

  return response;
};

export const getCurrentPage = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const { search } = useLocation();

  const query = qs.parse(search);

  const pages = useSelector(pagesSelector);

  const { isAuth, profile } = useSelector(authSelector);

  const isLogged = isAuth && !isEmpty(profile);

  const currentPageInfo = useMemo(
    () =>
      pages.find((o) => {
        return (
          o.screenId.toString() == get(query, 'target', null) ||
          o.screenUuid == query?.target
        );
      }),
    [query]
  );
  const currentModalPage = useMemo(
    () =>
      pages.find((o) => {
        return (
          o.screenId.toString() == get(query, 'targetModal', null) ||
          o.screenUuid == query?.targetModal
        );
      }),
    [query]
  );
  const appInfo = useSelector(appInfoSelector);

  const pageConfig = appInfo?.metadata
    ? JSON.parse(appInfo?.metadata)
    : undefined;

  const tableId = get(pageConfig, 'auth.tableId', null);

  const dataSource = useSelector(getDataSourceStore);

  const data = dataSource[tableId] || [];

  const checkValidScreen = useCallback(
    (screenUuid: string | any) => {
      const isValid = some(pages, { screenUuid });

      if (isValid) return screenUuid;
      else {
        return pages[0].screenUuid;
      }
    },
    [pages]
  );

  // check auth
  useEffect(() => {
    setLoading(true);
    AsyncStorage.getItem(AUTHEN_KEY)
      .then(
        (keyValue: string | any) => {
          const userStorage = JSON.parse(keyValue);

          if (isEmpty(userStorage) && isEmpty(data)) {
            dispatch(checkAuthError());
          }

          const findUser: IRecord | any = find(data, [
            '_id',
            userStorage?.userId,
          ]);

          if (isEmpty(findUser)) {
            dispatch(checkAuthError());
          } else {
            dispatch(checkAuth(userStorage));
            dispatch(
              checkAuth({
                ...findUser.record,
                userId: findUser._id,
                ...pick(findUser, ['createdAt', 'updatedAt']),
              })
            );
          }
        },
        () => {
          dispatch(checkAuthError());
        }
      )
      .then(() =>
        setTimeout(() => {
          setLoading(false);
        }, 500)
      );
  }, [data]);

  useEffect(() => {
    let nextScreen: any;
    const { active, startHomePage } = pageConfig;

    if (isLogged) {
      nextScreen = active;
    } else {
      nextScreen = startHomePage;
    }

    history.push({
      search: qs.stringify({
        ...query,
        ...{ target: checkValidScreen(nextScreen) },
      }),
    });
  }, [isLogged]);

  return {
    currentPage: currentPageInfo,
    loading,
    currentModalPage,
    query,
  };
};
