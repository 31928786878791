import React, { useState, FC, useEffect } from 'react';
import { View } from 'react-native';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { useBinding } from '@common/hooks/useBinding';
import { get } from 'lodash';
import { WebView } from 'react-native-webview';
import createStyles from './style';

const Youtube: FC = (attributes: any) => {
  const [videoIdPlay, setVideoIdPlay] = useState('');
  const styles = createStyles(attributes);
  const { handleBindingField } = useBinding();
  const getTableId = get(attributes, 'selectedItem.tableId');
  const currentRecord = useSelector(getCurrentRecord) || {};

  useEffect(() => {
    const url = handleBindingField(
      attributes?.url || '',
      attributes.record || currentRecord[getTableId],
      attributes?.selectedItem?.itemIndex
    );

    if (url) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      const videoId = match && match[7].length === 11 ? match[7] : '';
      setVideoIdPlay(videoId);
    }
  }, [currentRecord]);

  if (Platform.OS === 'web') {
    return (
      <View style={styles.container}>
        <iframe
          title="PMU"
          src={`https://www.youtube.com/embed/${videoIdPlay}?autoplay=${
            attributes.isAutoPlay ? 1 : 0
          }&origin=${origin}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            borderRadius: attributes.borderRadius,
            height: '100%',
            width: '100%',
          }}
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <WebView
        javaScriptEnabled={true}
        scrollEnabled={false}
        allowsFullscreenVideo={true}
        allowsInlineMediaPlayback={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        userAgent="Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1"
        source={{
          uri: `https://www.youtube.com/embed/${videoIdPlay}?&autoplay=1&showinfo=0&controls=1&fullscreen=1`,
        }}
        style={{
          width: attributes.width,
          height: attributes.height,
        }}
      />
    </View>
  );
};

export default Youtube;
