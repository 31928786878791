import { BINDING_SELECTOR_TYPE } from '@common/constants/shared';
import store from '@common/redux/store';
import { bindingType } from '@common/utils/functions';
import { find, findIndex, get, isEmpty, map, uniq } from 'lodash';

const uniqRelationship = (idRelations: any[], currentRecord: any) => {
  return map(uniq(idRelations) || [], (item: string) => {
    const index = findIndex(currentRecord, { _id: item });

    if (index !== -1) return currentRecord[index];
  }).filter((a) => a);
};

const getRecordNestedList = (
  selectorType: string,
  currentParent: any,
  initialValue: any
) => {
  const state: any = store.getState();
  const authProfile = state.auth.profile;

  switch (selectorType) {
    case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
      return authProfile;

    case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
      return initialValue;

    default:
      return currentParent;
  }
};

export const getValueNestedList = (
  source: any,
  listTable: any,
  selectorType: string | any,
  initialValue: any
) => {
  const currentParent = getRecordNestedList(
    selectorType,
    source?.currentParent,
    initialValue
  );

  const fieldId = source?.fieldId;
  const sourceFlat = source?.source;
  const currentValue =
    get(currentParent, sourceFlat.fieldId) ||
    get(currentParent, `record.${sourceFlat?.fieldId}`);
  const currentRecord = listTable[source?.tableId];
  const recordRelation = listTable[sourceFlat?.tableId];

  switch (source?.source?.type) {
    case bindingType.DATA:
      if (isEmpty(currentParent) || isEmpty(fieldId)) return [];

      const fieldRelation = currentParent?.record
        ? currentParent?.record[fieldId]
        : currentParent[fieldId];

      if (isEmpty(fieldRelation)) return [];

      const recordChild = listTable[source?.tableId] || [];

      return uniqRelationship(fieldRelation, recordChild);

    case bindingType.BE_LONG_RELATIONSHIP:
      const recordBelongto = find(recordRelation, { _id: currentValue });

      if (isEmpty(recordBelongto?.record)) return [];

      const idRelations = recordBelongto.record[source?.fieldId];

      return uniqRelationship(idRelations, currentRecord);

    default:
      if (!currentValue) return [];

      const listIdRelation = map(currentValue, (item: string) => {
        const index = findIndex(recordRelation, { _id: item });

        if (index !== -1) {
          const recordObj = recordRelation[index];
          return get(recordObj, `record.${source?.fieldId}`);
        }
      })
        .filter((a) => a)
        .flat(1);

      return uniqRelationship(listIdRelation, currentRecord);
  }
};
