import { theme } from '@common/configs/theme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: theme.colors.primary,
    borderRadius: 8,
  },
  appLogo: { width: 20, height: 20 },
});
