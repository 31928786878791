import React, { useRef } from 'react';
import SignatureScreen from 'react-signature-pad-wrapper';
import { Button } from 'antd';
import { isEmpty } from 'lodash';

import { ISignaturePad } from '@nocode/types';
import { parseText } from '../func';

const SignaturePad: React.FC<ISignaturePad> = (props) => {
  const { attributes, dataBinding, onPress, changeInput, valueInputs } = props;

  const {
    borderColor,
    borderWidth,
    borderRadius,
    borderStyle,
    backgroundColor,
    clearButton,
    saveButton,
    penColor,
  } = attributes;

  const {
    borderStyle: clearBorderStyle,
    borderRadius: clearBorderRadius,
    backgroundColor: clearBackgroundColor,
    borderColor: clearBorderColor,
    borderWidth: clearBorderWidth,
    fontFamily: clearFontFamily,
    color: clearColor,
    text: clearText,
  } = clearButton;

  const {
    borderStyle: saveBorderStyle,
    borderRadius: saveBorderRadius,
    backgroundColor: saveBackgroundColor,
    borderColor: saveBorderColor,
    borderWidth: saveBorderWidth,
    fontFamily: saveFontFamily,
    color: saveColor,
    text: saveText,
  } = saveButton;

  const ref = useRef<any>();

  return (
    <>
      <div
        style={{
          backgroundColor,
          borderColor,
          borderWidth,
          borderStyle,
          borderRadius,
        }}
      >
        <SignatureScreen ref={ref} options={{ penColor }} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 15,
        }}
      >
        <Button
          style={{
            width: '45%',
            height: 40,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: clearBackgroundColor,
            borderRadius: clearBorderRadius,
            borderStyle: clearBorderStyle,
            borderWidth: clearBorderWidth,
            borderColor: clearBorderColor,
            color: clearColor,
            fontFamily: clearFontFamily,
          }}
          onClick={() => ref.current?.clear()}
        >
          {parseText(dataBinding?.clearButton.text || clearText) || ' '}
        </Button>
        <Button
          onClick={() => {
            // console.log(ref.current?.toData());
            changeInput(ref.current?.toDataURL());
            onPress && onPress();
            ref.current?.clear();
          }}
          loading={!isEmpty(valueInputs)}
          style={{
            width: '45%',
            height: 40,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: saveBackgroundColor,
            borderRadius: saveBorderRadius,
            borderStyle: saveBorderStyle,
            borderWidth: saveBorderWidth,
            borderColor: saveBorderColor,
            color: saveColor,
            fontFamily: saveFontFamily,
          }}
        >
          {parseText(dataBinding?.saveButton.text || saveText) || ' '}
        </Button>
      </div>
    </>
  );
};
export default React.memo(SignaturePad);
