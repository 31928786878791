import React, { memo, useEffect } from 'react';
import { View } from 'react-native';

type Props = {
  adUnitID: string;
  onCallback: VoidFunction;
  handleError: (error: string) => void;
};

const CustomAdmobInterstitial: React.FC<Props> = ({
  adUnitID,
  onCallback,
  handleError,
}) => {
  const { AdMobInterstitial } = require('react-native-admob');
  useEffect(() => {
    AdMobInterstitial.setTestDevices([AdMobInterstitial.simulatorId]);
    AdMobInterstitial.setAdUnitID(adUnitID);

    AdMobInterstitial.addEventListener('adClosed', () => {
      onCallback();
    });

    AdMobInterstitial.requestAd()
      .then(() => {
        AdMobInterstitial.showAd();
      })
      .catch((error: any) => {
        handleError(error.message || 'Error when request AdMobInterstitial');
      });
    return () => {
      AdMobInterstitial.removeAllListeners();
    };
  }, [adUnitID]);

  return <View></View>;
};

export default memo(CustomAdmobInterstitial);
