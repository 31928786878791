import { BINDING_SELECTOR_TYPE, pageSizeDefault } from '@common/constants';
import { authSelector } from '@common/redux/selectors/auth';
import {
  getCurrentRecord,
  getDataSourceStore,
} from '@common/redux/selectors/database';
import { IPaginateOptions } from '@common/types';
import { IRecord } from '@common/types/database';
import { getBindingRecord } from '@common/utils/handleBinding';
import { paginate } from '@common/utils/handleBinding/function';
import { ceil, find, get, isEmpty, isNil, isUndefined, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { handlePermissonRecord } from './usePermission';

const flatSource: any = (params: Record<string, any>) => {
  if (isEmpty(params) || isNil(params)) return;

  if (params?.source?.selector) {
    return params;
  } else {
    return flatSource(params.source);
  }
};

const useFilter = (
  records: IRecord[],
  source: any,
  {
    filter,
    sort,
    maximum,
    ObjectBinding,
    databaseUuid,
    collection,
    auth,
    obj,
  }: {
    filter: any[];
    sort: any;
    maximum: number;
    ObjectBinding: any;
    databaseUuid: string;
    collection: Record<string, any>;
    auth: Record<string, any>;
    obj?: any;
  }
) => {
  const currentRecord = useSelector(getCurrentRecord) || {};
  const dataSource = useSelector(getDataSourceStore);
  const { profile } = useSelector(authSelector);

  const objectBindingMemo = useMemo(
    () =>
      Object.entries(ObjectBinding).reduce(
        (a: any, [k, v]: any) => (v ? ((a[k] = v), a) : a),
        {}
      ),
    [ObjectBinding]
  );

  const handleRecord = (records: any[], source: any) => {
    const getSource = flatSource(source);
    const selectorType = get(getSource, 'source.selector.type');

    if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
      const table = get(dataSource, getSource.tableId);

      const users = get(dataSource, getSource.tableId) || [];
      const userLogged = find(users, { _id: auth?.userId }) || {};

      if (!table) return [];

      const userIds: Record<string, any> = [];

      table.forEach((item: Record<string, any>) => {
        if (isUndefined(get(userLogged, getSource.fieldId))) {
          return;
        }
        if (get(userLogged, getSource.fieldId).includes(item._id)) {
          userIds.push(item._id);
        }
      });

      if (isEmpty(userIds)) return [];

      return records.filter(
        (item: Record<string, any>) =>
          userIds.includes(get(item, `record.${source.fieldId}`)) ||
          userIds.includes(get(item, '_id'))
      );
    }

    return [];
  };

  const filterDataSource = (
    records: any[],
    source: any,
    currentRecord: any
  ) => {
    if (!records) return [];

    // const selectorListId = get(getSource, 'source.selector.listObjectId');
    let currentId, currentParent;

    if (obj) {
      currentId = get(obj, 'attributes.items.id', undefined);
      //Note: config to filter nomal list (not list in list)
      if (obj?.record && obj?.record?._id) {
        currentParent = obj?.record;
      }
    }

    if (!source?.source) return records;

    const valueBinding =
      getBindingRecord(
        ['list'].includes(source?.dataType)
          ? { ...source, currentParent }
          : source,
        {},
        currentRecord,
        undefined,
        undefined,
        currentId
      ) || [];

    if (!valueBinding) return [];

    return valueBinding;
  };

  const paginationOptions: IPaginateOptions = useMemo(() => {
    const defaultOptions = {
      paginate: false,
      pageSize: pageSizeDefault,
    };

    switch (obj?.type) {
      //Custom list
      case 'list':
        return get(obj, 'dataBinding.source.options') || defaultOptions;

      //Library list && Dropdown
      case 'select':
      case 'libraryComponent':
        return get(obj, 'attributes.items.source.options') || defaultOptions;

      //Table
      default:
        return {
          pageSize: get(obj, 'pagination.pageSize') || pageSizeDefault,
          paginate: true,
        };
    }
  }, [obj]);

  const [page, setPage] = useState<number>(1);

  const fetchRecords = (resultHandleRecord: any) => {
    const initRecord: IRecord[] | any[] = !isEmpty(resultHandleRecord)
      ? resultHandleRecord
      : filterDataSource(records, source, currentRecord);
    const recordsData = initRecord.map((item) =>
      item?.record
        ? {
            _id: item._id,
            ...item.record,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
          }
        : item
    );

    return paginate(recordsData, {
      maximum,
      sort,
      filterOptions: filter,
      databaseUuid,
      ObjectBinding,
    });
  };

  const responseFilter = useMemo(() => {
    let response = {
      records: [],
      total: 0,
      totalPage: 1,
      loadingFilter: true,
      initializeList: page == 1 ? true : false,
      error: '',
    };

    if (isEmpty(records)) {
      return { ...response, initializeList: false };
    }

    const pageSize = paginationOptions.pageSize || pageSizeDefault;
    const pageNumber = page || 1;
    const totalItemCurrent = pageNumber * pageSize;

    const resultHandleRecord = handleRecord(records, source);
    const paginateResponse = fetchRecords(resultHandleRecord);
    const recordPaginate = paginationOptions.paginate
      ? paginateResponse.slice(0, totalItemCurrent)
      : paginateResponse;

    //return value
    return {
      ...response,
      records: recordPaginate,
      total: paginateResponse.length,
      totalPage: !paginationOptions.paginate
        ? 1
        : ceil(paginateResponse.length / pageSize),
      loadingFilter: false,
      initializeList: false,
      error: '',
    };
  }, [
    records,
    filter,
    maximum,
    page,
    paginationOptions,
    currentRecord,
    objectBindingMemo,
    profile,
  ]);

  const onLoadMoreFilter = (pageNumber: number) => setPage(pageNumber);

  return {
    ...responseFilter,
    records: handlePermissonRecord({
      response: uniqBy(responseFilter?.records, '_id').filter(
        (item) => !isEmpty(item)
      ),
      collection,
    }),
    onLoadMoreFilter,
  };
};

export default useFilter;
