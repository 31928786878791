import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApp } from '@common/types';

type State = {
  app?: IApp;
};

const initialState: State = {};

const appSlice = createSlice({
  name: 'appInfo',
  initialState: initialState,
  reducers: {
    loadApp: (state: State, action: PayloadAction<IApp>) => {
      if (action.payload) {
        state.app = action.payload;
      }
    },
  },
});

export const { loadApp } = appSlice.actions;
export default appSlice.reducer;
