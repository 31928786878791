import { get, pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any, Platform: any, isEmptyUrl: boolean) => {
  const hasPlaceholder = get(attributes, 'imageUrl.binding.options', {});

  const getShadow = () => {
    if (!attributes.shadow) return {};
    return {
      shadowColor: attributes.shadow.color,
      shadowRadius: attributes.shadow.size,
      shadowOpacity: attributes.shadow.blur || 1,
      shadowOffset: {
        width: attributes.shadow.x,
        height: attributes.shadow.y,
      },
    };
  };
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'width',
        'height',
        // 'borderStyle',
        'borderRadius',
        'zIndex',
      ]),
      ...getShadow(),
      zIndex: attributes.zIndex,
      ...(isEmptyUrl && !hasPlaceholder?.placeholderImageEnabled
        ? { borderWidth: 0 }
        : attributes.borderStyle !== 'none'
        ? {
            ...pick(attributes, ['borderWidth', 'borderColor']),
            borderStyle: attributes.borderStyle,
            borderRadius:
              attributes.borderRadius === 0 ? 1 : attributes.borderRadius,
          }
        : { borderWidth: 0 }),
    },
    wrapper: {},
    img: {
      ...pick(attributes, ['borderRadius', 'opacity']),
      resizeMode: attributes.imageResize,
      width: '100%',
      height: '100%',
    },
  });
};

export default createStyles;
