import { StyleSheet, Platform } from 'react-native';

const createStyles = (appInfor?: any) => {
  const isPreview = appInfor?.platform === 'mobile';
  const isWeb = appInfor?.platform === 'web';
  const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

  return StyleSheet.create({
    wrapper: {
      height: '100%',
      width: '100%',
      backgroundColor: '#fff',
      paddingTop: isPreview || isMobile ? 100 : 0,
      paddingLeft: 15,
      paddingRight: 15,
      ...(isWeb && { maxWidth: 520 }),
      justifyContent: isPreview || isMobile ? 'flex-start' : 'center',
    },

    email: {
      color: '#000',
      fontSize: 12,
      fontWeight: '600',
    },

    title: {
      marginBottom: 8,
      fontWeight: '600',
      fontSize: 24,
    },

    desc: {
      color: '#777777',
      fontSize: 14,
      marginBottom: 32,
    },

    text: {
      marginBottom: 4,
      fontSize: 12,
      fontWeight: '400',
    },

    input: {
      backgroundColor: '#fff',
      borderRadius: 4,
      borderWidth: 1,
      borderColor: '#ddd',
      padding: 10,
      color: '#092636',
    },

    error: {
      marginBottom: 10,
      fontSize: 14,
      color: '#ff4d4f',
      marginTop: 5,
    },
  });
};

export default createStyles;
