import React, { useEffect } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { ILineButton } from '@nocode/types';
import createStyles from '@nocode/components/LineLogin/style';
import LineIcon from '@nocode/components/LineLogin/LineIcon';
import AsyncStorage from '@react-native-async-storage/async-storage';

const LineLogin: React.FC<ILineButton> = ({ attributes, onPress, actions }) => {

  const { text, socialConfig, textError, successActions, failedActions, isCheckFriendship } = attributes;
  const styles = createStyles(attributes);
  const textOptions = Array.isArray(text) ? text[0] : text;
  const currentUrl = window?.location?.href?.toString();

  if (
    !currentUrl?.includes('canvas') &&
    (!socialConfig || !socialConfig.clientId || !socialConfig.clientSecret)
  ) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{textError?.en}</Text>
      </View>
    );
  }

  useEffect(() => {
    const handlePress = async () => {
      if (successActions && failedActions && actions) {
        await AsyncStorage.setItem(
          'lineClickFlows',
          JSON.stringify({
            successActions: actions[successActions.actionId]?.actions,
            failedActions: actions[failedActions.actionId]?.actions,
          })
        );
      }
      await AsyncStorage.setItem(
        'isCheckFriendship',
        JSON.stringify({
          isCheckFriendship: isCheckFriendship,
        })
      );
    };
    handlePress();
  }, []);

  return (
    <>
      <TouchableHighlight onPress={onPress}>
        <View style={styles.container}>
          <LineIcon />
          <Text style={styles.text}>{textOptions}</Text>
        </View>
      </TouchableHighlight>
    </>
  );
};

export default LineLogin;
