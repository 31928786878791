import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Image,
  ImageSourcePropType,
  Platform,
  Text,
  View,
} from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useSelector } from 'react-redux';

import CustomAdmobInterstitial from '@common/components/Admob/CustomAdmobInterstitial';
import CustomAdmobReward from '@common/components/Admob/CustomAdmobReward';
import createStyles from '@common/components/Admob/style';
import Loading from '@common/components/Loading';
import {
  AdmobBannerSize,
  ADMOB_IMAGE_BANNER,
  ADMOB_IMAGE_BANNER_FOR_MOBILE,
  bannerFullScreen,
} from '@common/constants';
import { admobSelector } from '@common/redux/selectors/admob';

const getTextAttr = (attr: any): string | null => {
  if (typeof attr === 'string') return attr;
  if (Array.isArray(attr)) return attr.length ? attr.join('') : null;
  return null;
};

const bannerSizeCallback: Array<AdmobBannerSize> = bannerFullScreen;

const Admob: FC = (props: any) => {
  const {
    iosAdID: _iosAdID,
    androidAdID: _androidAdID,
    bannerSize,
    placeholder,
    onPress,
    actions,
    attributes,
  } = props;

  const styles = createStyles(props);

  const { androidAppId, iosAppId, isUseAdmob } = useSelector(admobSelector);
  let succeed = false;

  if (!isUseAdmob) {
    return <View></View>;
  }

  const onSuccess = useCallback(() => {
    succeed = true;
    if (
      bannerSizeCallback.includes(bannerSize) &&
      actions &&
      attributes?.onSuccess
    ) {
      if (attributes?.onSuccess?.action) {
        return onPress('onSuccess');
      }
    } else {
      return onPress();
    }
  }, [bannerSize, actions, attributes]);

  const onFailure = useCallback(() => {
    const isValid = !!(
      bannerSizeCallback.includes(bannerSize) &&
      actions &&
      !succeed &&
      attributes?.onFailure?.action
    );
    if (isValid) {
      return onPress('onFailure');
    }
  }, [bannerSize, actions, attributes]);

  const [adUnitID, setAdUnitID] = useState('');
  const [error, setError] = useState<string>();
  const [errorImage, setErrorImage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [iosAdID, androidAdID] = [
    getTextAttr(_iosAdID),
    getTextAttr(_androidAdID),
  ];

  const imageBannerComponent = useMemo(() => {
    const textPreview =
      'See how this component fits the design of your app in the preview. Disable to hide placeholder from Share URL and PWA.';

    const sourceImage =
      Platform.OS === 'web'
        ? {
            uri:
              ADMOB_IMAGE_BANNER[bannerSize as AdmobBannerSize] ||
              ADMOB_IMAGE_BANNER.banner,
          }
        : ADMOB_IMAGE_BANNER_FOR_MOBILE[bannerSize as AdmobBannerSize] ||
          ADMOB_IMAGE_BANNER_FOR_MOBILE.banner;

    return (
      <Image
        style={{ width: '100%', height: '100%' }}
        resizeMode={'contain'}
        resizeMethod={'scale'}
        source={sourceImage as ImageSourcePropType}
        onLoad={() => {
          setIsLoading(false);
          setErrorImage('');
        }}
        onError={({ nativeEvent: { error } }) => {
          console.error('Error when load image:', error);
          setIsLoading(false);
          setErrorImage(textPreview);
        }}
      />
    );
  }, [bannerSize]);

  const webPreview = useMemo(() => {
    if (placeholder) {
      return (
        <View style={styles.wrapper}>
          {errorImage ? (
            <View style={styles.errorView}>
              <Text>{errorImage}</Text>
            </View>
          ) : (
            <View></View>
          )}
          {isLoading && !errorImage ? <Loading size="large" /> : <View></View>}
          {!errorImage && imageBannerComponent}
        </View>
      );
    }
    return <View></View>;
  }, [placeholder, isLoading, error, styles]);

  if (Platform.OS === 'web') {
    return webPreview;
  }

  useEffect(() => {
    if (
      (Platform.OS === 'ios' && !(iosAppId && iosAdID)) ||
      (Platform.OS === 'android' && !(androidAppId && androidAdID))
    ) {
      const platformPretty = Platform.OS === 'ios' ? 'iOS' : 'Android';
      setError(
        `Component Setup Incomplete. ${platformPretty} App ID or ${platformPretty} Ad ID was left blank.`
      );
    } else {
      if (Platform.OS === 'ios' && iosAdID) {
        setAdUnitID(iosAdID.replace(/\s/g, ''));
      } else if (Platform.OS === 'android' && androidAdID) {
        setAdUnitID(androidAdID.replace(/\s/g, ''));
      }
    }
  }, [iosAppId, androidAppId, iosAdID, androidAdID, Platform.OS]);

  const handlerError = useCallback((error?: string) => {
    if (error) {
      setError(error);
      Alert.alert('Error when load admob', error);
    }
  }, []);

  const renderAdMob = useMemo(() => {
    if (!adUnitID) {
      return <View></View>;
    }
    if (bannerSize === 'rewarded') {
      return (
        <CustomAdmobReward
          adUnitID={adUnitID}
          onCallback={() => {
            setIsLoading(false);
            setError('');
            onSuccess();
          }}
          onAdClosed={() => {
            onFailure();
          }}
          handleError={(error) => {
            handlerError(error);
            onFailure();
          }}
        />
      );
    }
    if (bannerSize === 'interstitial') {
      return (
        <CustomAdmobInterstitial
          adUnitID={adUnitID}
          onCallback={() => {
            setIsLoading(false);
            setError('');
            onSuccess();
          }}
          handleError={(error) => {
            handlerError(error);
            onFailure();
          }}
        />
      );
    }
    const { AdMobBanner } = require('react-native-admob');
    return (
      <AdMobBanner
        adSize={bannerSize}
        adUnitID={adUnitID}
        onAdFailedToLoad={(error: any) => {
          setIsLoading(false);
          setError(error?.message || 'Error when load AdMobBanner');
          console.error(error);
        }}
        onAdLoaded={() => {
          setIsLoading(false);
          setError('');
        }}
        onAdLeftApplication={() => {
          onPress && onPress();
        }}
        testDevices={[AdMobBanner.simulatorId]}
      />
    );
  }, [bannerSize, adUnitID, onFailure, onSuccess]);

  const renderLoading = useMemo(() => {
    return isLoading && !error ? (
      <View style={styles.borderView}>
        <ActivityIndicator />
      </View>
    ) : null;
  }, [isLoading, error]);

  const renderError = useMemo(() => {
    return error ? (
      <View style={[styles.imageMobile, styles.errorView]}>
        {imageBannerComponent}
        {/* <Text style={styles.errorText}>{error}</Text> */}
      </View>
    ) : null;
  }, [error]);

  return (
    <View style={styles.wrapper}>
      {renderError}
      {renderLoading}
      {renderAdMob}
    </View>
  );
};

export default Admob;
