import { BINDING_SELECTOR_TYPE, DATE_FORMAT } from '@common/constants/';
import { authSelector } from '@common/redux/selectors/auth';
import {
  getCurrentRecord,
  getListDatabases,
} from '@common/redux/selectors/database';
import { externalCollectionSelector } from '@common/redux/selectors/externalCollection';
import { getValueFields } from '@common/redux/selectors/formInput';
import { changeValue } from '@common/redux/slice/formInput';
import { IDatabaseColumn, ISelect } from '@common/types/';
import { getTextBinding, handleGetValue } from '@common/utils/handleBinding';
import { flattenObj, isRelation } from '@common/utils/handleBinding/function';
import { excuteRoundRelationship } from '@common/utils/handleBinding/helps';
import { find, get, isEmpty, isNil, isUndefined, map } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type useDropdownProps = {
  showDropDown: boolean;
  value: any;
  list: any[];
  handleShowDropdown: () => void;
  handleHiddenDropdown: () => void;
  handleChangeValue: (value: any) => any;
};

const useDropdown = (attributes: ISelect) => {
  const dispatch = useDispatch();
  const objectBinding = useSelector(getValueFields);

  const valueInputs = useMemo(
    () => get(objectBinding, `${attributes?.id}`, ''),
    [objectBinding]
  );

  const { profile: authProfile } = useSelector(authSelector);
  const fieldTable = useSelector(getListDatabases);
  const currentRecord = useSelector(getCurrentRecord);
  const { externalRecord } = useSelector(externalCollectionSelector);

  const getTableId = get(attributes, 'selectedItem.tableId', '');
  const getDatabaseUuid = get(attributes, 'databaseUuid');
  const getLabelField = get(attributes, 'database.options.labelField');
  const isMakeCustom = get(attributes, 'seperate.enabled', false);

  const isCurrentRecord = useMemo(
    () =>
      get(currentRecord, getTableId)
        ? get(currentRecord, getTableId)
        : get(currentRecord, getDatabaseUuid)
        ? get(currentRecord, getDatabaseUuid)
        : get(attributes, 'record', {}),
    [attributes, currentRecord, getTableId, getDatabaseUuid]
  );

  const getFieldTable = find(
    fieldTable,
    (item) => item.databaseUuid === attributes.databaseUuid
  );

  const getFieldId: IDatabaseColumn | any = find(
    getFieldTable?.fields,
    (item) => item.fid === getLabelField
  );

  const [showDropDown, setShowDropDown] = useState(false);

  const handleShowDropdown = () => setShowDropDown(true);
  const handleHiddenDropdown = () => setShowDropDown(false);

  const formatLabel = (value: any) => {
    if (isNil(value) && typeof value !== 'boolean') return;

    if (isUndefined(getFieldId))
      return moment(value).format(DATE_FORMAT.DATE_LABEL_FORMAT);

    switch (getFieldId?.type) {
      case 'dateOnly':
        return moment(value).format(DATE_FORMAT.DATE_ONLY);

      case 'date':
        return moment(value).format(DATE_FORMAT.DATE_LABEL_FORMAT);

      case 'boolean':
        return value.toString();

      default:
        return value;
    }
  };

  const optionDataOfDatabase = useMemo(() => {
    const tableId =
      get(attributes, 'attributes.database.idCollectionSelected') ||
      get(attributes, 'database.source.tableId');

    const defaultValue =
      get(attributes, 'attributes.defaultValue.text') ||
      get(attributes, 'database.options.labelField');

    if (!tableId) return [];

    if (getFieldTable && getFieldTable.isThirdParty) {
      return map(attributes.records || [], (value: any, index: number) => {
        const field = getFieldId.key.replace('[0].', '');
        return {
          value: index,
          label: formatLabel(value[field]),
        };
      }).filter(
        (item: Record<string, any>) =>
          !isNil(item.label) || !isEmpty(item.label)
      );
    }

    return map(attributes.records || [], (value: any) => {
      return {
        value: value._id,
        label: formatLabel(value?.[defaultValue]),
      };
    }).filter(
      (item: Record<string, any>) => !isNil(item.label) || !isEmpty(item.label)
    );
  }, [attributes, getFieldTable, getFieldId]);

  const optionCustomData = useMemo(() => {
    return get(attributes, 'seperate.items', '')
      .split('\n')
      .map((item: string) => ({
        label: item.trim(),
        value: item.trim(),
      }))
      .filter(
        (item: Record<string, any>) =>
          !isNil(item.label) || !isEmpty(item.label)
      );
  }, [attributes]);

  const list = isMakeCustom ? optionCustomData : optionDataOfDatabase;

  const defaultValue = useMemo(() => {
    if (isMakeCustom) {
      const defaultValueBinding = getTextBinding(
        attributes?.defaultValue || '',
        attributes?.record
      );

      return list.find(
        (item: Record<string, any>) => item.value === defaultValueBinding
      );
    } else {
      const getSource = get(attributes, 'defaultValue.source');

      if (isRelation.includes(getSource?.type)) {
        const flattenSource = flattenObj(getSource);

        return excuteRoundRelationship(
          getSource,
          flattenSource,
          attributes?.record
        );
      } else {
        const getSelctorType = get(getSource, 'selector.type');
        switch (getSelctorType) {
          // default value from logged user
          case BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR:
            return list.find(
              (item: Record<string, any>) => item.value === authProfile?.userId
            );
          // default value from selected value
          case BINDING_SELECTOR_TYPE.SELECT_VALUE_SELECTOR:
            const idSelected = handleGetValue(getSource, isCurrentRecord);
            return (
              list.find(
                (item: Record<string, any>) => item.value === idSelected
              ) || {}
            );
          // default value from list item or param
          case BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR:
          case BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR:
            let result = list.find(
              (item: Record<string, any>) => item.value === isCurrentRecord?._id
            );
            if (getFieldTable && getFieldTable.isThirdParty && externalRecord) {
              const field = getFieldId.key.replace('[0].', '');
              result = list.find(
                (item: Record<string, any>) =>
                  item.label === externalRecord[field]
              );
            }
            return result;
          default:
            return {};
        }
      }
    }
  }, [attributes]);

  const handleChangeValue = (value: any) => {
    dispatch(
      changeValue({
        objectId: attributes?.id,
        value,
      })
    );
  };

  return {
    showDropDown,
    value: valueInputs || defaultValue,
    list,
    handleShowDropdown,
    handleHiddenDropdown,
    handleChangeValue,
  };
};

export default useDropdown;
