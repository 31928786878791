import { Linking, Platform } from 'react-native';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import { IAction } from '@common/types/';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const didLogin: (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[]
) => {
  const socialConfig = action.options;
  AsyncStorage.setItem(
    'clickFlows',
    JSON.stringify({
      currentRecord,
      actionComponent,
    })
  );

  if (Platform.OS !== 'web') {
    let url = `https://oidc.oz1.lita-platform.com:9080/auth/realms/oz1/protocol/openid-connect/auth?response_type=code&client_id=${socialConfig.clientId}&redirect_uri=${socialConfig.redirectUri}&scope=openid&state=did`;
    await Linking.openURL(url);
  }

  return {
    status: 'FAILED',
  };
};

export default didLogin;
