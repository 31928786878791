import { BINDING_SELECTOR_TYPE, PERMISSON } from '@common/constants';
import store from '@common/redux/store';
import {
  forEach,
  get,
  isEmpty,
  isNil,
  keys,
  mapKeys,
  set,
  transform,
  omit,
} from 'lodash';

export const handlePermissonRecord = ({
  response,
  collection,
}: {
  response: Record<string, any>[];
  collection: Record<string, any>;
}) => {
  const state = store.getState();
  const auth = state?.auth?.profile;

  const fields = get(collection, 'fields', []);

  const objKeys = fields.reduce(
    (result: Record<string, any>, item: Record<string, any>) => {
      result[item.fid] = item?.permission;
      return result;
    },
    {}
  );

  const accObj: Record<string, any>[] = [];

  transform(
    response,
    (result, current, index) => {
      forEach(keys(current), (item) => {
        const permissionView = get(objKeys, `${item}.view`, PERMISSON.ANYONE);

        switch (permissionView) {
          case PERMISSON.NOBODY:
            set(result, [`${index}`, `${item}`], '');
            break;

          case PERMISSON.LOGGED_USER:
            const isLogged =
              !isEmpty(auth) || !isNil(auth) ? current[item] : '';

            set(result, [`${index}`, `${item}`], isLogged);
            break;

          case PERMISSON.RECORD_CREATOR:
            const isCreator =
              get(current, `creator`, null) === get(auth, 'email', '')
                ? current[item]
                : '';

            set(result, [`${index}`, `${item}`], isCreator);
            break;

          default:
            set(result, [`${index}`, `${item}`], current[item]);
            break;
        }
      });
    },
    accObj
  );

  return accObj;
};

export const handlePermissionField = ({
  response,
  collection,
  visibility,
}: {
  response: any;
  collection: Record<string, any>;
  visibility?: boolean;
}) => {
  const state = store.getState();
  const auth = state?.auth?.profile;

  const getRecord = response?.record
    ? {
        ...omit(response, ['record']),
        ...response.record,
      }
    : response;

  if (visibility) {
    return getRecord;
  } else {
    let record: Record<string, any> = {};

    const fields = get(collection, 'fields', []);

    const handleRes = (key: string, value: any) => {
      const field = fields.find(
        (item: Record<string, any>) => item.fid === key
      );
      const permissionView = get(field, 'permission.view', PERMISSON.ANYONE);

      switch (permissionView) {
        case PERMISSON.NOBODY:
          return {
            ...{ [key]: '' },
          };

        case PERMISSON.LOGGED_USER:
          const isLogged = !isEmpty(auth) || !isNil(auth);

          return {
            ...{ [key]: isLogged ? value : '' },
          };

        case PERMISSON.RECORD_CREATOR:
          const isCreator =
            get(getRecord, `creator`, null) === get(auth, 'email', '');

          return {
            ...{ [key]: isCreator ? value : '' },
          };

        default:
          return {
            ...{ [key]: value },
          };
      }
    };

    mapKeys(getRecord, (value, key) => {
      const valueField = handleRes(key, value);

      record = {
        ...record,
        ...valueField,
      };
    });

    return record;
  }
};

export const handleValueField = ({
  value,
  collection,
  key,
  selectorType,
}: {
  value: any;
  collection: Record<string, any>;
  key: string;
  selectorType?: any;
}) => {
  const state = store.getState();
  const auth = state.auth?.profile;
  const currentRecord = state.database.currentRecord;

  const fields = get(collection, 'fields', []);

  const field = fields.find((item: Record<string, any>) => item.fid === key);
  const permissionView = get(field, 'permission.view', PERMISSON.ANYONE);

  switch (permissionView) {
    case PERMISSON.NOBODY:
      return '';

    case PERMISSON.LOGGED_USER:
      const isLogged = !isEmpty(auth) || !isNil(auth);

      return isLogged ? value : '';

    case PERMISSON.RECORD_CREATOR:
      let currentObj = {};

      if (selectorType === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
        currentObj = get(
          currentRecord,
          `${collection?.databaseUuid}.record`,
          {}
        );
      } else if (selectorType === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
        currentObj = auth;
      }

      const isCreator =
        get(currentObj, `creator`, null) === get(auth, 'email', '');

      return isCreator ? value : '';

    default:
      return value;
  }
};
