import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';

const createStyles = (styleData: Record<string, any>) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      height: styleData.height,
      width: styleData.width,
      marginTop: styleData.marginTop,
      ...(Platform.OS === 'web'
        ? {
            boxShadow:
              styleData.objects?.rect?.styleMode === 'shadow'
                ? '0px 2px 2px rgba(0, 0, 0, 0.2)'
                : null,
          }
        : {}),
      borderColor: styleData.objects?.rect?.borderColor || '#f5f5f5',
      borderWidth: 3,
      borderRadius: styleData.objects?.rect?.borderRadius || 10,
      zIndex: styleData.zIndex,
    },
    text: {
      textAlign: 'center',
      margin: 'auto',
      fontSize: 17,
      opacity: 0.5,
    },
  });
};

export default createStyles;
