import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  appDetail: {
    width: '50%',
    maxWidth: 800,
    display: 'flex',
    justifyContent: 'center',
  },
  appDetailWrapper: {
    width: 530,
    marginLeft: 130,
  },
  appHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    fontWeight: '600',
  },
  appTitle: {
    width: '60%',
    fontSize: 38,
    fontWeight: '700',
  },
  titleText: { fontSize: 38, fontWeight: '700' },
  // shareButton: {
  //   borderWidth: 2,
  //   borderColor: theme.colors.primary,
  //   borderRadius: 8,
  //   fontWeight: "600",
  //   paddingVertical: 5,
  //   paddingHorizontal: 20,
  // },
  appDescription: {
    minHeight: 100,
    width: 550,
    marginBottom: 30,
    fontSize: 17,
    display: 'flex',
    alignItems: 'center',
    // textTransform: 'capitalize',
    flexWrap: 'wrap',
    //wordWrap: 'break-word',
  },
  appDetailText: { width: 'inherit' },
  appBanner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 60,
  },
  appLogo: {
    marginRight: 35,
    marginBottom: 35,
    // cursor: 'pointer',
    borderRadius: 44,
    width: '50%',
  },
  appIcon: {
    borderRadius: 40,
    width: 210,
    height: 210,
  },
  buttonView: {
    backgroundColor: '#34A0DD',
    fontWeight: '900',
    fontSize: 16,
    color: '#fff',
    borderRadius: 8,
    width: 'max-content',
    marginTop: 19,
  },
});

export default styles;
