import React from 'react';
import { Platform, View, Image as RNImage } from 'react-native';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { defaultURL } from '../../../../utils/common.constant';
import { getBorderStyle, getBorderWidth } from '../../../func';

type Props = {
  borderColor: string;
  borderWidth: number;
  borderRadius: number;
  backgroundImage: string;
  size: number;
  url: string;
  styles: any;
  imageResize: 'cover' | 'contain';
  borderStyle: 'solid' | 'dotted' | 'dashed' | any;
  placeholderImage?: string;
};
const AvartarImage: React.FC<Props> = (props) => {
  const {
    borderColor,
    borderStyle,
    borderRadius,
    borderWidth,
    size,
    styles,
    backgroundImage,
    imageResize,
    url,
    placeholderImage,
  } = props;

  const [uri, setUri] = React.useState<string>(url);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const newUrl = url || placeholderImage || defaultURL;
    if (uri !== newUrl) {
      setIsLoading(false);
      setUri(newUrl);
    }
  }, [url, placeholderImage, defaultURL]);

  const onError = () => {
    const placeholder = placeholderImage || defaultURL;

    if (uri !== placeholder) {
      setIsLoading(false);
      setUri(placeholder);
    } else {
      setUri(defaultURL);
    }
  };

  return (
    <>
      {isLoading ? null : Platform.OS === 'ios' || Platform.OS === 'web' ? (
        <ContentLoader
          speed={3}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          height={isLoading || Platform.OS === 'web' ? size : 0}
          width={size}
          style={{
            borderRadius: +borderRadius || 0,
          }}
        >
          <Rect x="0" y="0" rx="0" ry="0" width={size} height={size} />
        </ContentLoader>
      ) : null}
      <View
        style={{
          borderColor,
          borderWidth: getBorderWidth(borderWidth, borderStyle),
          borderStyle: getBorderStyle(borderStyle),
          borderRadius: +borderRadius || 0,
          backgroundColor: backgroundImage,
          display: isLoading || Platform.OS !== 'web' ? 'flex' : 'none',
          opacity: isLoading ? 1 : 0,
          height: size,
          width: size,
        }}
      >
        <RNImage
          style={[
            {
              borderRadius: +borderRadius || 0,
            },
            styles.cardCover,
          ]}
          resizeMode={imageResize}
          source={{ uri }}
          onLoad={() => setIsLoading(true)}
          onError={onError}
        />
      </View>
    </>
  );
};

export default AvartarImage;
