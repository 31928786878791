import { get, find } from 'lodash';

import { createSelector } from '@reduxjs/toolkit';

import { databaseSelector } from './database';
import { pagesSelector } from './page';
import { RootState } from '../store';

export const authSelector = (state: RootState) => state.auth;

export const authTableSelector = createSelector(
  [databaseSelector, pagesSelector],
  ({ collections }: any, { app }: any) => {
    const authTableId = get(app, 'metadata.auth.table');
    if (!authTableId) {
      return null;
    }
    const authTable = find(collections, { databaseHost: authTableId });
    if (!authTable) {
      return null;
    }
    return authTable;
  }
);

export const authProfileSelector = createSelector(
  [authTableSelector, authSelector],
  (authTable, { profile }) => {
    return find(authTable?.databaseMetadata, {
      click_table_record_id: profile
        ? get(profile, 'click_table_record_id')
        : '',
    });
  }
);
