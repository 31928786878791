import {
  generateFile,
  IFileUploadDocument,
  ImagePicker,
  uploadFileDocument,
} from '@common/utils/upload';
import React, { useState } from 'react';
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  Text,
  Platform,
} from 'react-native';
import createStyles from './style';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';
import DocumentPicker from 'react-native-document-picker';

export default function CusFileUpload(attributes: any) {
  const styles = createStyles(attributes);

  const [error, setError] = useState('');

  const [pending, setPending] = useState(false);

  const { valueInputs, changeInput }: UseValueInputProps =
    useValueInputs(attributes);
  const handlePress = async () => {
    try {
      setPending(true);
      error && setError('');
      if (Platform.OS !== 'web') {
        const res: IFileUploadDocument | any =
          await DocumentPicker.pickMultiple({
            type: [DocumentPicker.types.allFiles],
          });
        const file: any = await generateFile(res[0].uri);

        const response = await uploadFileDocument(file);

        if (response) {
          const handleResp = {
            filename: response.name,
            id: response.blobId,
            url: response.url,
            size: response.size,
          };

          changeInput(handleResp);
          setPending(false);
        }
      }

      ImagePicker.showImagePicker(
        { quality: 0.7, setPending, imageUpload: false, setError },
        (response: any) => {
          if (response) {
            changeInput(response);
            setPending(false);
          }
        }
      );
    } catch (err) {
      setPending(false);
    }
  };

  const Loading = () => {
    if (!pending) return null;
    return (
      <View
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <ActivityIndicator />
        <Text style={{ marginLeft: 10 }}>Uploading...</Text>
      </View>
    );
  };

  if (!valueInputs) {
    return (
      <View style={styles.wrapper}>
        <TouchableOpacity onPress={handlePress} style={styles.trigger}>
          <View style={styles.innerView}>
            <Loading />
            {/* {!pending && !error && <Text style={styles.text}>Choose File</Text>}
            {error && <Text style={{ color: 'red' }}>{error}</Text>} */}
            {!pending && <Text style={styles.text}>Choose File</Text>}
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.wrapper}>
      <TouchableOpacity onPress={handlePress} style={styles.trigger}>
        <View style={styles.innerView}>
          <Text>{valueInputs?.filename}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}
