import moment from 'moment';

export const OPTIONS_MODEL: { [key: string]: string } = {
  date: 'YYYY/MM/DD HH:mm',
  dateOnly: 'YYYY/MM/DD',
  dateTextInput: 'YYYY/MM/DD',
};

export const getDateDefaultValue = (type: string) => {
  if (type === 'startOfToday') {
    return moment().startOf('day').toDate();
  }
  return moment().toDate();
};

export const getDateFormat = (
  format: 'date' | 'dateOnly' | 'dateTextInput' = 'date'
) => {
  return OPTIONS_MODEL[format];
};
