import { pick } from 'lodash';
import { StyleSheet } from 'react-native';
import { checkFont } from '../func';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    text: {
      fontSize: 16,
      color: 'orange',
      fontFamily: checkFont(attributes.fontFamily),
    },
    wrapper: {
      ...pick(attributes, [
        'opacity',
        'width',
        'marginTop',
        'marginLeft',
        'borderRadius',
        'height',
      ]),
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      // height: 42,
      zIndex: attributes.zIndex,
    },
    trigger: {
      flex: 1,
    },
    innerView: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    fileWrapper: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    filename: {},
    filenameText: {
      textAlign: 'center',
    },
    clearButton: {
      marginTop: 10,
    },
    clearText: {
      color: '#8a8a8a',
      fontWeight: '500',
      fontSize: 12,
    },
    loaderWrapper: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

export default createStyles;
