import { StyleSheet } from 'react-native';
import { HEIGHT_STATUS_BAR } from '@common/constants/shared';
import { dimensionSelector } from '@common/redux/selectors/page';
import { useSelector } from 'react-redux';

const createStyles = (props: any) => {
  const dimension = useSelector(dimensionSelector);

  const originX = props.originX;

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: !props.isIphone ? HEIGHT_STATUS_BAR * originX : 25 * originX,
      maxHeight: HEIGHT_STATUS_BAR * originX,
      width: dimension.width,
      marginTop: 0,
      zIndex: 99999,
      paddingHorizontal: 20 * originX,
    },
    signal: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    signalText: { color: 'black', marginLeft: 3, fontSize: 13 * originX },
    timer: {
      color: 'black',
      fontSize: 15,
    },
    battery: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: 13 * originX,
    },
    batteryText: { color: 'black', marginRight: 3, fontSize: 12 * originX },
  });
};

export default createStyles;
