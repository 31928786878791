import React from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { IButton } from '@nocode/types';
import createStyles from '@nocode/components/TrustdockLogin/style';

const TrustdockLogin: React.FC<IButton> = ({ attributes, onPress }) => {
  const { text, socialConfig, textError, platformError } = attributes;
  const styles = createStyles(attributes);
  const textOptions = Array.isArray(text) ? text[0] : text;
  const currentUrl = window?.location?.href?.toString();

  if (
    !currentUrl?.includes('canvas') &&
    (!socialConfig || !socialConfig.clientId || !socialConfig.clientSecret)
  ) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{textError?.en}</Text>
      </View>
    );
  }

  return (
    <TouchableHighlight onPress={onPress}>
      <View style={styles.container}>
        <Text style={styles.text}>{textOptions}</Text>
      </View>
    </TouchableHighlight>
  );
};

export default TrustdockLogin;
