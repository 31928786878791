import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';
import {
  Fade,
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
} from 'rn-placeholder';

type SkeletonLoadingCardProps = {
  attrs: any;
  initialize: boolean;
};

function SkeletonLoadingCard({ attrs, initialize }: SkeletonLoadingCardProps) {
  const {
    opacity,
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius,
    borderStyle,
    // iconColor,
  } = attrs.attributes;
  const PlaceholderItem = () => {
    return (
      <Placeholder Animation={Fade}>
        <PlaceholderMedia style={styles.cardCover} />
        <View style={styles.cardContent}>
          <PlaceholderLine width={80} />
          <PlaceholderLine width={90} />
          <PlaceholderLine width={40} />
        </View>
      </Placeholder>
    );
  };

  return (
    <View style={styles.wrapper}>
      {Array.from(Array(4).keys()).map((item: any) => {
        return (
          <Card
            key={item}
            style={[
              {
                opacity,
                backgroundColor,
                borderColor,
                borderWidth,
                borderRadius,
                borderStyle: borderStyle !== 'none' ? borderStyle : undefined,
              },
              styles.item,
            ]}
          >
            <PlaceholderItem />
          </Card>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' },
  item: {
    width: '48%',
    margin: 3,
  },
  cardCover: { width: '100%', height: 120, marginBottom: 10 },
  cardContent: { padding: 5 },
});

export default SkeletonLoadingCard;
