import React, { useState, ComponentProps, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button as RNButton } from 'react-native-paper';
import { Text, View } from 'react-native';
import { get, isEmpty, isNil } from 'lodash';

import createStyles from './style';
import { checkFont, checkMessage } from '../func';
import { stripeMessage } from '../locale';

type IProps = {
  attributes: Record<string, any>;
  height: number;
  width: number;
  onPress: any;
  stripePayment: any;
  updateStripePayment: any;
  accountConnectId: string;
  customerId: string;
  groupActionId: any;
  id: string;
  locale: string;
};

type Props = ComponentProps<typeof RNButton> | any;

const StripeComponent: React.FC<IProps> = ({
  height,
  width,
  attributes,
  onPress,
  stripePayment,
  accountConnectId,
  groupActionId,
  id,
  locale,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const styles = createStyles({ attrs: attributes, width, height });

  const isPreview =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  const {
    type,
    fontFamily,
    title,
    changedescription,
    email,
    testMode,
    subscription,
  } = attributes;

  const { enabled: titleEnabled = true, text: titleText = 'Card information' } =
    title || {};

  const [message, setMessage] = useState<any | null>(null);
  const [isError, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emptyCard, setEmptyCard] = useState(false);

  const [cardCompleted, setCardCompleted] = useState(true);

  const text = Array.isArray(get(attributes, 'submitButton.text', ''))
    ? get(attributes, 'submitButton.text', '')[0]
    : get(attributes, 'submitButton.text', '');

  const childProps: Props = {
    mode: type,
    children: text.toUpperCase(),
    style: {
      backgroundColor: get(attributes, 'submitButton.backgroundColor'),
      borderRadius: get(attributes, 'submitButton.borderRadius'),
      justifyContent: 'center',
      borderColor: get(attributes, 'submitButton.backgroundColor'),
      marginTop: 20,
      minHeight: 44,
    },
    labelStyle: {
      fontFamily: checkFont(fontFamily),
      fontSize: get(attributes, 'submitButton.fontSize'),
      color: get(attributes, 'submitButton.color'),
    },
  };

  const handleAction = (id: string, isAction: any) => {
    const sectionOnpress = get(attributes, `${id}.action`, {});

    if (groupActionId && isNil(isAction)) {
      if (isEmpty(sectionOnpress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  const checkStatusPayment = (status: string) => {
    switch (status) {
      case 'succeeded':
      case 'active':
        setMessage(stripeMessage({ locale, type: 'success' }));
        setError(false);
        handleAction('successActions', attributes?.successActions);
        break;

      case 'processing':
        setMessage(stripeMessage({ locale, type: 'processing' }));
        setError(false);
        break;

      case 'requires_payment_method':
        setMessage(stripeMessage({ locale, type: 'requires_payment_method' }));
        setError(true);
        handleAction('failedActions', attributes?.failedActions);
        break;

      default:
        setMessage(stripeMessage({ locale, type: 'error_card' }));
        setError(true);
        handleAction('failedActions', attributes?.failedActions);
        break;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const handlePaymentMethod = async () => {
      return await stripe?.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardElement) || { token: '' },
      });
    };

    const payload = {
      destination: accountConnectId,
      receipt_email: email?.emailBuyer,
      description: changedescription?.description,
      testMode,
      subscription: {
        priceId: subscription?.priceId,
        quantity: subscription?.quantity,
        customerId: '',
      },
      paymentMethodId: (await handlePaymentMethod()).paymentMethod?.id,
    };

    setLoading(true);

    try {
      if (isEmpty(subscription?.priceId)) {
        handleAction('failedActions', attributes?.failedActions);
        setMessage(stripeMessage({ locale, type: 'missing_id' }));
        setError(true);
        setLoading(false);
        return;
      } else {
        const response = await stripePayment(payload);
        const paymentIntents =
          response?.latest_invoice?.payment_intent?.client_secret;

        if (paymentIntents) {
          const result = await stripe.confirmCardPayment(paymentIntents);
          if (
            result?.error?.message?.includes(
              'You have exceeded the maximum number of declines on this card in the last 24 hour period'
            )
          ) {
            handleAction('failedActions', attributes?.failedActions);
            setMessage(
              stripeMessage({
                locale,
                type: 'max_number',
              })
            );
            setError(true);
            setLoading(false);
            return;
          }
          const clientSecret = get(result, 'paymentIntent.client_secret');

          if (result.error && result.error.type === 'card_error') {
            handleAction('failedActions', attributes?.failedActions);
            setMessage(result.error.message);
            setError(true);
            setLoading(false);
            return;
          }

          stripe
            .retrievePaymentIntent(clientSecret)
            .then(async ({ paymentIntent }: any) => {
              checkStatusPayment(paymentIntent?.status);
            });
        } else if (response?.plan?.usage_type === 'metered') {
          checkStatusPayment(response?.status);
        } else if (response.status === 'active') {
          checkStatusPayment(response?.status);
        }
      }
    } catch (error: any) {
      handleAction('failedActions', attributes?.failedActions);
      setError(true);

      if (
        error.type === 'card_error' ||
        error.type === 'validation_error' ||
        error.response?.data?.message
      ) {
        if (error.response?.data?.message) {
          if (
            error.response?.data?.message ===
            "Cannot read property 'id' of null"
          ) {
            setMessage(stripeMessage({ locale, type: 'error_occured' }));
          } else if (error.response?.data?.message.includes('Invalid email')) {
            setMessage(
              stripeMessage({
                locale,
                type: 'invalid_email_address',
              })
            );
          } else if (error.response?.data?.message.includes('No such price')) {
            setMessage(stripeMessage({ locale, type: 'such_price' }));
          } else if (
            error.response?.data?.message.includes('Invalid API Key provided')
          ) {
            setMessage(stripeMessage({ locale, type: 'invalid_api' }));
          } else {
            setMessage(
              stripeMessage({ locale, type: error.response?.data?.message })
            );
          }
        } else {
          setMessage(error.message);
        }
      } else {
        setMessage(stripeMessage({ locale, type: 'error_occured' }));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    elements?.getElement(CardElement)?.clear();
    setMessage('');
    setError(false);
  }, [id]);

  const isConnected =
    isNil(accountConnectId) && !isPreview && !get(testMode, 'enabled', false);

  const isKey =
    (testMode.enabled &&
      isEmpty(testMode.publishableKey) &&
      isEmpty(testMode.secretKey)) ||
    (testMode.enabled && isEmpty(testMode.publishableKey)) ||
    (testMode.enabled && isEmpty(testMode.secretKey)) ||
    (testMode.publishableKey !== '' &&
      !String(testMode.publishableKey).startsWith('pk_test') &&
      testMode.enabled === true) ||
    (testMode.secretKey !== '' &&
      !String(testMode.secretKey).startsWith('sk_test') &&
      testMode.enabled === true);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#f5222d',
      },
    },
    hidePostalCode: true,
    disabled: isKey ? true : false,
  };

  return (
    <View style={styles.container}>
      {isConnected && (
        <View>
          <Text
            style={{
              color: 'red',
            }}
          >
            {stripeMessage({
              locale,
              type: 'stripe_configured',
            })}
          </Text>
        </View>
      )}

      {isKey && !isPreview && (
        <View>
          <Text
            style={{
              color: 'red',
            }}
          >
            {checkMessage(
              testMode.publishableKey,
              testMode.enabled,
              testMode.secretKey,
              locale
            )}
          </Text>
        </View>
      )}

      {titleEnabled && <Text style={styles.title}>{titleText}</Text>}

      <View style={styles.wrapperInput}>
        <CardElement
          onChange={(e: any) => {
            setError(!e.complete ? true : false);
            setEmptyCard(e.complete ? true : false);
            setMessage(
              !isEmpty(e.error)
                ? stripeMessage({
                    locale,
                    type: e.error?.code,
                    message: e.error?.message,
                  })
                : ''
            );
            setCardCompleted(e.complete);
          }}
          options={CARD_ELEMENT_OPTIONS}
        />
      </View>

      {!!message && (
        <View>
          <Text style={{ marginTop: 10, color: isError ? 'red' : 'green' }}>
            {message}
          </Text>
        </View>
      )}

      <RNButton
        disabled={
          (isNil(accountConnectId) && !get(testMode, 'enabled', false)) ||
          isKey ||
          !cardCompleted ||
          !emptyCard
        }
        {...childProps}
        onPress={handleSubmit}
        loading={loading}
      />
    </View>
  );
};

export default StripeComponent;
