import store from '@common/redux/store';
import { ActionResponse } from '@common/utils/handleActions/excuteAction';
import {
  setActionComponent,
  setForgotPassword,
} from '@common/redux/slice/action';
import { IAction } from '@common/types/';

const actionType = {
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
};

const forgotPassword: (
  action: any,
  currentRecord?: any,
  actionComponent?: IAction[]
) => Promise<ActionResponse> = async (
  action: any,
  currentRecord?: any,
  actionComponent?: IAction[]
) => {
  const dispatch = store.dispatch;

  if (action?.type === actionType.FORGOT_PASSWORD && !!action?.isStatus) {
    return {
      status: action.isStatus,
    };
  }

  dispatch(setActionComponent(actionComponent));
  dispatch(
    setForgotPassword({
      state: true,
      animation: 'fadeInUpBig',
    })
  );

  return {
    status: 'FAILED',
  };
};

export default forgotPassword;
