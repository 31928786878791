import React, { FC } from 'react';
import useUnivaPay, { Props, ReceivedProps } from './hook';
import UnivaPayForm from './UnivaPayForm';

const UnivaPayLayout: FC<Props> = (props) => {
  return <UnivaPayForm {...props} />;
};

const UnivaPay: FC<ReceivedProps> = (props) => (
  <UnivaPayLayout {...useUnivaPay(props)} />
);

export default UnivaPay;
