import { get } from 'lodash';
import { MarkerType } from '../../../types/map.type';

export const getCurrentLocationStatus = (attrs: any) => {
  const makerType = get<'simple' | 'multiple'>(attrs, 'markerType', 'multiple');

  let path = 'markers.currentLocation';

  if (makerType === 'simple') {
    path = 'marker.currentLocation';
  }

  return get(attrs, path);
};

export const stringToJson = (str: any) => {
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return [];
  }
};

export const convertLat = (num: number) => {
  const remainder = num % 180;
  if (remainder > 90) {
    return remainder - 180;
  } else if (remainder < -90) {
    return remainder + 180;
  } else {
    return remainder;
  }
};

export const getDistanceFromCurrent = (mk1: MarkerType, mk2: MarkerType) => {
  const Radius = 6371;
  const rLat1 = mk1.lat * (Math.PI / 180);
  const rLat2 = mk2.lat * (Math.PI / 180);
  const diffLat = rLat2 - rLat1;
  const diffLng = (mk2.lng - mk1.lng) * (Math.PI / 180);

  const distance =
    2 *
    Radius *
    Math.asin(
      Math.sqrt(
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(rLat1) *
            Math.cos(rLat2) *
            Math.sin(diffLng / 2) *
            Math.sin(diffLng / 2)
      )
    );
  return distance;
};

export const getDistance = (mk1: any, mk2: any) => {
  const Radius = 6371;
  const rLat1 = mk1.lat * (Math.PI / 180);
  const rLat2 = mk2.lat * (Math.PI / 180);
  const diffLat = rLat2 - rLat1;
  const diffLng = (mk2.lng - mk1.lng) * (Math.PI / 180);
  const distance =
    2 *
    Radius *
    Math.asin(
      Math.sqrt(
        Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
          Math.cos(rLat1) *
            Math.cos(rLat2) *
            Math.sin(diffLng / 2) *
            Math.sin(diffLng / 2)
      )
    );
  return distance;
};

export function convertKmToM(km: number) {
  return km * 1000;
}
