import styled from 'styled-components';
import { Table } from 'antd';

const AntStyles = styled(Table)`
  min-height: ${(attributes: any) => attributes.height}px;
  width: ${(attributes: any) => attributes.width}px;
  overflow: auto;

  .ant-table-body {
    height: ${(attributes: any) => attributes.height - 100}px;
  }
`;

export default AntStyles;
