import { pick } from 'lodash';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

type Props = {
  obj: any;
};

const ActionWrapper = (props: Props) => {
  const styles = StyleSheet.create({
    container: {
      ...pick(props.obj, ['height', 'width', 'marginTop', 'marginLeft']),
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

  return (
    <View style={styles.container}>
      <ActivityIndicator color={'#4259AC'} />
    </View>
  );
};

export default ActionWrapper;
