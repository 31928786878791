import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  const getShadow = () => {
    if (!attributes.shadow) return {};

    return {
      shadowColor: attributes.shadow.color,
      shadowRadius: attributes.shadow.size,
      shadowOpacity: attributes.shadow.blur || 1,
      shadowOffset: {
        width: attributes.shadow.x,
        height: attributes.shadow.y,
      },
      elevation:
        attributes?.shadow?.size === 0
          ? attributes?.shadow?.x
          : attributes?.shadow?.size,
    };
  };

  return StyleSheet.create({
    container: {
      ...pick(attributes, ['marginTop', 'marginLeft', 'width']),
      ...getShadow(),
      zIndex: attributes.zIndex,
    },
  });
};

export default createStyles;
