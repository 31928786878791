import getDIDToken from '@common/hooks/useGetDIDToken';
import getLineToken from '@common/hooks/useGetLineToken';
import getTrustdockToken from '@common/hooks/useGetTrustdockToken';
import { appInfoSelector } from '@common/redux/selectors/app';
import { authSelector } from '@common/redux/selectors/auth';
import { getDataTableSourceStore } from '@common/redux/selectors/database';
import { notificationSelector } from '@common/redux/selectors/notification';
import { pagesSelector } from '@common/redux/selectors/page';
import { checkAuth, checkAuthError } from '@common/redux/slice/auth';
import { RootState } from '@common/redux/store';
import { useHistory, useLocation } from '@common/routes/hooks';
import { ActionTypes } from '@common/types';
import { IRecord } from '@common/types/database';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import { AUTHEN_KEY } from '@common/utils/handleActions/func/func';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { find, get, isEmpty, pick, some } from 'lodash';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Linking, NativeModules, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import getDocomoToken from './useGetDocomoToken';

import queryString from 'query-string';

// type APIResponse = {
//   data: any;
//   error: string | null;
//   isLoading: boolean;
// };

const parseJson = (data: any) => {
  try {
    const parsedData = JSON.parse(data);

    return parsedData;
  } catch (e) {
    console.log('e:>>', e);
    return {};
  }
};

export const authenticationRouting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPageId, setCurrentPageId] = useState<any>();
  const [currentModalId, setCurrentModalId] = useState<any>();
  const [animation, setAnimation] = useState<any>();
  const [modalTransition, setModalTransition] = useState<any>();
  const { search, pathname } = useLocation();
  const query = useMemo(() => qs.parse(search), [search]);
  const appInfo = useSelector(appInfoSelector);
  const auth_info = useSelector(authSelector);
  const notificationInfo = useSelector(notificationSelector);

  const pageConfig = appInfo?.metadata
    ? parseJson(appInfo?.metadata)
    : undefined;

  const pages = useSelector(pagesSelector);
  const tableId = get(pageConfig, 'auth.tableId', null);

  const dataSource = useSelector((state: RootState) =>
    getDataTableSourceStore(state, tableId)
  );

  const data = dataSource || [];

  const authenticationCheck = () =>
    AsyncStorage.getItem(AUTHEN_KEY).then(
      (keyValue: string | any) => {
        const userStorage = parseJson(keyValue);

        if (isEmpty(userStorage) && isEmpty(data)) {
          dispatch(checkAuthError());
        }

        const findUser: IRecord | any = find(data, [
          '_id',
          userStorage?.userId,
        ]);

        const isValidApp = appInfo?.id === userStorage?.appId;

        if (isEmpty(findUser) || !isValidApp) {
          dispatch(checkAuthError());
          return false;
        } else {
          dispatch(
            checkAuth({
              ...findUser.record,
              databaseId: findUser.databaseId,
              userId: findUser._id,
              appId: userStorage.appId,
              ...pick(findUser, ['createdAt', 'updatedAt']),
            })
          );
          return true;
        }
      },
      () => {
        dispatch(checkAuthError());
        return false;
      }
    );

  const checkValidScreen = useCallback(
    (screenUuid: string | any) => {
      const isValid = some(pages, { screenUuid });

      if (isValid) return screenUuid;
      else {
        return pages[0].screenUuid;
      }
    },
    [pages]
  );

  const navigateCurrentPage = (lastLocation?: string) => {
    const { startHomePage } = pageConfig;
    const target = lastLocation || startHomePage;

    history.replace({
      search: qs.stringify({
        ...query,
        ...{
          target: checkValidScreen(target),
        },
      }),
    });
    setLoading(false);
  };

  const checkLogined = () => {
    authenticationCheck().then((res: any) => {
      let id: string;
      const { active, startHomePage } = pageConfig;
      const { target } = query;

      if (res) {
        id = target || active;
      } else {
        id = startHomePage;
      }

      if (notificationInfo.targetScreen) {
        id = notificationInfo.targetScreen;
      }

      history.replace({
        search: qs.stringify({
          ...query,
          ...{
            target: checkValidScreen(id),
          },
        }),
      });
      setLoading(false);
    });
  };

  const doExcuteAction = (actions: any, lastLocation: string) => {
    let noNavigateAction = true;

    actions.forEach((element: any) => {
      if (element?.actionType === ActionTypes.NAVIGATE) {
        noNavigateAction = false;
        return;
      }
    });

    // have no navigate action, navigate to current screen
    if (noNavigateAction) {
      navigateCurrentPage(lastLocation);
    }

    actionPromise(actions, data.currentRecord).finally(() => {
      AsyncStorage.setItem('clickFlows', JSON.stringify({}));
      setLoading(false);
    });
  };

  const executePostOAuthClickflows = (oAuthresult?: any) => {
    AsyncStorage.getItem('clickFlows')
      .then(async (keyValue: string | any) => {
        const lineClickFlows = await AsyncStorage.getItem('lineClickFlows');
        if (
          lineClickFlows &&
          lineClickFlows !== '{}' &&
          oAuthresult.friendship !== undefined
        ) {
          const { successActions, failedActions } = JSON.parse(lineClickFlows);
          const keyValData = JSON.parse(keyValue);
          const lastLocation = keyValData?.lastLocation;
          if (oAuthresult.friendship === true) {
            doExcuteAction(successActions, lastLocation);
          } else {
            doExcuteAction(failedActions, lastLocation);
          }
          await AsyncStorage.setItem('lineClickFlows', '{}');
        } else {
          if (keyValue !== '{}') {
            const data = JSON.parse(keyValue);
            const actions = data?.actionComponent.filter(
              (el: any) =>
                el.actionType !== ActionTypes.LINE_LOGIN &&
                el.actionType !== ActionTypes.DOCOMO_LOGIN &&
                el.actionType !== ActionTypes.DID_LOGIN &&
                el.actionType !== ActionTypes.TRUSTDOCK_LOGIN &&
                el.actionType !== ActionTypes.APPLE_LOGIN
            );
            const lastLocation = data?.lastLocation;

            if (actions && actions.length > 0) {
              doExcuteAction(actions, lastLocation);
            } else {
              navigateCurrentPage(lastLocation);
            }
          } else {
            checkLogined();
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getTokens = (code: string, type: string, appId: string) => {
    switch (type) {
      case 'line':
        getLineToken(code, appId).then((result: any) => {
          if (result.status !== 'FAILED') {
            executePostOAuthClickflows(result);
          } else {
            checkLogined();
          }
        });
        break;
      case 'docomo':
        getDocomoToken(code, appId);
        break;
      case 'did':
        getDIDToken(code, appId);
        break;
      case 'trustdock':
        getTrustdockToken(code, appId);
        break;
      default:
        break;
    }
  };

  const handleUrl = async (url: any) => {
    if (url) {
      url = (url?.url || url).toString();
      const authRegex = new RegExp(
        `${NativeModules?.ReactNativeConfigModule?.SCHEME_DEEP_LINK_APP}://auth`
      );

      if (!authRegex.test(url)) return;

      const paramsRegex = new RegExp('(?:\\?|#)(.*)');
      const params = paramsRegex.exec(url);

      if (!params) return;

      url = params[0];
      const appId = pathname?.split('/')[2];

      if (url.includes('code')) {
        const parsedData = queryString.parse(url);
        if (parsedData.code && parsedData.state && appId) {
          return getTokens(`${parsedData.code}`, `${parsedData.state}`, appId);
        }
      }

      if (url.includes('masterId')) {
        const { masterId } = queryString.parse(url);
        if (!masterId) return;
        return await AsyncStorage.setItem('masterId', `${masterId}`);
      }
    }
  };

  useEffect(() => {
    let codeAuth = null;
    let typeAuth = null;
    let appId = null;
    if (Platform.OS !== 'web') {
      Linking.getInitialURL()
        .then((initialUrl) => {
          handleUrl(initialUrl);
        })
        .catch((err) => {
          console.warn(err);
        });
      Linking.addEventListener('url', handleUrl);
    } else {
      const url = window.location.href;
      const urlSearchParams = new URLSearchParams(window.location.search);
      codeAuth = urlSearchParams.get('code');
      typeAuth = urlSearchParams.get('type');
      appId = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
    }

    if (codeAuth && typeAuth && appId) {
      getTokens(codeAuth, typeAuth, appId);
    } else {
      checkLogined();
    }
    return () => Linking.removeEventListener('url', handleUrl);
  }, []);

  useEffect(() => {
    const {
      target,
      transition,
      targetModal,
      modalTransition: queryModalTransition,
    } = query;

    if (target && currentPageId !== target) {
      setCurrentPageId(target);
    }

    if (currentModalId !== targetModal) {
      setCurrentModalId(targetModal);
    }

    if (animation !== transition) {
      setAnimation(transition);
    }

    if (modalTransition !== queryModalTransition) {
      setModalTransition(queryModalTransition);
    }

    return;
  }, [query]);

  useEffect(() => {
    if (auth_info.isAuth && auth_info.isOAuth) {
      AsyncStorage.getItem('clickFlows').then((clickflows: string | any) => {
        if (clickflows !== '{}') {
          const data = JSON.parse(clickflows);
          const actions = data?.actionComponent.filter(
            (el: any) =>
              el.actionType !== ActionTypes.LINE_LOGIN &&
              el.actionType !== ActionTypes.DOCOMO_LOGIN &&
              el.actionType !== ActionTypes.DID_LOGIN &&
              el.actionType !== ActionTypes.TRUSTDOCK_LOGIN &&
              el.actionType !== ActionTypes.APPLE_LOGIN
          );
          if (actions.length > 0) return executePostOAuthClickflows();
        }
        AsyncStorage.getItem(AUTHEN_KEY).then((res) => {
          let id: string;
          const { active, startHomePage } = pageConfig;
          if (res && res !== '{}') {
            id = active;
          } else {
            id = startHomePage;
          }
          history.replace({
            search: qs.stringify({
              ...query,
              ...{
                target: checkValidScreen(id),
              },
            }),
          });
          setLoading(false);
        });
      });
    }
  }, [auth_info.isAuth]);

  const currentPage = useMemo(() => {
    return pages.find((o) => {
      return o.screenUuid == currentPageId;
    });
  }, [currentPageId]);

  const currentModalPage = useMemo(
    () =>
      pages.find((o) => {
        return o.screenUuid == currentModalId;
      }),
    [currentModalId]
  );

  return {
    currentPage,
    animation,
    currentModalPage,
    modalTransition,
    loading,
    appInfo,
  };
};
