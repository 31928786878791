import { checkFont } from '@common/components/func';
import { ISelect } from '@common/types/element';
import { isEmpty, pick } from 'lodash';
import { Platform, StyleSheet } from 'react-native';

const createStyles = (attributes: ISelect, value: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        // 'borderWidth',
        'borderColor',
        'borderColor',
        'borderRadius',
        'backgroundColor',
        'opacity',
        'height',
      ]),
      ...(attributes.borderStyle !== 'none' && {
        borderWidth: attributes.borderWidth,
      }),
      paddingLeft: 5,
      paddingRight: 5,
      shadowColor: attributes?.shadow?.color || '#fff',
      shadowRadius: attributes?.shadow?.size || 0,
      shadowOpacity: attributes?.shadow?.blur || 0,
      shadowOffset: {
        width: attributes?.shadow?.x || 0,
        height: attributes?.shadow?.y || 0,
      },
      elevation: attributes?.shadow?.size || 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontFamily: attributes?.fontFamily || 'Roboto',
      ...(!isEmpty(attributes.borderStyle) && {
        borderStyle:
          attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      }),
    },
    text: {
      color: !isEmpty(value)
        ? attributes?.color
        : attributes?.placeholderColor || attributes?.color,
      fontSize: attributes.fontSize,

      ...(Platform.OS !== 'web' && {
        fontFamily: checkFont(attributes?.fontFamily),
      }),
      lineHeight:
        Platform.OS === 'web' ? undefined : attributes.fontSize * 1.15,
      flex: 1,
    },
  });
};

export default createStyles;
