import axios, { AxiosResponse } from 'axios';
import { pick } from 'lodash';
import { setError, setNotification } from '@common/redux/slice/action';
import { loginSuccessWithOAuth } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { ILineToken } from '@common/types/element';
import { APP_API_TOKEN } from '@common/constants';
import appConfig from '../../../common/appConfig.json';
import AsyncStorage from '@react-native-async-storage/async-storage';

const getDocomoToken = async (code: string, appId: string) => {
  const dispatch = store.dispatch;
  const masterId = await AsyncStorage.getItem('masterId');
  try {
    let login_url = `${appConfig.baseUrl}/apps-auth/docomo/${appId}/login?code=${code}`;
    if (masterId) {
      login_url += `&masterId=${masterId}`;
    }
    const res: AxiosResponse<ILineToken | any> = await axios.get(login_url, {
      headers: {
        authorization: 'Bearer ' + APP_API_TOKEN,
      },
    });

    const resData = res.data;
    if (resData.code && resData.code === 400) {
      const { message } = resData;
      return {
        status: 'FAILED',
        message,
      };
    }

    if (resData && resData.id_token && resData.dataUser) {
      dispatch(
        loginSuccessWithOAuth({
          user: {
            ...resData.dataUser.record,
            databaseId: resData.dataUser.databaseId,
            userId: resData?.dataUser?._id,
            appId: appId,
            ...pick(resData, ['createdAt', 'updatedAt']),
          },
          token: res.data.id_token,
        })
      );
      dispatch(setNotification('Login Success !'));
      return {
        status: 'SUCCEED',
        message: 'Login success!!',
      };
    }
  } catch (error: any) {
    dispatch(setError(error?.message));
    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default getDocomoToken;
