import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

import { IWebView } from '@common/types/element';

const createStyles = (attributes: IWebView) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'width',
        'height',
        'zIndex',
      ]),
    },
    errorRender: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};

export default createStyles;
