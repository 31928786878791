import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const Error = ({ attributes }: { attributes: any }) => {
  return (
    <View style={[styles.container, { height: attributes.height }]}>
      <Text style={styles.text}>Error plugin</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#8f9dd2',
  },
  text: {
    color: '#fff',
  },
});

export default Error;
