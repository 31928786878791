import { pick } from 'lodash';
import { StyleSheet } from 'react-native';
import { checkFont } from '../func';

const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, ['marginTop', 'marginLeft']),
      fontFamily: checkFont(attributes.fontFamily),
      zIndex: attributes.zIndex,
    },
  });
};

export default createStyles;
