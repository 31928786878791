import { IMAGE_TYPE } from '@common/constants/shared';
import { useCallback, useMemo } from 'react';
import { IImage } from '@common/types/element';
import { get, isEmpty } from 'lodash';
import { getImageBinding } from '@common/utils/handleBinding';
import { useSelector } from 'react-redux';
import { formInputs } from '@common/redux/selectors/formInput';

const useImage = (attributes: IImage) => {
  const { imageType, imageURL, filename1x: imageUpload, imageUrl } = attributes;

  const { defaultValues } = useSelector(formInputs);

  const getUrl = useCallback(() => {
    switch (imageType) {
      case IMAGE_TYPE.UPLOAD:
        return imageUpload;

      case IMAGE_TYPE.URL:
        if (!imageURL) return;
        const getSoure: any = imageURL.filter((item: any) => item.id);

        if (!isEmpty(getSoure)) {
          const urlBinding = getImageBinding(
            imageURL,
            get(attributes, 'record', defaultValues || {})
          );

          return urlBinding;
        }

        if (Array.isArray(imageURL)) return imageURL[0];
        return imageURL;

      case IMAGE_TYPE.BINDING:
        const imageBinding = getImageBinding(
          imageUrl,
          get(attributes, 'record', defaultValues || {})
        );

        return imageBinding?.binding;

      default:
        return;
    }
  }, [imageType, imageURL, imageUrl, attributes]);

  const url = useMemo(() => getUrl(), [attributes]);

  return { url };
};

export default useImage;
