import React, { useState } from 'react';
import { View, Animated } from 'react-native';
import { useSelector } from 'react-redux';

import AnimationWrapper from '@common/components/Animation/NormalAnimation';
import { appInfoSelector } from '@common/redux/selectors/app';
import Confirrm from './ConfirmPopup';
import ForgotPassword from './ForgotPassword';
import createStyles from './style';

interface IProps {
  appId: string;
  locale: string;
}

export type Response = {
  status: boolean;
  loading: boolean;
  error: string;
};

const ForgotPasswordView = ({ appId, locale }: IProps) => {
  const appInfo = useSelector(appInfoSelector);
  const [value, setValue] = useState<string>('');
  const [response, setResponse] = useState<Response>({
    status: false,
    loading: false,
    error: '',
  });

  const styles = createStyles(appInfo);

  return (
    <View
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%',
      }}
    >
      {!response.status ? (
        <View style={styles.wrapper}>
          <Animated.View>
            <AnimationWrapper animation="fadeInUpBig">
              <ForgotPassword
                appId={appId}
                response={response}
                value={value}
                setValue={setValue}
                setResponse={setResponse}
                locale={locale}
              />
            </AnimationWrapper>
          </Animated.View>
        </View>
      ) : (
        <View style={styles.wrapper}>
          <Animated.View>
            <AnimationWrapper animation="fadeInRightBig">
              <Confirrm
                email={value}
                setResponse={setResponse}
                locale={locale}
              />
            </AnimationWrapper>
          </Animated.View>
        </View>
      )}
    </View>
  );
};

export default ForgotPasswordView;
