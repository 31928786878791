export const CARD = {
  CARD_NAME: 'cardName',
  CARD_NUMBER: 'cardNumber',
  CARD_EXPIRATION: 'cardExpiration',
  CARD_CVC: 'cardCvc',
  TEXT_ERROR: 'textError',
};

export const STATUS_UNIVAPAY_SUBSCRIPTION = {
  UNVERIFIED: 'unverified',
  UNCONFIRMED: 'unconfirmed',
  CANCELED: 'canceled',
  UNIPAID: 'unpaid',
  CURRENT: 'current',
  SUSPENDED: 'suspended',
  COMPLETED: 'completed',
};

export const getTextErrors = (textError = {}) => ({
  cardNameInvalid: 'This card holder name looks invalid',
  cardNumberInvalid: 'This card number looks invalid',
  cardExpirationInvalid: 'This expiration date looks invalid',
  cardCvcInvalid: 'This cvc date looks invalid',
  cardNameRequired: 'Card holder name is required',
  cardNumberRequired: 'Card number is required',
  cardExpirationRequired: 'Expiration date is required',
  cardCvcRequired: 'Cvc code is required',
  secretRequired: 'Secret is required',
  jwtRequired: 'JWT is required',
  emailRequired: 'Email is required',
  connectionRequired: 'UnivaPay not yet connected',
  cardNameMustBeValid: 'Cardholder must be between 2 to 30 digits',
  emailInvalid: 'Email looks invalid',
  ...textError,
});

export const amountFormatter = (
  value: string | number,
  typeCurrency: 'usd' | 'jpy'
): number => {
  if (!value) return 0;
  if (typeCurrency === 'jpy') {
    return Math.round(Number(value)) || 0;
  } else {
    return Number(value) || 0;
  }
};

export const cardNumberFormatter = (
  oldValue: string,
  newValue: string
): string => {
  if (oldValue.length > newValue.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export const cardExpirationDateFormatter = (
  oldValue: string,
  newValue: string
): string => {
  if (oldValue.length > newValue.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{2})/g, '$1/')
    .substring(0, 5);
};

export const toNonAccentVietnamese = (str: string) => {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, 'A');
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, 'E');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, 'I');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, 'O');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, 'U');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, 'Y');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};
