import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DimensionObject, IPage } from '@common/types';

export type StatePage = {
  pages: IPage[];
  isHaveDevelopPlugin: boolean;
  isDevelopPluginLoading: boolean;
  dimension: DimensionObject;
  loadingDimension: boolean;
  googleMaploaded: boolean;
};

const initialState: StatePage = {
  pages: [],
  isHaveDevelopPlugin: false,
  isDevelopPluginLoading: false,
  dimension: { fontScale: 0, height: 0, scale: 0, width: 0 },
  loadingDimension: false,
  googleMaploaded: false,
};

const pageSlice = createSlice({
  name: 'pageInfo',
  initialState: initialState,
  reducers: {
    loadPages: (state: StatePage, action: PayloadAction<Array<IPage>>) => {
      if (action.payload) {
        state.pages = action.payload;
      }
    },
    loadDevelopPlugin: (
      state: StatePage,
      action: PayloadAction<{ isDevelopPluginLoading: boolean }>
    ) => {
      state.isDevelopPluginLoading = action.payload.isDevelopPluginLoading;
    },
    setHaveDevelopPlugin: (
      state: StatePage,
      action: PayloadAction<{ isHaveDevelopPlugin: boolean }>
    ) => {
      state.isHaveDevelopPlugin = action.payload.isHaveDevelopPlugin;
    },
    setDimensionStore: (
      state: StatePage,
      action: PayloadAction<DimensionObject>
    ) => {
      if (action.payload) {
        state.dimension = action.payload;
        state.loadingDimension = false;
      }
    },
    setLoadingDimension: (state: StatePage, action: PayloadAction<boolean>) => {
      state.loadingDimension = action.payload;
    },
    setGoogleMaploaded: (state: StatePage, action: PayloadAction<boolean>) => {
      state.googleMaploaded = action.payload;
    },
  },
});

export const {
  loadPages,
  setDimensionStore,
  setLoadingDimension,
  loadDevelopPlugin,
  setHaveDevelopPlugin,
  setGoogleMaploaded,
} = pageSlice.actions;
export default pageSlice.reducer;
