import { IAction } from '@common/types/';
import store from '@common/redux/store';

import { ActionResponse } from '../../excuteAction';
import { setError } from '@common/redux/slice/formInput';

const appleLogin: (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[],
  locale?: string
) => Promise<ActionResponse> = async (
  action: IAction,
  currentRecord?: any,
  actionComponent?: IAction[],
  locale?: string
) => {
  const dispatch = store.dispatch;
  const state: any = store.getState();

  const isJa = locale === 'ja';

  dispatch(
    setError({
      message: isJa
        ? 'Appleログインは現在IOSのみサポートしております。'
        : 'Apple login currently only supports the IOS',
      isError: true,
    })
  );

  return {
    status: 'FAILED',
  };
};

export default appleLogin;
