import React from 'react';
import { isEmpty } from 'lodash';
import { View, ActivityIndicator, Text } from 'react-native';

import { fetchRecords } from '@common/hooks/useFetchRecords';

const BindingNewComponent: React.FC<{
  ObjectClass: React.FC;
  obj: any;
  databaseUuid: string;
  keyItem: any;
  zIndex: number | string;
  handlePress?: (actionRef: string, options?: { itemId: string }) => void;
}> = ({ ObjectClass, obj, handlePress, databaseUuid, keyItem, zIndex }) => {
  const { response, setLoadMore } = fetchRecords({
    obj,
    databaseId: databaseUuid,
    handlePress,
  });

  if (isEmpty(response.data) && response.loading) {
    return (
      <View
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          height: obj.height,
          marginTop: obj.marginTop,
        }}
      >
        <ActivityIndicator size="small" color="#1976d2" />
      </View>
    );
  }

  if (response.error) {
    return (
      <View
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: obj.height,
          marginTop: obj.marginTop,
        }}
      >
        <Text
          style={{
            color: 'red',
          }}
        >
          {response.error}
        </Text>
      </View>
    );
  }

  const attrs = {
    ...obj,
    records: response.data,
    loading: response.loading,
    total: response.total,
    page: response.page,
    error: response.error,
    setLoadMore,
  };

  return (
    <ObjectClass
      onPress={handlePress}
      databaseUuid={databaseUuid}
      keyItem={keyItem}
      zIndex={zIndex}
      {...attrs}
    />
  );
};

export default BindingNewComponent;
