import { updateLoggedUserSuccess } from '@common/redux/slice/auth';
import {
  setReloadStatus,
  updateItemDataSource,
} from '@common/redux/slice/database';
import { resetValue, setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { IRecord } from '@common/types/';
import { MetaData } from '@common/types/action';
import { isEmpty } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { updateRecordRecordApi } from '../func/apiFunction';
import {
  getUserLoginRecord,
  handlePermissonDatabase,
  parsePayload,
} from '../func/func';

const updateLoggedIn: (
  metadata: MetaData,
  locale?: string
) => Promise<ActionResponse> = async (metadata: MetaData, locale?: string) => {
  const { record, databaseUuid, autoValues } = metadata;
  const dispatch = store.dispatch;
  const state: any = store.getState();
  const auth = state.auth.profile;

  const newPayload = parsePayload({
    databaseUuid,
    record: {
      ...record,
      ...autoValues,
    },
  });

  const permission = handlePermissonDatabase({
    databaseUuid,
    targetRecord: auth,
    payload: newPayload,
  });

  // const recordArray: Array<IRecord> = getRecords(databaseUuid);

  // const validEmail = isEmpty(
  //   filter(recordArray, ['record.email', record.email])
  // );

  // if (!validEmail) {
  //   dispatch(
  //     setError({
  //       message: 'Email is ready exist !',
  //       isError: true,
  //     })
  //   );

  //   return {
  //     status: 'FAILED',
  //     message: 'Email is ready exist !',
  //   };
  // }

  if (permission.message) {
    dispatch(
      setError({
        message: permission.message,
        isError: true,
      })
    );
  }

  try {
    if (isEmpty(permission.payload)) {
      return {
        status: 'FAILED',
        message: '',
      };
    }

    const userLoginRecord: IRecord | any = getUserLoginRecord();

    if (!isEmpty(userLoginRecord) && userLoginRecord.userId) {
      const userUpdated: IRecord | any = await updateRecordRecordApi(
        databaseUuid,
        permission.payload,
        userLoginRecord.userId
      );

      dispatch(
        updateLoggedUserSuccess({
          user: {
            ...userUpdated.record,
            userId: userUpdated._id,
            appId: state.appInfo.app?.id,
          },
        })
      );

      if (
        metadata?.mappingField &&
        Object.keys(metadata?.mappingField).length
      ) {
        dispatch(resetValue(metadata?.mappingField));
      } else {
        dispatch(resetValue(record));
      }

      dispatch(updateItemDataSource(userUpdated));

      dispatch(setReloadStatus(true));

      return {
        status: 'SUCCEED',
        message:
          locale === 'ja'
            ? 'ユーザーの変更は完成しました !'
            : 'Update logged user success !',
      };
    } else {
      return {
        status: 'FAILED',
        message: 'Logged user not found',
      };
    }
  } catch (err: any) {
    return {
      status: 'FAILED',
      message: err?.message,
    };
  }
};

export default updateLoggedIn;
