import * as React from 'react';
import { Platform, View, Text, TouchableOpacity } from 'react-native';
import { checkFont } from '../../func';
import useAvatar, { Props, ReceivedProps } from './hook';
import AvatarImage from './AvatarImage';
import { isEmpty } from 'lodash';

const AvatarItemLayout: React.FC<Props> = (props) => {
  const {
    attributes,
    getTextStyles,
    styles,
    urlImage,
    titleText,
    subTitleText,
    item,
    index,
    handlePress,
    placeholderImage,
  } = props;

  const { title, cardCover, opacity, backgroundColor, subTitle } = attributes;
  const {
    borderColor,
    borderStyle,
    borderWidth,
    borderRadius,
    size,
    image,
    backgroundColor: backgroundImage,
    // onPress,
  } = cardCover || {};

  const { enabled = false, titleLineNum, position = 'bottom' } = title || {};
  const {
    enabled: subTitleEnabled = false,
    subTitleLineNum,
    backgroundColor: backgroundColorSubTitle,
    borderRadius: borderRadiusSubTitle,
  } = subTitle || {};
  const lineHeightSubTitle =
    borderRadiusSubTitle >= subTitle?.fontSize * 1.15
      ? subTitle?.fontSize * 1.15
      : borderRadiusSubTitle;

  return (
    <TouchableOpacity
      onPress={() => {
        handlePress('onPress', {
          itemId: item._id,
          indexRecord: index,
        });
      }}
    >
      <View
        style={[
          {
            opacity,
            backgroundColor,
            flexDirection: position === 'top' ? 'column-reverse' : 'column',
            marginRight: attributes.gap,
            width: size,
          },
          styles.cardWrapper,
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            handlePress('cardCover', {
              itemId: item._id,
              indexRecord: index,
            });
          }}
        >
          <AvatarImage
            borderColor={borderColor}
            borderRadius={borderRadius}
            backgroundImage={backgroundImage}
            borderStyle={borderStyle}
            borderWidth={borderWidth}
            size={size}
            url={urlImage}
            imageResize={image?.imageResize || 'cover'}
            styles={styles}
            placeholderImage={placeholderImage}
          />
        </TouchableOpacity>
        <View>
          {enabled && !isEmpty(titleText) && (
            <Text
              style={[
                {
                  ...getTextStyles(title),
                },
                styles.cardContent,
              ]}
              numberOfLines={title && titleLineNum > 1 ? 500 : 1}
              onPress={() => {
                handlePress('title', {
                  itemId: item._id,
                  indexRecord: index,
                });
              }}
            >
              {titleText}
            </Text>
          )}

          {subTitleEnabled && !isEmpty(subTitleText) && (
            <Text
              style={[
                {
                  ...getTextStyles(subTitle),
                  backgroundColor: backgroundColorSubTitle,
                  borderRadius: lineHeightSubTitle,
                  overflow: 'hidden',
                },
                styles.cardContent,
              ]}
              numberOfLines={subTitle && subTitleLineNum > 1 ? 500 : 1}
              onPress={() => {
                handlePress('subTitle', {
                  itemId: item._id,
                  indexRecord: index,
                });
              }}
            >
              {subTitleText}
            </Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const AvatarItem: React.FC<ReceivedProps> = (props) => (
  <AvatarItemLayout {...useAvatar(props)} />
);

export default React.memo(AvatarItem);
