import React, { ComponentProps } from 'react';
import { IconButton as RNIconButton } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import { IIconButton } from '@nocode/types';
import SvgComponent from '../CustomIcon';
import { CustomIconList } from '../CustomIcon/CustomIcon';

type Props = ComponentProps<typeof RNIconButton>;

const Icon: React.FC<IIconButton> = ({
  width,
  height,
  attributes,
  onPress,
}) => {
  const page = StyleSheet.create({
    icon: {
      margin: 0,
      width,
      height,
      zIndex: attributes.zIndex,
    },
  });

  const childProps: Props = {
    size: attributes.size,
    icon: attributes.iconName,
    color: attributes.iconColor
      ? attributes.iconColor.trim()
      : attributes.iconColor,
  };

  const CustomIconName = CustomIconList.find(
    (customIcon) => customIcon.name === attributes.iconName
  );

  if (CustomIconName != undefined) {
    return (
      <View>
        <SvgComponent
          icon={attributes.iconName}
          heigh={height}
          width={width}
          color={attributes.iconColor.trim()}
        />
      </View>
    );
  }

  return (
    <RNIconButton
      style={page.icon}
      {...childProps}
      onPress={() => {
        onPress && onPress();
      }}
    />
  );
};

export default Icon;
