import React from 'react';
import { Button } from 'react-native-paper';
import { Image } from 'react-native';
import clickLogo from '@common/assets/images/logo-click.png';

import { styles } from './style';

type Props = {
  onClick: () => void;
  children: any;
};

const CreateButton = ({ onClick, children }: Props) => {
  const renderIcon = () => {
    return <Image source={{ uri: clickLogo }} style={styles.appLogo} />;
  };

  return (
    <Button
      icon={() => renderIcon()}
      mode="contained"
      onPress={onClick}
      style={styles.container}
      contentStyle={{ paddingVertical: 5 }}
    >
      {children}
    </Button>
  );
};

export default CreateButton;
