import React, { FC } from 'react';
import { ILabel } from '@common/types/element';
import { View, Text } from 'react-native';
import createStyles from './style';
import { useSelector } from 'react-redux';
import { getCurrentRecord } from '@common/redux/selectors/database';
import { excuteBinding } from '@common/hooks/useBinding';
import { get, isEmpty } from 'lodash';

const CusLabel: FC<ILabel> = (attrs) => {
  const styles = createStyles(attrs);
  const getTableId = get(attrs, 'selectedItem.tableId');

  const currentRecord = useSelector(getCurrentRecord) || {};

  const text = excuteBinding(
    attrs?.text || '',
    attrs.record || currentRecord[getTableId],
    attrs?.selectedItem?.itemIndex,
    attrs.currentListId
  );

  const handlePress = (event: any) => {
    !isEmpty(attrs.actions) && attrs.onPress ? attrs.onPress() : {};
  };

  return (
    <View style={styles.container}>
      <Text
        style={styles.text}
        numberOfLines={attrs.multiline ? 0 : 1}
        {...(!isEmpty(attrs.actions) ? { onPress: handlePress } : {})}
      >
        {!Array.isArray(attrs.text) ? attrs.text.trim() : text.trim()}
        {attrs.children}
      </Text>
    </View>
  );
};

export default CusLabel;
