import { filter } from 'lodash';
import moment from 'moment';
import React, { useCallback, useRef } from 'react';
import { Text, TouchableOpacity, View, VirtualizedList } from 'react-native';

import Icon from '../../AppBar/Icon';
import DayView from './DayView';
import styleConstructor from './style';
import { IAgendaProp } from '@nocode/types/calendar.type';

type Props = IAgendaProp;

const EventCalendar: React.FC<Props> = (props) => {
  const calendarRef: any = useRef(null);
  const {
    headerColor,
    headerTextColor,
    eventBgColor,
    eventTextColor,
    activeColor,
    customFontStyles,
    initDate,
    width,
    height,
    opacity,
    headerStyle,
    renderEvent,
    eventTapped,
    title,
    backButton,
    headerBackIcon,
    format24h,
    events,
    dateChanged,
    virtualizedListProps,
    scrollToFirst,
    size,
    start = 0,
    end = 24,
    formatHeader,
  } = props;

  const starts = start ? start : 0;
  const ends = end ? end : 24;
  const propStyle: any = { ...props };

  const styles = styleConstructor(
    propStyle,
    (ends - starts) * 100,
    headerColor,
    headerTextColor,
    eventBgColor,
    eventTextColor,
    customFontStyles
  );

  const getItemLayout = (data: any, index: number) => {
    return { length: width, offset: width * index, index };
  };

  const getItem = (events: any) => {
    const date = moment(initDate);
    return filter(events, (event) => {
      const eventStartTime = moment(event.start);
      return (
        eventStartTime >= date.clone().startOf('day') &&
        eventStartTime <= date.clone().endOf('day')
      );
    });
  };

  const onBackButton = useCallback(() => {
    backButton && backButton();
  }, [backButton]);

  const renderItem = ({ item, index }: { index: number; item: Array<any> }) => {
    const backIcon = headerBackIcon ? (
      headerBackIcon
    ) : (
      <Icon name={'chevron-left'} color={activeColor} />
    );

    return (
      <View
        style={[
          styles.container,
          {
            width: width,
            height: height,
            opacity: opacity,
          },
        ]}
      >
        <View style={[styles.header]}>
          <TouchableOpacity
            style={[styles.arrowButton]}
            onPress={() => onBackButton()}
          >
            {backIcon}
          </TouchableOpacity>
          <View style={{ justifyContent: 'center' }}>
            <Text style={[styles.headerText]}>{title}</Text>
          </View>
        </View>
        <DayView
          index={index}
          format24h={format24h}
          formatHeader={formatHeader}
          headerStyle={headerStyle}
          renderEvent={renderEvent}
          eventTapped={eventTapped}
          events={item}
          width={width}
          styles={styles}
          scrollToFirst={scrollToFirst}
          start={start}
          end={end}
        />
      </View>
    );
  };

  const getItemCount = (data: any) => {
    return 1;
  };

  return (
    <View style={[styles.container, { width }]}>
      <VirtualizedList
        ref={calendarRef}
        windowSize={2}
        data={events}
        getItemCount={getItemCount}
        getItem={getItem}
        keyExtractor={(item: any, index: number) => index.toString()}
        getItemLayout={getItemLayout}
        horizontal
        pagingEnabled
        renderItem={({ item, index }: { item: any; index: number }) =>
          renderItem({ item, index })
        }
        style={{ width }}
        onMomentumScrollEnd={(event: any) => {
          if (dateChanged) {
            const data: any = event.nativeEvent.contentOffset.x / width;
            const index = parseInt(data);
            const date = moment(initDate).add(index, 'days');
            dateChanged(date.format('YYYY-MM-DD'));
          }
        }}
        {...virtualizedListProps}
      />
    </View>
  );
};

export default EventCalendar;
