import { pick } from 'lodash';
import { StyleSheet } from 'react-native';

const createStyles = (attributes: any) => {
  const width =
    attributes.bannerSize === 'smartBanner' ? '100%' : attributes.width;

  return StyleSheet.create({
    errorView: {
      borderWidth: 0,
    },
    errorText: {
      textAlign: 'center',
      color: '#FF0000',
    },
    borderView: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      borderColor: '#999999',
      borderRadius: 4,
      padding: 8,
      position: 'absolute',
    },
    wrapper: {
      ...pick(attributes, [
        'marginTop',
        'marginLeft',
        'borderRadius',
        'opacity',
        'height',
      ]),
      width,
      display: attributes.hidden ? 'none' : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      ...(attributes.borderStyle !== 'none'
        ? pick(attributes, ['borderWidth', 'borderColor'])
        : {}),
      borderStyle:
        attributes.borderStyle === 'none' ? 'solid' : attributes.borderStyle,
      zIndex: attributes.zIndex,
      position: 'relative',
    },
    imageMobile: {
      ...pick(attributes, ['height', 'width']),
      position: 'absolute',
    },
  });
};

export default createStyles;
