import {
  FIELD_NAME_SEND_MAIL,
  RESPONSE_STATUS,
  SPECIAL_CHARACTER_REGEX,
} from '@common/constants/shared';
import { setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { MetaData } from '@common/types/action';
import { isEmpty } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { handleSendEmail } from '../func/apiFunction';

const mapFieldNameCondition = (item: string) => FIELD_NAME_SEND_MAIL[item];

function getErrorMessages(value: any, locale?: string) {
  const emptyKeys = Object.keys(value)
    .filter((key: string) => isEmpty(value[key]))
    .map(mapFieldNameCondition);

  if (emptyKeys.length <= 0) {
    const inValidRegexKeys = Object.keys(value)
      .filter((key: string) => value[key].match(SPECIAL_CHARACTER_REGEX))
      .map(mapFieldNameCondition);

    if (inValidRegexKeys.length <= 0) {
      return {
        status: RESPONSE_STATUS.VALID,
      };
    } else {
      return {
        status: RESPONSE_STATUS.IN_VALID,
        message:
          locale === 'ja'
            ? '送信先のメールアドレス、件名、内容は無効です。'
            : 'Send email failure: Email address, Title or Body is invalid',
        // message: `Send email failure: ${inValidRegexKeys.join(
        //   ', '
        // )} is contains special all characters`,
      };
    }
  } else {
    return {
      status: RESPONSE_STATUS.IN_VALID,
      message:
        locale === 'ja'
          ? '送信先のメールアドレス、件名、内容は必須項目です'
          : 'Send email failure: Email address, Title and Body is not allowed to be empty',
      // message: `Send email failure: ${emptyKeys.join(
      //   ', '
      // )} is not allowed to be empty`,
    };
  }
}

const sendEmail: (
  action: any,
  metadata: MetaData,
  locale?: string
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetaData,
  locale?: string
) => {
  const dispatch = store.dispatch;

  try {
    const sendEmailOption = metadata?.optionsNofitication;

    const newPayload = {
      to: sendEmailOption.address,
      subject: sendEmailOption.title,
      content: sendEmailOption.body.replace(/\n/g, '<br>'),
    };

    const error = getErrorMessages(newPayload, locale);

    if (error.status === RESPONSE_STATUS.IN_VALID) {
      dispatch(setError({ message: error?.message }));
      return {
        status: 'FAILED',
        message: error?.message,
      };
    }

    const response = await handleSendEmail(newPayload);

    if (response && response === 'OK') {
      return {
        status: 'SUCCEED',
        message:
          locale === 'ja' ? 'メールを送信しました。' : 'Send email Success',
      };
    } else {
      return {
        status: 'FAILED',
        message:
          locale === 'ja' ? 'メール送信が失敗しました。' : 'Send email failed',
      };
    }
  } catch (error: any) {
    dispatch(
      setError({
        ...error,
        message: locale === 'ja' ? 'メール送信エラー' : 'Send email Error',
      })
    );

    return {
      status: 'FAILED',
      message: error?.message,
    };
  }
};

export default sendEmail;
