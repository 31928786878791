import React, { memo, useEffect, useState } from 'react';
import { View } from 'react-native';

type Props = {
  adUnitID: string;
  onCallback: VoidFunction;
  onAdClosed: VoidFunction;
  handleError: (error: string) => void;
};

const CustomAdmobReward: React.FC<Props> = ({
  adUnitID,
  onCallback,
  onAdClosed,
  handleError,
}) => {
  const { AdMobRewarded } = require('react-native-admob');
  useEffect(() => {
    AdMobRewarded.setAdUnitID(adUnitID);
    AdMobRewarded.addEventListener('rewarded', () => {
      onCallback();
    });
    AdMobRewarded.addEventListener('adClosed', () => {
      onAdClosed();
    });
    AdMobRewarded.requestAd()
      .then(() => AdMobRewarded.showAd())
      .catch((error: any) => {
        handleError(error.message || 'Error when request AdMobRewarded');
      });
    return () => {
      AdMobRewarded.removeAllListeners();
    };
  }, [adUnitID]);

  return <View></View>;
};

export default memo(CustomAdmobReward);
