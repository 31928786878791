import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';

import { IButton } from '@nocode/types';
import { AppleIcon, AppleIconOutLine } from './appleIcon';
import { parseText } from '../func';
import createStyles from './style';

const AppleLogin: React.FC<IButton> = ({ attributes, height, onPress }) => {
  const styles = createStyles({ ...attributes, height });

  const text = parseText(attributes.text);

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Image
          style={styles.iconImage}
          resizeMode="contain"
          source={{
            uri: attributes.type !== 'contained' ? AppleIconOutLine : AppleIcon,
          }}
        />
        <Text ellipsizeMode="tail" numberOfLines={1} style={styles.text}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default AppleLogin;
