export type ReceivedProps = {
  animation: string;
};

const useAnimate = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useAnimate>;

export default useAnimate;
