import { IList } from '@common/types/';
import { map, pick } from 'lodash';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';
import { getShadow } from '../../func';

type ListPlaceholderProps = {
  attrs: IList;
  initialize: boolean;
};

ListPlaceholder.defaultProps = {
  attrs: null,
  initialize: false,
};

function ListPlaceholder({ attrs, initialize }: ListPlaceholderProps) {
  const { rowMargin } = attrs;

  const shadowProps = {
    shadow: { blur: 1, color: '#BDBDBD', enabled: true, size: 4, x: 0, y: 2 },
  };

  const styles = StyleSheet.create({
    wrapper: {
      padding: 10,
      ...(initialize
        ? pick(attrs, ['marginTop', 'marginLeft', 'width', 'zIndex'])
        : {}),
    },
    item: {
      marginBottom: rowMargin,
      borderColor: '#f5f5f5',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      height: 80,
      borderRadius: 4,
      ...(Platform.OS !== 'android' && getShadow(shadowProps)),
    },
  });

  const PlaceholderItem = () => {
    return (
      <Placeholder Animation={Fade} style={styles.item}>
        <PlaceholderLine width={40} />
        <PlaceholderLine width={80} />
      </Placeholder>
    );
  };

  const renderSkeleton = () => {
    return map(Array.from(Array(4).keys()), (item) => {
      return <PlaceholderItem key={item} />;
    });
  };

  return <View style={styles.wrapper}>{renderSkeleton()}</View>;
}

export default ListPlaceholder;
