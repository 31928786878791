import React, { useState, useMemo, useEffect, useRef } from 'react';
import { View, Platform, FlatList, ActivityIndicator } from 'react-native';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';

import { IChipList } from '@nocode/types';
import ChipItem from './Chip';
import { getItemChipList } from './func';
import Icon from '../AppBar/Icon';
import EmptyList from '../EmptyList';
import createStyles from './style';

const ChipList = (attrs: IChipList) => {
  const flatListRef: any = useRef();
  const [page, setPage] = useState<number>(1);

  const data = useMemo(() => {
    return getItemChipList(attrs);
  }, [attrs]);

  const styles = createStyles(attrs);
  const tableId = get(attrs, 'attributes.items.source.tableId');
  const maximum = get(attrs, 'attributes.items.source.options.limit', null);

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  if (target && !tableId && !attrs?.loading)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attrs.zIndex,
        }}
      >
        <EmptyList attributes={attrs} />
      </View>
    );

  useEffect(() => {
    setPage(1);
    flatListRef.current.scrollToOffset({ animated: true, offset: 0, x: 0 });
  }, [attrs.id]);

  useEffect(() => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0, x: 0 });

    if (attrs?.hasAction) {
      attrs.setLoadMore && attrs.setLoadMore(1);
      setPage(1);
    }
  }, [attrs?.hasAction]);

  return (
    <View style={styles.wapperList}>
      <FlatList
        data={data}
        horizontal
        // pagingEnabled={Platform.OS === 'web'}
        scrollEnabled
        snapToAlignment="start"
        scrollEventThrottle={16}
        decelerationRate={'normal'}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        ref={flatListRef}
        onEndReachedThreshold={0.1}
        {...(attrs?.loading && {
          ListFooterComponent: (
            <View
              style={{
                display: 'flex',
                height: attrs.height,
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="small" color="#1976d2" />
            </View>
          ),
        })}
        {...(!attrs?.loading &&
          data.length >= 10 && {
            onEndReached: () => {
              if (data.length === attrs?.total || data.length === maximum) {
                setPage(1);
                return;
              }

              setPage(page + 1);
              attrs.setLoadMore && attrs.setLoadMore(page + 1);
            },
          })}
        renderItem={({ item, index }) => (
          <ChipItem
            item={item}
            key={index}
            index={index}
            attrs={attrs}
            onPress={attrs.onPress}
          />
        )}
        keyExtractor={(item, index) => `${(item?._id, index)}`}
      />

      {isCanvas && <Icon name="ellipsis-horizontal" />}
    </View>
  );
};

export default ChipList;
