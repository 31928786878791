import api from '@common/configs/api';

export const useForgotPassword = async (props: {
  appId: string;
  value: string;
}) => {
  const res = await api({
    method: 'post',
    url: `/v2/database/forgot-password`,
    params: {
      appId: props.appId,
    },
    data: {
      email: props.value,
    },
  });

  return res.data;
};

export const useResetPassword = async (props: {
  appId: string;
  email: string;
  value: string;
  passwordTemporary: string;
}) => {
  const res = await api({
    method: 'post',
    url: `/v2/database/reset-password`,
    params: {
      appId: props.appId,
    },
    data: {
      email: props.email,
      new_password: props.value,
      password_temporary: props.passwordTemporary,
    },
  });

  return res.data;
};

export const convertMessage = (props: { message: string; locale: string }) => {
  const isJa = props.locale === 'ja';
  switch (props.message) {
    case 'Email is not allowed to be empty':
      return isJa ? 'メールアドレスが空欄です' : props.message;

    case 'Email must be a valid email':
      return isJa ? 'メールアドレスが有効ではありません' : props.message;

    case 'User does not exist.':
      return isJa ? 'メールアドレスが存在しません' : props.message;

    case 'Your password temporary has experied.':
      return isJa ? '仮パスワードは有効ではありません' : props.message;

    case 'new password is not allowed to be empty':
      return isJa ? 'パスワードが空欄です' : props.message;

    default:
      return props.message;
  }
};
