import { createSelector } from 'reselect';
import { RootState } from '../store';

export const formInputs = (state: RootState) => state.formInputs;

export const getFormFieldValue = (state: RootState, fieldId: string) =>
  createSelector(
    (state: RootState) => state.formInputs,
    (formInputs) =>
      formInputs.values[fieldId] || formInputs.defaultValues[fieldId] || ''
  );

export const getValueFields = (state: RootState) => state.formInputs.values;

export const getDateDefaultValue = (state: RootState) =>
  state.formInputs.defaultValues;
