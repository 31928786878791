import { ICheckBox } from '@common/types/element';
import React, { FC, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import useValueInputs, {
  UseValueInputProps,
} from '@common/hooks/useValueInputs';

const Checkbox: FC<ICheckBox> = (attributes) => {
  // const { id } = attributes;
  const [
    state,
    // setstate
  ] = useState({
    activeColor: attributes?.activeColor || '',
    inactiveColor: attributes?.inactiveColor || '',
    activeIcon: attributes?.activeIcon || 'check-box',
    inactiveIcon: attributes?.inactiveIcon || 'check-box-outline-blank',
  });
  const { valueInputs, changeInput }: UseValueInputProps =
    useValueInputs(attributes);

  return (
    <TouchableOpacity
      onPress={() => changeInput(!valueInputs)} //{() => changeValue(id, !value)}
      activeOpacity={0.4}
      hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
      //   style={layout}
    >
      <Icon
        size={24}
        name={valueInputs ? state.activeIcon : state.inactiveIcon}
        color={valueInputs ? state.activeColor : state.inactiveColor}
      />
    </TouchableOpacity>
  );
};

export default Checkbox;
