import { KEY_CRYPTO } from '@common/constants';
import CryptoJS from 'crypto-js';

export const getDataEncrypt = (response: any) => {
  try {
    if (response?.encrypt) {
      const bytes = CryptoJS.AES.decrypt(response.data, KEY_CRYPTO);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return {
        ...response,
        data: decryptedData,
      };
    } else {
      return response;
    }
  } catch (error) {
    console.log('error decrypt data:', error);
  }
};

export const convertEncrypt = (responseApi: any[]) => {
  if (!Array.isArray(responseApi)) return [];
  return responseApi.map((response: any) => ({
    ...response,
    data: getDataEncrypt(response?.data),
  }));
};
