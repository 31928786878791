import { getMessage } from '@common/constants/locale';
import { signupUserSuccess } from '@common/redux/slice/auth';
import {
  addItemDataSource,
  setCreateRecordAddUpdate,
} from '@common/redux/slice/database';
import { setError } from '@common/redux/slice/formInput';
import store from '@common/redux/store';
import { MetaData } from '@common/types/action';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { get, pick } from 'lodash';
import { Platform } from 'react-native';
import { ActionResponse } from '../excuteAction';
import { signUpAction } from '../func/apiFunction';
import { parsePayload } from '../func/func';
import { unregisterFCMUser, registerFCMUser } from './FCMregister';

const excuteSignup: (
  action: any,
  metadata: MetaData,
  locale?: string
) => Promise<ActionResponse> = async (
  action: any,
  metadata: MetaData,
  locale?: string
) => {
  const { record, databaseUuid, autoValues, handleResetValue } = metadata;

  const dispatch = store.dispatch;
  const state: any = store.getState();

  const isLock = get(action, 'options.lock', false);

  const newPayload = parsePayload({
    databaseUuid,
    record: {
      ...autoValues,
      ...record,
    },
    actionType: 'signUpAction',
  });

  try {
    if (!newPayload.email || !newPayload.password) {
      return {
        status: 'FAILED',
        message: 'Please Enter email or password !',
      };
    }

    let masterId = null;
    if (Platform.OS === 'web') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      masterId = urlSearchParams?.get('masterId');
    } else {
      masterId = await AsyncStorage.getItem('masterId');
    }

    const resp = await signUpAction({
      ...newPayload,
      creator: newPayload.email,
      email: newPayload.email,
      password: newPayload.password,
      masterId,
    });

    if (resp.code && resp.code === 400) {
      if (!isLock) {
        dispatch(
          setError(
            locale === 'ja'
              ? { ...resp, message: 'メールアドレスは既に存在しています' }
              : resp
          )
        );
      }

      return {
        status: 'FAILED',
        message:
          locale === 'ja' ? 'メールアドレスは既に存在しています' : resp.message,
      };
    } else {
      await unregisterFCMUser(); //remove old token

      await registerFCMUser(resp, state.appInfo.app?.id);

      dispatch(
        signupUserSuccess({
          user: {
            ...resp.record,
            userId: resp._id,
            appId: state.appInfo.app?.id,
            ...pick(resp, ['createdAt', 'updatedAt', 'databaseId']),
          },
        })
      );

      handleResetValue();

      dispatch(
        setCreateRecordAddUpdate({
          databaseUuid,
          record: resp,
        })
      );
      dispatch(addItemDataSource(resp));

      return {
        status: 'SUCCEED',
        message: locale === 'ja' ? '登録が成功しました' : 'Signup User Success',
      };
    }
  } catch (err: any) {
    if (!isLock) {
      dispatch(
        setError({
          message:
            locale === 'ja'
              ? 'メールアドレスは既に存在しています'
              : 'Email was exist',
          // message: err?.response?.data?.message,
          isError: true,
        })
      );
    }

    return {
      status: 'FAILED',
      message:
        getMessage({
          id: err?.response?.data?.message,
          locale,
        }) || err?.message,
    };
  }
};

export default excuteSignup;
