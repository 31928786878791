import {
  getCurrentRecord,
  getListDatabases,
} from '@common/redux/selectors/database';
import { externalCollectionSelector } from '@common/redux/selectors/externalCollection';
import {
  getDateDefaultValue,
  getValueFields,
} from '@common/redux/selectors/formInput';
import { changeValue } from '@common/redux/slice/formInput';
import { getTextBinding } from '@common/utils/handleBinding';
import { getSourceComponent } from '@common/utils/functions';
import { get, isEmpty, isNull, isUndefined } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type UseValueInputProps = {
  changeInput: (value: any) => any;
  valueInputs: any;
};

const libComponent = ['toggle', 'switch', 'barcodescanner', 'signaturepad'];
const notInputComponent = ['button', 'icon', 'actionbutton'];

const useValueInputs = (attributes: any) => {
  const dispatch = useDispatch();

  const currentRecord = useSelector(getCurrentRecord) || {};
  const defaultValues = useSelector(getDateDefaultValue);
  const objectBinding = useSelector(getValueFields);

  const databases = useSelector(getListDatabases);

  const externals = useSelector(externalCollectionSelector);

  const valueInputs = useMemo(
    () => get(objectBinding, `${attributes?.id}`, ''),
    [objectBinding]
  );

  const getTableId = get(attributes, 'input.source.source.tableId');

  const changeInput = useCallback(
    (value: any) => {
      dispatch(
        changeValue({
          objectId: attributes?.id,
          value,
        })
      );
    },
    [dispatch, attributes]
  );

  const defaultValue = useMemo(() => {
    let attrDefaultValue = getSourceComponent(
      attributes,
      defaultValues,
      objectBinding,
      externals
    );

    return getTextBinding(
      attrDefaultValue,
      attributes.record || currentRecord[getTableId],
      undefined,
      attributes?.selectedItem?.itemIndex
    );
  }, [objectBinding, attributes, databases, externals]);

  useEffect(() => {
    const isChangeValue =
      notInputComponent.includes(attributes.type) ||
      (libComponent.includes(attributes.type) &&
        (isUndefined(defaultValue) || isNull(defaultValue))) ||
      (!libComponent.includes(attributes.type) && isEmpty(defaultValue));

    if (isChangeValue) return;

    dispatch(
      changeValue({
        objectId: attributes?.id,
        value: defaultValue,
      })
    );
  }, [defaultValue, attributes?.id]);

  return {
    valueInputs,
    changeInput,
  };
};

export default useValueInputs;
